<template>
  <ValidationObserver v-slot="{ invalid, validate }">
    <section class="container mx-auto px-4 pt-8 text-center leading-tight">
      <div class="text-verde-primary text-4xl pb-2"> 
        <i class="icon icon-ok-circled2" ></i>
      </div>
      <p
        v-if="coverage && coverage.subTitle"
        v-html="coverage.subTitle"
        class="container flex-initial mb-4 text-center"
      ></p>
      <div v-html="coverage.message">
      </div>
    </section>
    <section class="container mx-auto text-center leading-tight bg-gray-200">
      <form
        @submit.prevent="
          validate().then(valid => {
            !valid || submit();
          })
        "
        class="flex flex-row flex-wrap p-4 -mx-4"
        :class="$attrs._class"
      >
        <div class="flex-auto w-full px-4 pb-4 mb-6 md:mb-0" >
          <span v-html="coverage.submitText"></span>
        </div>
        <div
        v-for="field in _.filter(
          form,
          v => !v.target || _.lowerCase(v.target) === _.lowerCase(target)
        )"
        :key="field.name"
        class="mb-6 md:mb-0 mx-auto px-4 w-full sm:w-1/2"
        >
        <validator v-bind="field">
          <template #default="{errors}">
            <input
              v-model="field.value"
              class="border border-gray-600 mb-2 px-4 py-2 w-full"
              :type="field.type"
              :placeholder="field.label"
              :class="{ 'border-error ': errors }"
            />
          </template>
        </validator>
        </div>
        <div class="hidden sm:flex justify-center w-full pb-4 mb-6 md:mb-0">
          <div class="flex justify-center w-full">
            <button
              type="submit"
              class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed bg-linkem-celeste hover:bg-linkem-celeste-lighter"
              :disabled="invalid"
            >
              {{ submitButtonLabel }}
            </button>
          </div>
        </div>
        <div class="flex justify-center w-full sm:hidden pb-4 mb-6 md:mb-0">
          <button
            type="submit"
            class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed bg-linkem-celeste hover:bg-linkem-celeste-lighter"
            :disabled="invalid"
          >
            {{ submitButtonLabel }}
          </button>
        </div>
        <div class="flex-auto w-full px-4 mb-6 md:mb-0" >
          <span v-html="coverage.privacy"></span>
        </div>
      </form>
    </section>
    <section class="container mx-auto px-4 py-8 leading-tight">
      <p
        v-if="coverage && coverage.footerText"
        v-html="coverage.footerText"
        class="container flex-initial mb-4"
      ></p>
    </section>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    //origin: String,
    form: [
      {
        name: "mobile_phone",
        value: String(),
        validation: { required: true, min: 9, max: 10, regex: /^3[\d]+$/ },
        label: "Cellulare",
        type: "text"
      }
    ]
  }),
  inheritAttrs: false,
  props: {
    target: {
      type: String,
      default: "privati"
    },
    coverage: Object,
    privacyMsg: String,
    contactsApi: String,
    leaveContactMessage: { type: String },
    leavecontactNameLabel: { type: String, default: "Nome" },
    leavecontactLastnameLabel: { type: String, default: "Cognome" },
    leavecontactEmailLabel: { type: String, default: "E-mail" },
    leavecontactPhoneLabel: { type: String, default: "Cellulare" },
    submitButtonLabel: { type: String, default: "Lascia Contatti" },
    leaveUrl: { type: String, default: null },
    origin: { type: String, default: "LasciaContatti" },
    leadSuccessMessage: { type: String, default: null }
  },
  computed: {
    url() {
      return window.location.origin;
    },
    mappedForm() {
      return _.map(this.form, i => {
        i.label = this.leavecontactPhoneLabel;
        return i;
      });
    },
    submitForm() {
      return _.merge(
        _.mapValues(_.mapKeys(this.form, v => v.name), v => v.value),
        { coverage: this.coverage.coverage },
        { target: this.target },
        { origin: this.origin },
        { url: this.url }
      );
    },
    color() {
      return this.sectionColor || this.$$section.color;
    }
  },
  methods: {
    async submit() {
      try {
        window.vm.$wait.start("contact");
        await this.$axios
          .post(this.contactsApi, this.submitForm)
          .then(response => {
            this.$events.emit("toggleDrawer", [
              "Richiesta inviata con successo",
              "thanks",
              { sectionColor: this.color, leadSuccessMessage: this.leadSuccessMessage }
            ]);
          });
      } finally {
        window.vm.$wait.end("contact");
      }
    }
  }
};
</script>
