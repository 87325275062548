<template>
  <div class="flex flex-col justify-start h-full p-4 overflow-y-auto">
    <!-- VERIFICA COPERTURA -->
    <div v-html="message" class="container flex-initial mb-4 text-center"></div>
    <div
      class="container flex flex-wrap justify-around flex-initial text-center"
    >
      <div class="p-4" v-if="covered">
        <div
          class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          @click="goOn"
        >
          Prosegui con l'acquisto
        </div>
      </div>
      <div v-for="(offer, key) in response.relatedData" :key="key" class="p-4">
        <!-- <a
            :href="offer"
            class="text-xl cursor-pointer hover:underline"
            :class="`text-${color}-primary`"
            >{{ key }}</a
          > -->
        <div
          class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          @click="goOnOffer(offer)"
          v-html="key"
        >
          {{ key }}
        </div>
      </div>
      <div class="p-4">
        <button
          @click="
            openContacts(
              response.coverage,
              covered ? 'LEAD_ORGANICO_LASCIACONTATTI' : 'LasciaContatti'
            ),
              resetState()
          "
        >
          <div
            class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
            :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          >
            Ti richiamiamo noi gratis
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,

  data: () => ({}),
  props: {
    response: Object,
    message: String,
    offer: Object,
    goOnApi: String,
    code: Number
  },
  computed: {
    covered() {
      //return this.code === 0;
      return this.response.status === "covered";
    }
  },
  mounted() {
    if (this.address) {
      this.checkCopertura(this.address);
    }
  },
  methods: {
    resetState() {
      this.$emit("update:message", null);
      this.$emit("update:offer", null);
    },
    async goOn() {
      try {
        window.vm.$wait.start("go-on");
        await this.$axios.post(this.goOnApi, this.offer);
      } finally {
        window.vm.$wait.end("go-on");
        location.reload();
      }
    },
    async goOnOffer(offerUrl) {
      try {
        window.vm.$wait.start("go-on");
        await this.$axios.post(this.goOnApi, this.offer);
      } finally {
        window.vm.$wait.end("go-on");
        location.assign(offerUrl);
      }
    }
  }
};
</script>
