<template>
  <i :class="`icon icon-${icon}`"></i>
</template>

<script>
export default {
  props: {
    icon: String
  }
};
</script>
