<template>
  <div id="checkout_recurring_rid_form" v-if="step == step_name">
    <step-header
      title="Addebito su conto corrente"
      icon="payments"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-20 mx-auto">
          <div class="lg:flex-no-wrap flex flex-wrap justify-around">
            <div class="lg:flex-no-wrap flex flex-wrap w-full p-4">
              <linkem-input
                :errorModel="checkout_errors.iban"
                :value="recurring_rid.iban"
                @input="
                  $emit('update:recurring_rid', {
                    ...recurring_rid,
                    iban: $event
                  })
                "
                modelName="checkout.recurring_rid.iban"
                label="IBAN"
                :maxLength="inputLength.ibanLength"
                :disabled="iban ? true : false"
              />
              <form-otp
                v-if="_.isEmpty(iban)"
                :phone="phone"
                :iban="recurring_rid.iban"
                :validable="!_.isEmpty(recurring_rid.iban)"
                :handle-submit="handleSubmit"
                :checks-rid="true"
                :confirmed-phone="confirmedPhone"
                :confirmed-iban="confirmedIban"
                @update:confirmed-phone="
                  $emit('update:confirmed-phone', $event)
                "
                @update:confirmed-iban="$emit('update:confirmed-iban', $event)"
              />
            </div>
          </div>
          <div class="flex flex-wrap justify-around" v-if="_.isEmpty(iban)">
            <div class="checkbox justify-center w-full p-4">
              <ValidationProvider
                immediate
                name="acceptRid"
                :rules="{ required: { allowFalse: false } }"
              >
                <linkem-checkbox
                  :errorModel="checkout_errors['iban_accept_condition']"
                  :value="recurring_rid.iban_accept_condition"
                  @input="
                    $emit('update:recurring_rid', {
                      ...recurring_rid,
                      iban_accept_condition: $event
                    })
                  "
                  modelName="checkout.recurring_rid.iban_accept_condition"
                  :message="ridConditionsMessage"
                />
                <span
                  class="text-error"
                  v-for="(item, index) in checkout_errors.iban_accept_condition"
                  :key="index"
                  >{{ item }}</span
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="flex flex-wrap p-4 pt-0" v-if="_.isEmpty(iban)">
            <span> * campo obbligatorio </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    recurring_rid: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    inputLength: {
      required: true
    },
    iban: {
      type: String
    },
    colour: {
      default: "arancio"
    },
    phone: String,
    prefix: String,
    confirmedPhone: Boolean,
    confirmedIban: Boolean,
    ridConditionsMessage: String
  },
  data: function() {
    return {
      step_name: "recurring_rid"
    };
  }
};
</script>
