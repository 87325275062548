<template>
  <transition-fade>
    <div v-if="show" class="fixed inset-0 z-50" @click="cancel">
      <div
        :class="
          `bg-${color}-100 border-t-4 border-${color}-500 rounded-b text-${color}-900 px-4 py-3 shadow-md`
        "
        role="alert"
        @keydown.esc="cancel"
      >
        <div class="flex items-center -mx-4">
          <div class="px-4 py-1">
            <font-awesome-icon :icon="icon" size="lg"></font-awesome-icon>
          </div>
          <div class="flex-1 px-4">
            <p class="font-bold">{{ title }}</p>
            <p class="text-sm" v-html="message"></p>
          </div>
          <div class="px-4 self-start cursor-pointer" @click="agree">
            <font-awesome-icon icon="times"></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
  </transition-fade>
</template>

<script>
export default {
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      type: "",
      color: "",
      width: 290,
      zIndex: 200
    }
  }),
  computed: {
    icon() {
      switch (this.options.type) {
        case "success":
          return "check";
        case "info":
          return "info-circle";
        case "danger":
          return "exclamation-circle";
        case "error":
          return "exclamation-triangle";
        default:
          return "";
      }
    },
    color() {
      switch (this.options.type) {
        case "success":
          return "green";
        case "info":
          return "blue";
        case "danger":
          return "yellow";
        case "error":
          return "red";
        default:
          return "gray";
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.show = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.show = false;
    },
    cancel() {
      this.resolve(false);
      this.show = false;
    }
  }
};
</script>
