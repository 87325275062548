<template>
  <span class="relative w-full group">
    <input
      ref="autocomplete"
      type="text"
      :class="classname"
      class="w-full px-4 py-2 border"
      :id="id"
      :placeholder="placeholder"
      v-model="autocompleteText"
      @keypress.enter="$emit('enable-autosubmit')"
    />
    <font-awesome-icon
      icon="times"
      class="absolute inset-y-auto right-0 hidden m-3 cursor-pointer group-hover:inline"
      @click="clear"
    ></font-awesome-icon>
  </span>
</template>

<script>
export default {
  name: "GoogleAutocomplete",

  props: {
    id: {
      type: [Number, String],
      required: true
    },

    classname: String,

    placeholder: {
      type: String,
      default: "Comincia a digitare il tuo indirizzo"
    },

    types: {
      type: String,
      default: "address"
    },

    country: {
      type: [String, Array],
      default: "it"
    },
    nestedModel: Boolean
  },

  data() {
    return {
      autocomplete: null,
      autocompleteText: ""
    };
  },

  watch: {
    autocompleteText: function(newVal) {
      _.isEmpty(newVal) && this.$emit("placechanged", undefined);
    },
    country: function() {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      });
    }
  },

  mounted: function() {
    const options = {
      types: this.types || null,
      country: this.country || null
    };

    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.autocomplete.setTypes(['address']);
  },

  methods: {
    async onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // Pressione di Enter con indirizzo non suggerito o richiesta fallita
        this.$emit("no-results-found", place, this.id);
        this.clear();
        return;
      }
      if (place.address_components !== undefined) {
        const model = await this.mapAddress(place);
        this.$emit("placechanged", model);
        this.autocompleteText = model.address.full_address;
      }
    },
    clear() {
      this.autocompleteText = "";
      this.$emit("placechanged", undefined);
    },
    mapAddress(normalizedAddress) {
      const address = normalizedAddress.address_components;
      const coordinates = normalizedAddress.geometry.location;

      let complete_address = _.reduce(
        address,
        (data, component) => {
          data[component.types[0]] = {
            short: component.short_name,
            long: component.long_name
          };
          return data;
        },
        {}
      );
      let model = {
        address: {
          country: _.get(complete_address, "country.long"),
          administrative_area: _.get(
            complete_address,
            "administrative_area_level_2.short"
          ),
          administrative_area_level_1: _.get(
            complete_address,
            "administrative_area_level_1.short"
          ),
          city:
            _.get(complete_address, "locality.short") ||
            _.get(complete_address, "administrative_area_level_3.long"),
          postal_code: _.get(complete_address, "postal_code.short"),
          route: _.get(complete_address, "route.long"),
          street_number: _.get(complete_address, "street_number.short"),
          full_address: normalizedAddress.formatted_address
        },
        coordinates: {
          latitude: `${coordinates.lat()}`,
          longitude: `${coordinates.lng()}`
        }
      };
      model = model = this.nestedModel
        ? model
        : { ...model.address, ...model.coordinates };

      return model;
    }
  }
};
</script>
