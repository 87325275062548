<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit" :class="$attrs._class">
      <div class="flex-auto w-full px-4 mb-6 md:w-auto md:mb-0">
        <ValidationProvider
          name="Codice fiscale"
          rules="required|cf"
          v-slot="{ errors }"
        >
          <input
            v-model="cf"
            class="w-full px-4 py-2 mb-2 border"
            :class="{ 'border-error ': !_.isEmpty(errors) }"
            type="text"
            :placeholder="cfPlaceholder"
          />
          <p class="text-xs italic" :class="_.isEmpty(errors) || `text-error`">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div class="flex-no-wrap flex-auto w-full px-4 mb-6 md:w-auto md:mb-0">
        <ValidationProvider
          name="Telefono cellulare"
          rules="required|cell"
          v-slot="{ errors }"
        >
          <div
            class="flex items-baseline mb-2 border"
            :class="{ 'border-error': !_.isEmpty(errors) }"
          >
            <div class="p-2 bg-gray-400 flex-0">+39</div>
            <input
              v-model="cell"
              class="flex-1 w-full px-4 py-2"
              type="text"
              :placeholder="mobilePhonePlaceholder"
            />
          </div>
          <p class="text-xs italic" :class="_.isEmpty(errors) || `text-error`">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div class="px-4 mb-6 md:flex-none md:mb-0">
        <!-- type="submit" -->
        <button
          class="px-8 py-1 overflow-hidden text-sm text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          :disabled="invalid"
        >
          {{ recoverButtonText }}
        </button>
      </div>
      <div class="px-4 mb-6 md:flex-none md:mb-0">
        <a
          class="cursor-pointer hover:underline"
          :class="`text-${color}-primary`"
          @click="changeState('login')"
          >Torna al Login</a
        >
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  data: () => ({
    cf: String(),
    cell: String()
  }),
  props: {
    path: String,
    configurationItemId: String,
    antiForgeryToken: String,
    cfPlaceholder: { type: String, default: "Codice fiscale" },
    mobilePhonePlaceholder: { type: String, default: "Telefono cellulare" },
    recoverButtonText: { type: String, default: "Invia Sms" }
  },
  methods: {
    changeState(step) {
      this.$emit("update:step", step);
    },
    async submit() {
      try {
        window.vm.$wait.start("recovery");
        const response = await this.$axios.post(
          this.path,
          {
            codice_fiscale: this.cf,
            telefono: `0039${this.cell}`,
            ConfigurationItemID: this.configurationItemId
          },
          { headers: { RequestVerificationToken: this.antiForgeryToken } }
        );
        this.$root.$toast.open("Successo", response.data.message, {
          type: "success"
        });
      } catch (error) {
        if (_.get(error, "response.data.message")) {
          this.$root.$toast.open("Errore", error.response.data.message, {
            type: "error"
          });
        }
      } finally {
        window.vm.$wait.end("recovery");
      }
    }
  }
};
</script>
