<template>
  <div
    :id="'cart-' + this.kitType + '-composition-' + comp.id"
    v-bind:class="
      'transitionpx-2 ' +
        (selComposition ? `text-${colour}-primary` : 'text-gray-600') +
        ' bg-white rounded'
    "
  >
    <form :id="'form-kits-' + comp.id">
      <!-- <input type='hidden' name='composition_id' value='{{comp.id}}' /> -->
      <div class="flex flex-wrap justify-around py-4 bg-white">
        <div class="justify-center w-1/12 px-4" @click="showPanel = !showPanel">
          <font-awesome-icon
            :icon="showPanel ? 'minus' : 'plus'"
          ></font-awesome-icon>
        </div>
        <div
          class="justify-center w-1/2 cursor-pointer sm:w-5/12"
          @click="submit()"
        >
          <span class="py-2 font-bold">{{ comp.kitName }}</span>
        </div>
        <!-- <div class="justify-center hidden sm:block sm:w-5/12">
          <span class="">{{comp.kitDescription}}</span>
        </div>-->
        <div
          class="justify-center w-1/4 cursor-pointer sm:w-5/12"
          @click="submit()"
        >
          <span v-if="comp.upfrontKitPrice > 0">{{
            comp.upfrontKitPrice
              | currency("€ ", 2, {
                decimalSeparator: ",",
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true
              })
          }}</span>
          <span v-if="comp.recurringKitPrice > 0"
            >{{
              comp.recurringKitPrice
                | currency("€ ", 2, {
                  decimalSeparator: ",",
                  symbolOnLeft: false,
                  spaceBetweenAmountAndSymbol: true
                })
            }}
            / mese</span
          >
        </div>
        <div
          class="justify-center w-1/6 text-center cursor-pointer sm:w-1/12"
          @click="submit()"
        >
          <font-awesome-icon
            v-if="this.kitType == 'optional'"
            :icon="selComposition ? 'check-square' : 'square'"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="this.kitType == 'mandatory'"
            :icon="selComposition ? 'check-circle' : 'circle'"
          ></font-awesome-icon>
          <!-- <i v-bind:class="'font-20 icon '+(selComposition ? 'icon-square-empty' : 'icon-square-checked')" aria-hidden="true"></i> -->
          <!-- <i v-if="this.kitType == 'mandatory'" v-bind:class="'font-20 fa '+(selComposition ? 'fa-check-circle-o' : 'fa-circle-o')" aria-hidden="true"></i> -->
        </div>
        <div v-if="showPanel" class="overflow-hidden" :id="`${comp.id}-active`">
          <div class="pt-4">
            <p class="px-4" v-html="comp.kitDescription"></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    "comp-id": Number,
    "kit-name": String,
    "kit-description": String,
    "upfront-price": String,
    "recurring-price": String,
    "selected-comp": Boolean,
    "submit-url": String,
    "kit-type": String,
    colour: String
  },
  data: vm => ({
    selComposition: vm.selectedComp,
    showPanel: Boolean(),
    comp: {
      id: vm.compId,
      kitName: vm.kitName,
      kitDescription: vm.kitDescription,
      upfrontKitPrice: vm.upfrontPrice,
      recurringKitPrice: vm.recurringPrice
    }
  }),
  computed: {},

  created: function() {
    this.$events.on("cart:unselectItem", id => {
      if (id === this.compId) {
        this.selComposition = false;
      }
    });
  },

  beforeDestroy() {
    this.$events.off("cart:unselectItem");
  },

  methods: {
    updateCart() {
      this.$events.emit("cart:cart_updated");
    },
    submit() {
      this.$events.emit("checkout:loading");
      let component = this;
      this.$axios
        .put(this.submitUrl, { composition_id: component.comp.id })
        .then(
          response => {
            if (response.status == 200) {
              component.updateCart();
              component.selComposition = !component.selComposition;
              _.forEach(response.data, id => {
                this.$events.emit("cart:unselectItem", id);
              });
            }
            this.$events.emit("checkout:not-loading");
          },
          error => {
            this.$events.emit("open-modal", {
              title: "Ops! Qualcosa è andato storto",
              component: "error",
              props: { message: error.response.data.message }
            });
          }
        );
    },
    openAccordion() {
      let panel = document.getElementById(this.comp.id + "-active");
      if (panel.style.height) {
        panel.style.height = null;
      } else {
        panel.style.height = panel.scrollHeight + "px";
      }
    }
  }
};
</script>
