var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"cart-summary"}},[_c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"rounded-lg"},[_c('div',{class:("flex flex-col text-white rounded-lg px-4 py-2 text-center bg-" + _vm.colour)},[_c('h3',{staticClass:"text-lg font-semibold leading-tight"},[_vm._v("\n          Prodotti presenti nell'offerta\n        ")])])])]),(_vm.visible_products_in_invoice.length > 0)?_c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"rounded-lg"},[_c('div',{staticClass:"bg-white rounded-t-lg shadow-around"},[_c('ul',_vm._l((_vm.visible_products_in_invoice),function(product,index){return _c('li',{key:index,staticClass:"flex flex-wrap px-2 leading-tight border-b"},[_c('p',{class:("flex-1 p-2 text-linkem-" + _vm.colour)},[_vm._v("\n              "+_vm._s(product.title)+"\n            ")]),_vm._l((product.prices),function(price,index){return [_c('div',{key:("price-" + index),staticClass:"p-2 text-right"},[(
                    _vm.offer_targets &&
                      _vm.offer_targets[0].demerged_VAT &&
                      price.VAT_demerged_amount
                  )?_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.VAT_demerged_amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]):_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]),_c('span',{key:index,staticClass:"text-xs"},[_c('span',[_vm._v(_vm._s(price.payment_type.code == "recurring" ? "/mese" : ""))]),(
                      product.has_deferred_prices && price.total_payments > 1
                    )?_c('span',{key:index},[_vm._v("\n                    "+_vm._s(product.has_deferred_prices && price.total_payments > 1
                        ? "per " + price.total_payments + " mesi"
                        : ""))]):_vm._e()])])]})],2)}),0)]),(_vm.recurring_invoice_total >= 0)?_c('div',{class:("flex flex-col text-white rounded-b-lg px-4 py-2 bg-" + _vm.colour + "-primary")},[_c('ul',[_c('li',{staticClass:"flex flex-wrap text-xl font-semibold leading-tight"},[_c('p',{staticClass:"flex-1"},[_vm._v("Totale costo mensile")]),_c('div',{staticClass:"text-right"},[(
                  _vm.offer_targets &&
                    _vm.offer_targets[0].demerged_VAT &&
                    _vm.no_VAT_recurring_invoice_total
                )?_c('p',{staticClass:"pb-0 pl-2 lg:pt-0"},[_vm._v("\n                €\n                "+_vm._s(_vm._f("currency")(_vm.no_VAT_recurring_invoice_total," ", 2, {
                      decimalSeparator: ",",
                      spaceBetweenAmountAndSymbol: true
                    }))+"\n              ")]):_c('p',{staticClass:"pb-0 pl-2 lg:pt-0"},[_vm._v("\n                €\n                "+_vm._s(_vm._f("currency")(_vm.recurring_invoice_total," ", 2, {
                      decimalSeparator: ",",
                      spaceBetweenAmountAndSymbol: true
                    }))+"\n              ")]),_c('p',{staticClass:"pl-2 text-xs"},[_vm._v("/ mese")])])]),_c('li',[_c('p',{staticClass:"text-xs"},[_vm._v("\n              "+_vm._s(_vm.hasVat
                  ? "Prezzi IVA esclusa"
                  : "Tutti i costi indicati sono da intendersi IVA inclusa")+"\n            ")])])])]):_vm._e()])]):_vm._e(),(
      _vm.visible_products_not_in_invoice.length > 0 ||
        _vm.visible_recurring_products_not_in_invoice.length > 0
    )?_c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"rounded-lg"},[_c('div',{staticClass:"overflow-hidden bg-white rounded-lg shadow-around"},[_c('ul',[_vm._l((_vm.visible_recurring_products_not_in_invoice),function(product,index){return _c('li',{key:index,staticClass:"flex flex-wrap px-2 leading-tight border-b"},[_c('p',{class:("flex-1 p-2 text-linkem-" + _vm.colourUpfront)},[_vm._v("\n              "+_vm._s(product.title)+"\n            ")]),_vm._l((product.prices),function(price,index){return [_c('div',{key:("price-" + index),staticClass:"p-2 text-right"},[(
                    _vm.offer_targets &&
                      _vm.offer_targets[0].demerged_VAT &&
                      price.VAT_demerged_amount
                  )?_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.VAT_demerged_amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]):_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]),(
                    product.has_deferred_prices && price.total_payments > 1
                  )?_c('span',{key:index,staticClass:"text-xs"},[_vm._v("\n                  "+_vm._s(product.has_deferred_prices && price.total_payments > 1
                      ? "per " + price.total_payments + " mesi"
                      : "")+"\n                ")]):_vm._e()])]})],2)}),_vm._l((_vm.visible_products_not_in_invoice),function(product,index){return _c('li',{key:index,staticClass:"flex flex-wrap px-2 leading-tight border-b"},[_c('p',{class:("flex-1 p-2 text-linkem-" + _vm.colourUpfront)},[_vm._v("\n              "+_vm._s(product.title)+"\n            ")]),_vm._l((product.prices),function(price,index){return [_c('div',{key:("price-" + index),staticClass:"p-2 text-right"},[(
                    _vm.offer_targets &&
                      _vm.offer_targets[0].demerged_VAT &&
                      price.VAT_demerged_amount
                  )?_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.VAT_demerged_amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]):_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]),_c('span',{key:index,staticClass:"text-xs"},[_vm._v("\n                  "+_vm._s(price.payment_type.code == "recurring" &&
                    price.total_payments > 1
                      ? "/mese"
                      : "")+" ")]),(
                    product.has_deferred_prices && price.total_payments > 1
                  )?_c('span',{key:index,staticClass:"text-xs"},[_vm._v("\n                  "+_vm._s(product.has_deferred_prices && price.total_payments > 1
                      ? "per " + price.total_payments + " mesi"
                      : "")+"\n                ")]):_vm._e()])]})],2)}),(_vm.cod_amount)?_c('li',{staticClass:"flex flex-wrap p-2 border-b"},[_c('p',{staticClass:"flex-1"},[_vm._v("Pagamento contrassegno")]),_c('div',{key:("price-" + _vm.index),staticClass:"p-2 text-right"},[(
                  _vm.offer_targets &&
                    _vm.offer_targets[0].demerged_VAT &&
                    _vm.price.VAT_demerged_amount
                )?_c('span',{key:("price-" + _vm.index)},[_vm._v("\n                €\n                "+_vm._s(_vm._f("currency")(_vm.price.VAT_demerged_amount," ", 2, {
                      decimalSeparator: ",",
                      spaceBetweenAmountAndSymbol: true
                    }))+"\n              ")]):_c('span',{key:("price-" + _vm.index)},[_vm._v("\n                €\n                "+_vm._s(_vm._f("currency")(_vm.price.amount," ", 2, {
                      decimalSeparator: ",",
                      spaceBetweenAmountAndSymbol: true
                    }))+"\n              ")])])]):_vm._e(),(_vm.cart.offer_discount)?_c('li',{staticClass:"flex flex-wrap p-2 leading-tight border-b"},[_c('p',{staticClass:"flex-1 p-4 "},[_vm._v("\n              Codice sconto "+_vm._s(_vm.cart.offer_discount.code)+"\n            ")]),[_c('div',{key:("price-" + _vm.index),staticClass:"p-2 text-right"},[_c('span',{key:("price-" + _vm.index)},[_vm._v("\n                  "+_vm._s(_vm._f("currency")(_vm._.get(_vm.cart, "offer_discount.campaign.use_percentage")
                      ? "-" +
                        _vm._.get(_vm.cart, "offer_discount.campaign.value") +
                        "%"
                      : ("-" + _vm._.get(_vm.cart, "offer_discount.campaign.value")),"€ ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        }))+"\n                ")]),_c('span',[_vm._v(_vm._s(_vm._.get(
                      _vm.cart,
                      "offer_discount.campaign.payment_type.code"
                    ) == "recurring"
                      ? "/mese"
                      : "")+" ")]),(_vm._.get(_vm.cart, 'offer_discount.campaign.total_payments'))?_c('span',[_vm._v("\n                  "+_vm._s(_vm._.get(_vm.cart, "offer_discount.campaign.total_payments") > 1
                      ? "per " +
                        _vm._.get(
                          _vm.cart,
                          "offer_discount.campaign.total_payments"
                        ) +
                        " mesi"
                      : "per 1 mese")+"\n                ")]):_vm._e()])]],2):_vm._e(),(_vm._.get(_vm.cart, 'offer.refund_method.description'))?_c('li',{staticClass:"flex flex-wrap p-2 leading-tight border-b "},[_c('p',{staticClass:"px-2 text-xs"},[_vm._v("\n              "+_vm._s(_vm._.get(_vm.cart, "offer.refund_method.description"))+"\n            ")])]):_vm._e()],2),(
            _vm.upfront_total + _vm.recurring_not_invoice_total > 0 ||
              _vm.hasZeroUpfrontFromCoupon
          )?_c('div',{class:("flex flex-col text-white rounded-b-lg px-4 py-2 bg-" + _vm.colourUpfront + "-primary")},[_c('ul',[_c('li',{staticClass:"flex flex-wrap text-xl font-semibold leading-tight"},[_c('p',{staticClass:"flex-1"},[_vm._v("Totale costo una tantum")]),_c('div',{staticClass:"text-right"},[(
                    _vm.offer_targets &&
                      _vm.offer_targets[0].demerged_VAT &&
                      _vm.no_VAT_upfront_total
                  )?_c('p',{staticClass:"px-2"},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")((this.no_VAT_upfront_total +
                      this.cod_amount +
                      _vm.no_VAT_recurring_not_invoice_total)," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]):_c('p',{staticClass:"px-2"},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")((this.upfront_total +
                      this.cod_amount +
                      _vm.recurring_not_invoice_total)," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")])])]),_c('li',[_c('p',{staticClass:"text-xs"},[_vm._v("\n                "+_vm._s(_vm.hasVat
                    ? "Prezzi IVA esclusa"
                    : "Tutti i costi indicati sono da intendersi IVA inclusa")+"\n              ")])])])]):_vm._e()])])]):_vm._e(),(_vm.visible_products_deferred.length > 0)?_c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"rounded-lg"},[_c('div',{staticClass:"overflow-hidden bg-white rounded-lg shadow-around"},[_c('ul',[_vm._l((_vm.visible_products_deferred),function(product,index){return _c('li',{key:index,staticClass:"flex flex-wrap px-2 leading-tight border-b"},[_c('p',{class:("flex-1 p-2 text-linkem-" + _vm.colourUpfront)},[_vm._v("\n              "+_vm._s(product.title)+"\n            ")]),_vm._l((product.prices),function(price,index){return [_c('div',{key:("price-" + index),staticClass:"p-2 text-right"},[(
                    _vm.offer_targets &&
                      _vm.offer_targets[0].demerged_VAT &&
                      price.VAT_demerged_amount
                  )?_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.VAT_demerged_amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]):_c('span',{key:("price-" + index)},[_vm._v("\n                  €\n                  "+_vm._s(_vm._f("currency")(price.amount," ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      }))+"\n                ")]),_c('span',{key:index,staticClass:"text-xs"},[_vm._v("\n                  "+_vm._s("per " + price.total_payments + " mesi")+"\n                ")])])]})],2)}),(_vm._.get(_vm.cart, 'offer.refund_method.description'))?_c('li',{staticClass:"flex flex-wrap p-2 leading-tight border-b "},[_c('p',{staticClass:"px-2 text-xs"},[_vm._v("\n              "+_vm._s(_vm._.get(_vm.cart, "offer.refund_method.description"))+"\n            ")])]):_vm._e()],2),(_vm.deferred_total)?_c('div',{class:("flex flex-col text-white rounded-b-lg px-4 py-2 bg-" + _vm.colourUpfront + "-primary")},[_c('ul',[_c('li',{staticClass:"flex flex-wrap text-xl font-semibold leading-tight"},[_c('p',{staticClass:"flex-1"},[_vm._v("Contributo di attivazione")]),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"pb-0 pl-2 lg:pt-0"},[(
                      _vm.offer_targets &&
                        _vm.offer_targets[0].demerged_VAT &&
                        _vm.no_VAT_deferred_total
                    )?_c('span',[_vm._v("\n                    €\n                    "+_vm._s(_vm._f("currency")(_vm.no_VAT_deferred_total," ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        }))+"\n                  ")]):_c('span',[_vm._v("\n                    €\n                    "+_vm._s(_vm._f("currency")(_vm.deferred_total," ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        }))+"\n                  ")])]),_c('p',{staticClass:"text-xs bold"},[_vm._v("\n                  "+_vm._s(_vm._.get(_vm.cart, "offer.activation_message"))+"\n                ")])])]),_c('li',[_c('p',{staticClass:"text-xs"},[_vm._v("\n                "+_vm._s(_vm.hasVat
                    ? "Prezzi IVA esclusa"
                    : "Tutti i costi indicati sono da intendersi IVA inclusa")+"\n              ")])])])]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }