<template>
  <div class="px-4 mb-6 md:flex-none md:mb-0">
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="recaptchaSiteKey"
      @verify="onVerifyRecaptcha"
      @expired="onExpiredRecaptcha"
    ></vue-recaptcha>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    path: String,
    antiForgeryToken: String,
    recaptchaSiteKey: {
      type: String,
      default: "6LfhgWYUAAAAAPUtthEcqie2LGodNjp5GJ5fsocu"
    }
  },
  methods: {
    onSubmitRecaptcha() {
      this.$refs.recaptcha.execute();
    },

    async onVerifyRecaptcha(response) {
      try {
        window.vm.$wait.start("captcha");
        const test = await this.$axios.post(
          "/api/accounts/verifiedcaptcha",
          {
            ConfigurationItemID: this.configuration_id,
            code: response
          },
          { headers: { RequestVerificationToken: this.antiForgeryToken } }
        );
        if (!test.ok) {
          this.$root.$toast.open("Attenzione", test, {
            type: "warning"
          });
        }
      } catch (error) {
        this.$root.$toast.open("Errore", error, {
          type: "error"
        });
      } finally {
        window.vm.$wait.end("captcha");
        this.changeState("login");
      }
    },

    changeState(step) {
      this.$emit("update:step", step);
    },
    onExpiredRecaptcha() {
      this.handleRecaptcha();
    },

    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },

    handleRecaptcha() {
      this.resetRecaptcha();
    }
  }
};
</script>
