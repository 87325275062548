var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-auto mx-20"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-2/3 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.route'],"value":_vm.activation_address.route,"modelName":"checkout.address.activation_address.route","label":"Indirizzo","autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('route')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {route: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/6 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.street_number'],"value":_vm.activation_address.street_number,"modelName":"checkout.address.activation_address.street_number","label":"Civico","maxLength":("" + (_vm.inputLength.streetNumberLength)),"autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('street_number')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {street_number: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/6 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.floor'],"value":_vm.activation_address.floor,"modelName":"checkout.address.activation_address.floor","label":"Piano","maxLength":("" + (_vm.inputLength.floorLength)),"autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('floor')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {floor: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/3 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.administrative_area'],"value":_vm.activation_address.administrative_area,"modelName":"checkout.address.activation_address.administrative_area","label":"Provincia","maxLength":'2',"autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('administrative_area')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {administrative_area: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/3 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.city'],"value":_vm.activation_address.city,"modelName":"checkout.address.activation_address.city","label":"Comune","autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('city')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {city: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/3 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors['activation_address.postal_code'],"value":_vm.activation_address.postal_code,"modelName":"checkout.address.activation_address.postal_code","label":"CAP","maxLength":'5',"autocompleteField":"off","disabled":_vm.old_address.hasOwnProperty('postal_code')},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {postal_code: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors['activation_address.same_billing_address'],"value":_vm.activation_address.same_billing_address,"modelName":"checkout.address.activation_address.same_billing_address","message":"Usa lo stesso indirizzo per Fatturazione","disabled":_vm.old_address.billingAddress},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {same_billing_address: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors['activation_address.same_shipping_address'],"value":_vm.activation_address.same_shipping_address,"modelName":"checkout.address.activation_address.same_shipping_address","message":"Usa lo stesso indirizzo per Spedizione","disabled":_vm.old_address.billingAddress},on:{"input":function($event){return _vm.$emit('update:activation_address', Object.assign({}, _vm.activation_address,
              {same_shipping_address: $event}))}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }