<template>
  <div class="form-group">
    <div class="relative">
      <vue-simple-suggest
        :disabled="disabled"
        :name="modelName"
        :id="modelName.replace(/\./g, '_')"
        :title="label"
        :placeholder="label"
        :min-length="options ? 0 : 2"
        :debounce="1000"
        :value="value"
        :controls="controls"
        @select="$event ? $emit('input', $event) : null"
        display-attribute="label"
        value-attribute="code"
        :list="options ? options : searchOptions"
        :loading="loading"
        mode="select"
        :styles="autoCompleteStyle"
        autocomplete="off"
        ref="select"
        @show-list="listShown = true"
        @hide-list="listShown = false"
      ></vue-simple-suggest>
      <font-awesome-icon
        :icon="listShown ? 'chevron-up' : 'chevron-down'"
        class="absolute top-0 right-0 mx-4 h-full"
      ></font-awesome-icon>
    </div>
    <font-awesome-icon
      :key="`${modelName}-error-icon`"
      icon="exclamation-triangle"
      v-if="errorModel"
      class="text-error"
    ></font-awesome-icon>
    <span
      class="text-error pl-2"
      v-for="(item, index) in errorModel"
      :key="index"
      v-text="item"
    ></span>
  </div>
</template>
<script>
export default {
  props: {
    id: [String, Object],
    modelName: String,
    api: String,
    label: String,
    errorModel: [String, Object, Array],
    options: Array,
    disabled: Boolean,
    value: [String, Object]
  },
  data() {
    return {
      listShown: Boolean(),
      feedback: false,
      loading: false,
      controls: {
        selectionUp: [38],
        selectionDown: [40],
        select: [13, 9],
        showList: [40],
        hideList: [27]
      }
    };
  },
  methods: {
    searchOptions: async function(value) {
      this.loading = true;
      const list = await this.$axios.get(
        _.join(
          [window.location.origin, "api", "checkout", this.api, "?q=" + value],
          "/"
        )
      );
      return list.data;
    }
  },
  computed: {
    autoCompleteStyle() {
      return {
        defaultInput: `form-control w-full py-2 px-4 bg-gray-200 mx-auto border pr-10 ${
          this.errorModel ? "border-error" : ""
        }`
      };
    }
  }
};
</script>
<style>
.suggestions {
  @apply absolute;
  @apply w-full;
  @apply bg-white;
  @apply shadow-under;
  @apply z-40;
}
.suggest-item {
  @apply p-2;
  @apply border-b;
}
.suggest-item.hover {
  @apply bg-gray-100;
}
</style>
