<template>
  <a
    :href="leaveUrl"
    class="img__wrapper sm:bg-white sm:p-4 h-8 sm:h-full sm:inline-block flex-none"
    @click="leave_checkout"
  >
    <img
      :src="
        `/images/logo${target.charAt(0).toUpperCase() + target.slice(1)}.png`
      "
      heigth="auto"
      width="auto"
      alt="Logo Linkem"
    />
  </a>
</template>
<script>
export default {
  props: ["target", "leaveUrl", "isThanks"],
  data: function() {
    return {
      privacyCompleted: false
    };
  },
  mounted: function() {
    this.$events.on("checkout:privacy", value => {
      this.privacyCompleted = value;
    });
  },
  beforeDestroy() {
    this.$events.off("checkout:privacy");
  },
  methods: {
    leave_checkout: function(event) {
      if (!this.privacyCompleted && !this.isThanks) {
        event.preventDefault();
        this.$events.emit("toggleDrawer", [
          "Lascia i tuoi dati, sarai ricontattato",
          "contact-me",
          { leaveUrl: this.leaveUrl, origin: "ClientePotenziale" }
        ]);
      }
    }
  }
};
</script>
