<template>
  <transition name="transition-fade">
    <slot></slot>
  </transition>
</template>

<script>
export default {};
</script>

<style>
.transition-fade-enter {
  @apply opacity-0;
}
.transition-fade-enter-active {
  transition: opacity 0.5s;
}
.transition-fade-leave {
  @apply opacity-100;
}
.transition-fade-leave-active {
  transition: opacity 0.5s;
  @apply opacity-0;
}
</style>
