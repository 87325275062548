var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{class:_vm.$attrs._class,on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"flex-auto w-full px-4 mb-6 md:w-auto md:mb-0"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"w-full px-4 py-2 mb-2 border",class:{ 'border-error ': !_vm._.isEmpty(errors) },attrs:{"type":"text","placeholder":_vm.usernamePlaceholder},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('a',{staticClass:"block text-xs italic text-center cursor-pointer md:text-left",class:[
            ("hover:text-" + _vm.color + "-primary"),
            _vm._.isEmpty(errors) || "text-error"
          ],on:{"click":function($event){return _vm.changeState('recoverUsr')}}},[_vm._v(_vm._s(_vm.forgottenUsernameText))])]}}],null,true)})],1),_c('div',{staticClass:"flex-auto w-full px-4 mb-6 md:w-auto md:mb-0"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full px-4 py-2 mb-2 border",class:{ 'border-error ': !_vm._.isEmpty(errors) },attrs:{"type":"password","placeholder":_vm.passwordPlaceholder},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('a',{staticClass:"block text-xs italic text-center cursor-pointer md:text-left",class:[
            ("hover:text-" + _vm.color + "-primary"),
            _vm._.isEmpty(errors) || "text-error"
          ],on:{"click":function($event){return _vm.changeState('recoverPwd')}}},[_vm._v(_vm._s(_vm.forgottenPasswordText))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mb-6 text-center md:flex-none md:mb-0 md:w-auto"},[_c('label',{staticClass:"flex items-baseline block w-full font-bold text-gray-500 whitespace-no-wrap md:w-2/3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rememberMe),expression:"rememberMe"}],staticClass:"mr-2 leading-tight",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.rememberMe)?_vm._i(_vm.rememberMe,null)>-1:(_vm.rememberMe)},on:{"change":function($event){var $$a=_vm.rememberMe,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.rememberMe=$$a.concat([$$v]))}else{$$i>-1&&(_vm.rememberMe=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.rememberMe=$$c}}}}),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.rememberMeText))])])]),_c('div',{staticClass:"px-4 mb-2 md:flex-none md:mb-0"},[_c('button',{staticClass:"px-8 py-1 overflow-hidden text-sm text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed",class:[("bg-" + _vm.color), ("hover:bg-" + _vm.color + "-lighter")],attrs:{"type":"submit","disabled":invalid}},[_vm._v("\n        "+_vm._s(_vm.loginButtonText)+"\n      ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }