<template>
  <div class="justify-center"
    :class="checksRid ? 'px-4' : 'p-4'">
    <!-- <button
      :disabled="!validPhone || confirmedPhone"
      class="button-standard min-w-auto flex justify-center px-6 mx-auto"
      :class="confirmedPhone ? 'bg-verde' : `bg-${color}-primary`"
      @click="handleSubmit(sendOtp())"
    > -->
    <button
      :disabled="disabled"
      class="button-standard min-w-auto bg-verde flex justify-center px-6 mx-auto"
      @click="handleSubmit(sendOtp())"
    >
      <font-awesome-icon
        v-if="confirmed"
        icon="check"
        size="lg"
        class="text-white"
      />
      <span v-else>{{ checksRid ? "Invia sms di verifica" : "Verifica" }}</span>
    </button>
    <div
      v-show="showOtpCheck"
      class="bg-overlay fixed inset-0 z-10 flex flex-col justify-center"
    >
      <div class="z-50 self-center w-full max-w-md">
        <div
          class="flex justify-between px-4 py-2 text-white rounded-t-lg"
          :class="`bg-${color}`"
        >
          <h3 class=" no-margin padding-10 color-gray-alt">
            <span class="icon-container"> </span>
            {{ title }}
          </h3>
          <font-awesome-icon
            class="margin-0-4 cursor-pointer"
            icon="times"
            @click="cancel"
          ></font-awesome-icon>
        </div>
        <div class="card bg-white rounded-t-none">
          <p v-text="message" class="px-4 mb-4"></p>
          <div>
            <input-element
              class=" w-full"
              :rules="{ required: showOtpCheck, digits: 6 }"
              name="codice di conferma"
              v-model="verificationCode"
              label="Codice di conferma"
            />
          </div>
          <div class="flex py-4">
            <!-- <button
              class="button-standard min-w-auto flex justify-center px-6 mx-auto"
              :class="`bg-${color}-primary`"
              @click="sendOtp"
            > -->
            <button
              class="button-standard min-w-auto bg-grigio flex justify-center px-6 mx-auto"
              @click="sendOtp"
            >
              Reinvia Codice
            </button>
            <!-- <button
              class="button-standard min-w-auto flex justify-center px-6 mx-auto"
              :class="`bg-${color}-primary`"
              @click="checkOtp"
            > -->
            <button
              class="button-standard min-w-auto bg-verde flex justify-center px-6 mx-auto"
              @click="checkOtp"
            >
              Conferma
            </button>
            <ValidationProvider
              immediate
              name="verified"
              :rules="{ required: { allowFalse: false } }"
            >
              <input
                :value="checksRid ? confirmedIban : confirmedPhone"
                v-show="false"
              />
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    verificationCode: String(),
    showOtpCheck: Boolean(),
    message: String()
  }),
  props: {
    phone: String,
    iban: String,
    validable: Boolean,
    handleSubmit: Function,
    confirmedPhone: Boolean,
    confirmedIban: Boolean,
    checksRid: Boolean
  },
  watch: {
    phone: function(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit(`update:${this.eventName}`, false);
      }
    },
    iban: function(newVal, oldVal) {
      if (oldVal !== newVal && this.checksRid) {
        this.$emit(`update:${this.eventName}`, false);
      }
    }
  },
  computed: {
    title() {
      return this.checksRid
        ? "Conferma Codice IBAN"
        : "Verifica del numero di telefono";
    },
    disabled() {
      return this.checksRid
        ? !this.phone || !this.iban || !this.validable || this.confirmedIban
        : !this.phone || !this.validable || this.confirmedPhone;
    },
    eventName() {
      return this.checksRid ? "confirmed-iban" : "confirmed-phone";
    },
    confirmed() {
      return this.checksRid ? this.confirmedIban : this.confirmedPhone;
    }
  },
  methods: {
    async sendOtp() {
      try {
        window.vm.$wait.start("otp");
        const answer = await this.$axios.post("/api/send_otp", {
          telephone: this.phone,
          checksRid: this.checksRid
        });
        this.showOtpCheck = true;
        this.message = answer.data.message;
        return;
      } catch (error) {
        if (error.response.status == 420) {
          this.showOtpCheck = false;
          this.$events.emit("toggleDrawer", [
            "Lascia i tuoi dati, sarai ricontattato",
            "contact-me",
            {
              leaveContactMessage: error.response.data.message,
              leaveUrl: "http://web.linkem.com",
              origin: "ClientePotenziale"
            }
          ]);
        }

        return error;
      } finally {
        window.vm.$wait.end("otp");
      }
    },
    async checkOtp() {
      try {
        await this.$axios.post("/api/otp_validate", {
          otpCode: this.verificationCode,
          checksRid: this.checksRid
        });
        this.verificationCode = undefined;
        this.showOtpCheck = false;
        this.$emit(`update:${this.eventName}`, true);
        return;
      } catch (error) {
        return error;
      }
    },
    cancel() {
      this.verificationCode = undefined;
      this.showOtpCheck = false;
    }
  }
};
</script>
<style scoped>
.card {
  @apply rounded-b-lg shadow-around p-2 py-6 bg-white mx-auto;
}
</style>
