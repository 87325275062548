<template>
  <div id="checkout_contact_data_form" v-if="step == step_name">
    <step-header
      title="Dati di contatto"
      icon="envelope"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="transition checkout-form content section-light">
        <div class="mx-20 mx-auto">
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.name"
                :value="contact_data.name"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    name: $event
                  })
                "
                modelName="checkout.contact_data.name"
                label="Nome"
                :maxLength="inputLength.nameLength"
                autocompleteField="section-contact_data given-name"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.last_name"
                :value="contact_data.last_name"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    last_name: $event
                  })
                "
                modelName="checkout.contact_data.last_name"
                label="Cognome"
                :maxLength="inputLength.lastNameLength"
                autocompleteField="section-contact_data family-name"
              ></linkem-input>
            </div>
          </div>
          <form-email
            v-model="contact_data.email"
            :errorModel="checkout_errors.email"
            @input="
              $emit('update:contact_data', {
                ...contact_data,
                email: $event
              })
            "
            modelName="checkout.contact_data.email"
            :maxLength="inputLength.emailLength"
            autocompleteField="section-contact_data email"
            :confirm-email="contact_data.confirmEmail"
            @update:confirm-email="
              $emit('update:contact_data', {
                ...contact_data,
                confirmEmail: $event
              })
            "
          />

          <form-cellphone
            :prefixes="prefixes"
            :prefix-error-model="checkout_errors.international_prefix"
            :prefix="contact_data.international_prefix"
            :phone-error-model="checkout_errors.mobile_phone"
            :phone="contact_data.mobile_phone"
            :confirmed-phone="confirmedPhone"
            @update:confirmed-phone="$emit('update:confirmed-phone', $event)"
            @update:prefix="
              $emit('update:contact_data', {
                ...contact_data,
                international_prefix: $event
              })
            "
            @update:phone="
              $emit('update:contact_data', {
                ...contact_data,
                mobile_phone: $event
              })
            "
            :otp-check="otpCheck"
            :max-length="inputLength.mobileLength"
          />
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 checkbox">
              <linkem-checkbox
                :errorModel="checkout_errors.flag_contacts"
                :value="contact_data.flag_contacts"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    flag_contacts: $event
                  })
                "
                modelName="checkout.contact_data.flag_contacts"
                :message="checkboxMessage"
              ></linkem-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    contact_data: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    checkboxMessage: {
      type: String,
      required: true
    },
    inputLength: {
      required: true
    },
    prefixes: {
      required: true
    },
    colour: {
      default: "arancio"
    },
    otpCheck: Boolean,
    confirmedPhone: Boolean
  },
  data: function() {
    return {
      step_name: "contact_data"
    };
  }
};
</script>
