<template>
  <div class="w-full justify-center p-4">
    <div class="info__wrapper">
      <h2
        class="block text-xl font-bold uppercase mb-4"
        :class="`text-${color}-primary`"
      >
        Prima di acquistare
      </h2>
      <ul class="flex flex-wrap">
        <li
          class="info__wrapper__doc"
          v-for="item in itemList"
          :key="item.title"
        >
          <div class="flex">
            <font-awesome-icon
              class="mr-2"
              :class="`text-${color}-primary`"
              icon="chevron-right"
              @click="menu = !menu"
            ></font-awesome-icon>
            <div class="flex flex-col">
              <a
                class="uppercase text-lg leading-tight pb-1"
                :class="`text-${color}-primary`"
                :href="item.url"
                >{{ item.title }}</a
              >
              <p class="text-sm">{{ item.copy }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    "item-list": Array
  }
};
</script>
