<template>
  <div class="flex flex-wrap justify-around">
    <input-element
      class="lg:w-1/2 w-full py-4 lg:py-0"
      rules="required|email|confirmed:Conferma e-mail"
      name="E-mail"
      :value="value"
      label="E-mail"
      type="email"
      @input="$emit('input', $event)"
      :model-name="modelName"
      :max-length="maxLength"
      :autocomplete-field="autocompleteField"
      :error-model="errorModel"
    />
    <input-element
      class="lg:w-1/2 w-full py-4 lg:py-0"
      rules="required"
      name="Conferma e-mail"
      :value="confirmEmail"
      @input="$emit('update:confirm-email', $event)"
      label="Conferma e-mail"
      type="email"
      :max-length="maxLength"
    />
  </div>
</template>

<script>
export default {
  props: {
    confirmEmail: String,
    value: String,
    modelName: String,
    maxLength: String,
    autocompleteField: String,
    errorModel: [String, Object, Array]
  }
};
</script>
