<template>
  <div id="checkout_address_form" v-if="step == step_name">
    <step-header
      title="Indirizzi"
      icon="map-marker-alt"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto mx-20">
          <div class="flex flex-wrap justify-around">
            <div class="py-3 px-4 w-full" :class="`text-${colour}-primary`">
              <span class="icon-container">
                <font-awesome-icon icon="home"></font-awesome-icon>
              </span>
              <span> Indirizzo attivazione </span>
            </div>
          </div>
          <activation-address
            v-bind.sync="address"
            @input="
              $emit('update:address', {
                ...address,
                activation_address: $event
              })
            "
            :checkout_errors="checkout_errors"
            :inputLength="inputLength"
            :account="account"
            :old_address="old_address"
          >
          </activation-address>
          <div v-if="!address.activation_address.same_billing_address">
            <div class="flex flex-wrap justify-around">
              <div class="py-3 px-4 w-full" :class="`text-${colour}-primary`">
                <span class="icon-container">
                  <font-awesome-icon icon="home"></font-awesome-icon>
                </span>
                <span> Indirizzo fatturazione </span>
              </div>
            </div>
            <billing-address
              v-bind.sync="address"
              @input="
                $emit('update:address', { ...address, billing_address: $event })
              "
              :checkout_errors="checkout_errors"
              :inputLength="inputLength"
              :account="account"
              :google="google"
            >
            </billing-address>
          </div>
          <div v-if="!address.activation_address.same_shipping_address">
            <div class="flex flex-wrap justify-around">
              <div class="py-3 px-4 w-full" :class="`text-${colour}-primary`">
                <span class="icon-container">
                  <font-awesome-icon icon="home"></font-awesome-icon>
                </span>
                <span> Indirizzo spedizione </span>
              </div>
            </div>
            <shipping-address
              v-bind.sync="address"
              @input="
                $emit('update:address', {
                  ...address,
                  shipping_address: $event
                })
              "
              :checkout_errors="checkout_errors"
              :inputLength="inputLength"
              :account="account"
              :google="google"
            >
            </shipping-address>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    address: {
      required: true
    },
    checkout_errors: {
      required: true,
      default: {}
    },
    inputLength: {
      required: true
    },
    account: {
      required: true
    },
    old_address: {
      required: true
    },
    colour: {
      default: "arancio"
    },
    google: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      step_name: "address"
    };
  }
};
</script>
