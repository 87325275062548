<template>
  <transition-expand>
    <div v-if="show" class="bg-gray-200 overflow-y-auto max-h-screen">
      <div class="container mx-auto p-4">
        <div class="flex justify-between items-baseline mb-4">
          <h4
            class="font-bold uppercase mx-auto md:mx-0"
            :class="`text-${color}-primary`"
          >
            {{ title }}
          </h4>
          <button class="px-2 -ml-5 md:ml-0" @click="show = false">
            <font-awesome-icon icon="times"></font-awesome-icon>
          </button>
        </div>
        <component
          :is="_.kebabCase(step)"
          :step.sync="step"
          :path="paths[step]"
          :configuration-item-id="configurationItemId"
          :anti-forgery-token="antiForgeryToken"
          _class="flex flex-wrap md:flex-no-wrap -mx-4 items-baseline justify-center"
          v-bind="labels"
          :redirect-url="redirectUrl"
          :change-password-url="changePasswordUrl"
        ></component>
      </div>
    </div>
  </transition-expand>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    show: false,
    step: "login"
  }),
  props: {
    login: { type: String, required: true },
    logout: { type: String, required: true },
    recoverPwd: { type: String, required: true },
    recoverUsr: { type: String, required: true },
    captcha: { type: String, required: true },
    configurationItemId: { type: String, required: true },
    antiForgeryToken: String,
    labels: Object,
    changePasswordUrl: String,
    redirectUrl: String
  },
  computed: {
    paths() {
      return {
        login: this.login,
        logout: this.logout,
        recoverPwd: this.recoverPwd,
        recoverUsr: this.recoverUsr
      };
    },
    title() {
      return _.get(this.label, "HeaderLabelText") || this.step === "logout"
        ? "Logout"
        : "Entra nell'area clienti";
    }
  },
  events: {
    toggleLogin(step) {
      this.show = !this.show;
      !this.show || (this.step = step);
    }
  }
};
</script>
