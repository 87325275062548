<template>
  <ValidationProvider
    :rules="rules"
    :name="name"
    v-slot="{ errors }"
    class="flex-inline w-full px-4"
    tag="div"
  >
    <div class="relative">
      <input
        :placeholder="label"
        @focus="focused = true"
        @blur="focused = false"
        class="form__input placeholder-gray-600"
        :class="{ 'form__input--error': errors[0] }"
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :model-name="modelName"
        :maxlength="maxLength"
        :autcomplete-field="autocompleteField"
        :error-model="errorModel"
      />
      <font-awesome-icon
        icon="exclamation-triangle"
        v-if="errorModel"
        class="text-error"
      ></font-awesome-icon>
      <span
        class="text-error pl-2"
        v-html="item"
        v-for="(item, index) in errorModel"
        :key="index"
        >{{ item }}</span
      >
    </div>
    <div v-if="errors[0]" class="block">
      <font-awesome-icon
        icon="exclamation-triangle"
        v-if="errors[0]"
        class="text-error"
      ></font-awesome-icon>
      <span class="text-error pl-2" v-text="errors[0]" />
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    value: String,
    label: String,
    rules: [Object, String],
    type: String,
    name: String,
    modelName: String,
    maxLength: Number,
    autocompleteField: String,
    errorModel: [String, Object, Array],
    appendIcon: String
  },
  data: () => ({
    focused: Boolean()
  }),
  computed: {
    active() {
      return this.focused || this.value;
    }
  }
};
</script>
<style scoped>
.form__label {
  @apply absolute py-2 px-4 pointer-events-none;
}
.form__label--active {
  @apply pt-1;
  font-size: 0.6rem;
}
.form__input {
  @apply border block w-full h-input bg-gray-200 px-4 py-2;
}
.form__input--error {
  @apply border-red-600;
}
.form__error-text {
  @apply inline-block text-red-600 leading-tight text-xs py-1 ml-4;
}
</style>
