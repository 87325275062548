var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step == _vm.step_name)?_c('div',{attrs:{"id":"checkout_personal_data_form"}},[_c('step-header',_vm._g({attrs:{"title":"Dati personali","icon":"personal-data","colour":_vm.colour}},_vm.$listeners)),_c('div',{staticClass:"bg-white rounded-b-lg"},[_c('div',{staticClass:"checkout-form content section-light transition"},[_c('div',{staticClass:"mx-auto mx-20"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.name_legal,"value":_vm.personal_data.name_legal,"modelName":"checkout.personal_data.name_legal","label":"Nome","maxLength":_vm.inputLength.nameLength,"autocompleteField":"section-legal_data given-name"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {name_legal: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.last_name_legal,"value":_vm.personal_data.last_name_legal,"modelName":"checkout.personal_data.last_name_legal","label":"Cognome","maxLength":_vm.inputLength.lastNameLength,"autocompleteField":"section-legal_data family-name"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {last_name_legal: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center date-time-container"},[_c('linkem-datetime',{attrs:{"errorModel":_vm.checkout_errors.birth_date_legal,"value":_vm.personal_data.birth_date_legal,"modelName":"checkout.personal_data.birth_date_legal","label":"Data di Nascita","idName":"birth_date_legal"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {birth_date_legal: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('input-autocomplete',{attrs:{"errorModel":_vm.checkout_errors.sex_legal,"value":_vm.personal_data.sex_legal,"modelName":"checkout.personal_data.sex_legal","label":"Selezionare il sesso","options":_vm.sex},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {sex_legal: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('input-autocomplete',{attrs:{"errorModel":_vm.checkout_errors.birth_country_legal,"value":_vm.personal_data.birth_country_legal,"modelName":"checkout.personal_data.birth_country_legal","label":"Paese di Nascita","api":"getnations"},on:{"input":function($event){_vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {birth_country_legal: $event}));
                _vm.checkEnable($event);}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[(_vm.enabled)?_c('input-autocomplete',{attrs:{"value":_vm.personal_data.birth_city_legal,"modelName":"checkout.personal_data.birth_city_legal","label":"Comune di Nascita","disabled":!_vm.enabled,"api":"getlocations","errorModel":_vm.checkout_errors.birth_city_legal},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {birth_city_legal: $event}))}}}):_vm._e()],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-full justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.fiscal_code_legal,"value":_vm.personal_data.fiscal_code_legal,"modelName":"checkout.personal_data.fiscal_code_legal","label":"Codice fiscale","maxLength":_vm.inputLength.taxCodeLength,"autocompleteField":"on"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {fiscal_code_legal: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.document_number_legal,"value":_vm.personal_data.document_number_legal,"modelName":"checkout.personal_data.document_number_legal","label":"Numero documento","maxLength":_vm.inputLength.docNumberLength,"autocompleteField":"on"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {document_number_legal: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('input-autocomplete',{attrs:{"errorModel":_vm.checkout_errors.document_type_legal,"value":_vm.personal_data.document_type_legal,"modelName":"checkout.personal_data.document_type_legal","label":"Selezionare il tipo documento","options":_vm.document_types},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {document_type_legal: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center date-time-container"},[_c('linkem-datetime',{attrs:{"errorModel":_vm.checkout_errors.released_at_legal,"value":_vm.personal_data.released_at_legal,"modelName":"checkout.personal_data.released_at_legal","label":"Rilasciato il","idName":"legal_released_at"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {released_at_legal: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.released_by_legal,"value":_vm.personal_data.released_by_legal,"modelName":"checkout.personal_data.released_by_legal","label":"Documento legale rilasciato da","maxLength":_vm.inputLength.docReleasedFromLength,"autocompleteField":"on"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {released_by_legal: $event}))}}})],1)])]),_c('div',{staticClass:"mx-auto mx-20"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.operational_name,"value":_vm.personal_data.operational_name,"modelName":"checkout.personal_data.operational_name","label":"Nome","maxLength":_vm.inputLength.nameLength,"autocompleteField":"section-operational_data given-name"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {operational_name: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.operational_last_name,"value":_vm.personal_data.operational_last_name,"modelName":"checkout.personal_data.operational_last_name","label":"Cognome","maxLength":_vm.inputLength.lastNameLength,"autocompleteField":"section-operational_data family-name"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {operational_last_name: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.operational_email,"value":_vm.personal_data.operational_email,"modelName":"checkout.personal_data.operational_email","label":"Email","maxLength":_vm.inputLength.emailLength,"autocompleteField":"section-operational_data email"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {operational_email: $event}))}}})],1),_c('div',{staticClass:"p-4 w-full lg:w-1/2 justify-center"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.operational_mobile_phone,"value":_vm.personal_data.operational_mobile_phone,"modelName":"checkout.personal_data.operational_mobile_phone","label":"Telefono","maxLength":_vm.inputLength.mobileLength,"autocompleteField":"section-operational_data tel"},on:{"input":function($event){return _vm.$emit('update:personal_data', Object.assign({}, _vm.personal_data,
                  {operational_mobile_phone: $event}))}}})],1)])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }