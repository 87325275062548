<template>
  <div>
    <div class="mx-auto mx-20">
      <div class="flex flex-wrap justify-around">
        <div class="p-4 w-full lg:w-2/3 justify-center">
          <linkem-input
            :errorModel="checkout_errors['activation_address.route']"
            :value="activation_address.route"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                route: $event
              })
            "
            modelName="checkout.address.activation_address.route"
            label="Indirizzo"
            autocompleteField="off"
            :disabled="old_address.hasOwnProperty('route')"
          ></linkem-input>
        </div>
        <div class="p-4 w-full lg:w-1/6 justify-center">
          <linkem-input
            :errorModel="checkout_errors['activation_address.street_number']"
            :value="activation_address.street_number"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                street_number: $event
              })
            "
            modelName="checkout.address.activation_address.street_number"
            label="Civico"
            :maxLength="`${inputLength.streetNumberLength}`"
            autocompleteField="off"
            :disabled="
              old_address.hasOwnProperty('street_number')
            "
          ></linkem-input>
        </div>
        <div class="p-4 w-full lg:w-1/6 justify-center">
          <linkem-input
            :errorModel="checkout_errors['activation_address.floor']"
            :value="activation_address.floor"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                floor: $event
              })
            "
            modelName="checkout.address.activation_address.floor"
            label="Piano"
            :maxLength="`${inputLength.floorLength}`"
            autocompleteField="off"
            :disabled="old_address.hasOwnProperty('floor')"
          ></linkem-input>
        </div>
      </div>
      <div class="flex flex-wrap justify-around">
        <div class="p-4 w-full lg:w-1/3 justify-center">
          <linkem-input
            :errorModel="
              checkout_errors['activation_address.administrative_area']
            "
            :value="activation_address.administrative_area"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                administrative_area: $event
              })
            "
            modelName="checkout.address.activation_address.administrative_area"
            label="Provincia"
            :maxLength="'2'"
            autocompleteField="off"
            :disabled="old_address.hasOwnProperty('administrative_area')"
          ></linkem-input>
        </div>
        <div class="p-4 w-full lg:w-1/3 justify-center">
          <linkem-input
            :errorModel="checkout_errors['activation_address.city']"
            :value="activation_address.city"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                city: $event
              })
            "
            modelName="checkout.address.activation_address.city"
            label="Comune"
            autocompleteField="off"
            :disabled="old_address.hasOwnProperty('city')"
          ></linkem-input>
        </div>
        <div class="p-4 w-full lg:w-1/3 justify-center">
          <linkem-input
            :errorModel="checkout_errors['activation_address.postal_code']"
            :value="activation_address.postal_code"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                postal_code: $event
              })
            "
            modelName="checkout.address.activation_address.postal_code"
            label="CAP"
            :maxLength="'5'"
            autocompleteField="off"
            :disabled="old_address.hasOwnProperty('postal_code')"
          ></linkem-input>
        </div>
      </div>
      <div class="flex flex-wrap justify-around">
        <div class="p-4 w-full lg:w-1/2 justify-center">
          <linkem-checkbox
            :errorModel="
              checkout_errors['activation_address.same_billing_address']
            "
            :value="activation_address.same_billing_address"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                same_billing_address: $event
              })
            "
            modelName="checkout.address.activation_address.same_billing_address"
            :message="`Usa lo stesso indirizzo per Fatturazione`"
            :disabled="old_address.billingAddress"
          ></linkem-checkbox>
        </div>
        <div class="p-4 w-full lg:w-1/2 justify-center">
          <linkem-checkbox
            :errorModel="
              checkout_errors['activation_address.same_shipping_address']
            "
            :value="activation_address.same_shipping_address"
            @input="
              $emit('update:activation_address', {
                ...activation_address,
                same_shipping_address: $event
              })
            "
            modelName="checkout.address.activation_address.same_shipping_address"
            :message="`Usa lo stesso indirizzo per Spedizione`"
            :disabled="old_address.billingAddress"
          ></linkem-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activation_address: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    inputLength: {
      required: true
    },
    account: {
      required: true
    },
    old_address: {
      required: true
    }
  },
  data: function() {
    return {
      step_name: "address"
    };
  }
};
</script>
