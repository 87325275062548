<template>
  <div id="checkout_personal_data_form" v-if="step == step_name">
    <step-header
      title="Dati personali"
      icon="personal-data"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto mx-20">
          <!-- <h4 class="bold color-orange">
          Rappresentante Legale
          </h4>-->
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.name_legal"
                :value="personal_data.name_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    name_legal: $event
                  })
                "
                modelName="checkout.personal_data.name_legal"
                label="Nome"
                :maxLength="inputLength.nameLength"
                autocompleteField="section-legal_data given-name"
              ></linkem-input>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.last_name_legal"
                :value="personal_data.last_name_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    last_name_legal: $event
                  })
                "
                modelName="checkout.personal_data.last_name_legal"
                label="Cognome"
                :maxLength="inputLength.lastNameLength"
                autocompleteField="section-legal_data family-name"
              ></linkem-input>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center date-time-container">
              <linkem-datetime
                :errorModel="checkout_errors.birth_date_legal"
                :value="personal_data.birth_date_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_date_legal: $event
                  })
                "
                modelName="checkout.personal_data.birth_date_legal"
                label="Data di Nascita"
                idName="birth_date_legal"
              ></linkem-datetime>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.sex_legal"
                :value="personal_data.sex_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    sex_legal: $event
                  })
                "
                modelName="checkout.personal_data.sex_legal"
                label="Selezionare il sesso"
                :options="sex"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.birth_country_legal"
                :value="personal_data.birth_country_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_country_legal: $event
                  });
                  checkEnable($event);
                "
                modelName="checkout.personal_data.birth_country_legal"
                label="Paese di Nascita"
                api="getnations"
              ></input-autocomplete>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                v-if="enabled"
                :value="personal_data.birth_city_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_city_legal: $event
                  })
                "
                modelName="checkout.personal_data.birth_city_legal"
                label="Comune di Nascita"
                :disabled="!enabled"
                api="getlocations"
                :errorModel="checkout_errors.birth_city_legal"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-full justify-center">
              <linkem-input
                :errorModel="checkout_errors.fiscal_code_legal"
                :value="personal_data.fiscal_code_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    fiscal_code_legal: $event
                  })
                "
                modelName="checkout.personal_data.fiscal_code_legal"
                label="Codice fiscale"
                :maxLength="inputLength.taxCodeLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.document_number_legal"
                :value="personal_data.document_number_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    document_number_legal: $event
                  })
                "
                modelName="checkout.personal_data.document_number_legal"
                label="Numero documento"
                :maxLength="inputLength.docNumberLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.document_type_legal"
                :value="personal_data.document_type_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    document_type_legal: $event
                  })
                "
                modelName="checkout.personal_data.document_type_legal"
                label="Selezionare il tipo documento"
                :options="document_types"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center date-time-container">
              <linkem-datetime
                :errorModel="checkout_errors.released_at_legal"
                :value="personal_data.released_at_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    released_at_legal: $event
                  })
                "
                modelName="checkout.personal_data.released_at_legal"
                label="Rilasciato il"
                idName="legal_released_at"
              ></linkem-datetime>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.released_by_legal"
                :value="personal_data.released_by_legal"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    released_by_legal: $event
                  })
                "
                modelName="checkout.personal_data.released_by_legal"
                label="Documento legale rilasciato da"
                :maxLength="inputLength.docReleasedFromLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
          </div>
        </div>
        <div class="mx-auto mx-20">
          <!-- <h4 class="bold color-orange">
          Rappresentante Operativo
          </h4>-->
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.operational_name"
                :value="personal_data.operational_name"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    operational_name: $event
                  })
                "
                modelName="checkout.personal_data.operational_name"
                label="Nome"
                :maxLength="inputLength.nameLength"
                autocompleteField="section-operational_data given-name"
              ></linkem-input>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.operational_last_name"
                :value="personal_data.operational_last_name"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    operational_last_name: $event
                  })
                "
                modelName="checkout.personal_data.operational_last_name"
                label="Cognome"
                :maxLength="inputLength.lastNameLength"
                autocompleteField="section-operational_data family-name"
              ></linkem-input>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.operational_email"
                :value="personal_data.operational_email"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    operational_email: $event
                  })
                "
                modelName="checkout.personal_data.operational_email"
                label="Email"
                :maxLength="inputLength.emailLength"
                autocompleteField="section-operational_data email"
              ></linkem-input>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.operational_mobile_phone"
                :value="personal_data.operational_mobile_phone"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    operational_mobile_phone: $event
                  })
                "
                modelName="checkout.personal_data.operational_mobile_phone"
                label="Telefono"
                :maxLength="inputLength.mobileLength"
                autocompleteField="section-operational_data tel"
              ></linkem-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    personal_data: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    inputLength: {
      required: true
    },
    colour: {
      default: "arancio"
    }
  },
  data: function() {
    return {
      step_name: "personal_data",
      sex: [
        { code: "F", label: "Femminile" },
        { code: "M", label: "Maschile" }
      ],
      document_types: [
        { code: "CartaDiIdentita", label: "Carta d'identità" },
        { code: "Passaporto", label: "Passaporto" },
        { code: "PatenteDiGuida", label: "Patente di guida" }
      ],
      enabled: false
    };
  },
  methods: {
    checkEnable(birth_country_legal) {
      this.enabled = birth_country_legal.code == "ITALIA;Z000;IT";
      // this.personal_data.birth_city_legal = "";
      // this.checkout_errors.birth_city_legal = "";
    }
  }
};
</script>
