<template>
  <form :action="externalUrl" method="post" ref="externalForm">
    <input
      type="hidden"
      name="Coordinates[latitude]"
      :value="coordinates ? coordinates.latitude : undefined"
    />
    <input
      type="hidden"
      name="Coordinates[longitude]"
      :value="coordinates ? coordinates.longitude : undefined"
    />
    <input
      type="hidden"
      name="Address[street_number]"
      :value="address ? address.street_number : undefined"
    />
    <input
      type="hidden"
      name="Address[city]"
      :value="address ? address.city : undefined"
    />
    <input
      type="hidden"
      name="Address[route]"
      :value="address ? address.route : undefined"
    />
    <input
      type="hidden"
      name="Address[administrative_area]"
      :value="address ? address.administrative_area : undefined"
    />
    <input
      type="hidden"
      name="Address[postal_code]"
      :value="address ? address.postal_code : undefined"
    />
    <input
      type="hidden"
      name="Address[country]"
      :value="address ? address.country : undefined"
    />
    <input
      type="hidden"
      name="Address[country_code]"
      :value="address ? address.country_code : undefined"
    />
    <input
      type="hidden"
      name="Address[full_address]"
      :value="address ? address.full_address : undefined"
    />
    <input type="hidden" name="target" :value="target" />
    <input type="hidden" name="offerCode" :value="offerCode" />
  </form>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["externalUrl", "address", "coordinates", "target", "offerCode"],
  methods: {
    submit() {
      this.$refs.form.submit();
    }
  }
};
</script>
