<script>
//   import { Select } from 'element-ui';
//   import { isIE, isEdge } from 'element-ui/src/utils/util';
//   export default {
//     extends: Select,

//     computed: {
//       readonly() {
//         return !(this.filterable || this.multiple || (!this.visible && !isIE() && !isEdge()));
//       }
//     },
//     methods: {
//       setSoftFocus() {
//         this.softFocus = true;
//         const input = this.$refs.input || this.$refs.reference;
//         if (input && !this.value) {
//           input.focus();
//         }
//       },

//     }

//   }
</script>
