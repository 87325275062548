<template>
  <section id="cart-summary">
    <div class="px-4 py-2">
      <div class="rounded-lg">
        <div
          :class="
            `flex flex-col text-white rounded-lg px-4 py-2 text-center bg-${colour}`
          "
        >
          <h3 class="text-lg font-semibold leading-tight">
            Prodotti presenti nell'offerta
          </h3>
        </div>
      </div>
    </div>
    <div class="px-4 py-2" v-if="visible_products_in_invoice.length > 0">
      <div class="rounded-lg">
        <div class="bg-white rounded-t-lg shadow-around">
          <ul>
            <li
              v-for="(product, index) in visible_products_in_invoice"
              :key="index"
              class="flex flex-wrap px-2 leading-tight border-b"
            >
              <p :class="`flex-1 p-2 text-linkem-${colour}`">
                {{ product.title }}
              </p>
              <template v-for="(price, index) in product.prices">
                <div :key="`price-${index}`" class="p-2 text-right">
                  <span
                    :key="`price-${index}`"
                    v-if="
                      offer_targets &&
                        offer_targets[0].demerged_VAT &&
                        price.VAT_demerged_amount
                    "
                  >
                    €
                    {{
                      price.VAT_demerged_amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span :key="`price-${index}`" v-else>
                    €
                    {{
                      price.amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span class="text-xs" :key="index">
                    <span>{{
                      price.payment_type.code == "recurring" ? "/mese" : ""
                    }}</span
                    ><span
                      v-if="
                        product.has_deferred_prices && price.total_payments > 1
                      "
                      :key="index"
                    >
                      {{
                        product.has_deferred_prices && price.total_payments > 1
                          ? "per " + price.total_payments + " mesi"
                          : ""
                      }}</span
                    >
                  </span>
                </div>
              </template>
            </li>
          </ul>
        </div>
        <div
          v-if="recurring_invoice_total >= 0"
          :class="
            `flex flex-col text-white rounded-b-lg px-4 py-2 bg-${colour}-primary`
          "
        >
          <ul>
            <li class="flex flex-wrap text-xl font-semibold leading-tight">
              <p class="flex-1">Totale costo mensile</p>
              <div class="text-right">
                <p
                  class="pb-0 pl-2 lg:pt-0"
                  v-if="
                    offer_targets &&
                      offer_targets[0].demerged_VAT &&
                      no_VAT_recurring_invoice_total
                  "
                >
                  €
                  {{
                    no_VAT_recurring_invoice_total
                      | currency(" ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}
                </p>
                <p class="pb-0 pl-2 lg:pt-0" v-else>
                  €
                  {{
                    recurring_invoice_total
                      | currency(" ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}
                </p>
                <p class="pl-2 text-xs">/ mese</p>
              </div>
            </li>
            <li>
              <p class="text-xs">
                {{
                  hasVat
                    ? "Prezzi IVA esclusa"
                    : "Tutti i costi indicati sono da intendersi IVA inclusa"
                }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="px-4 py-2"
      v-if="
        visible_products_not_in_invoice.length > 0 ||
          visible_recurring_products_not_in_invoice.length > 0
      "
    >
      <div class="rounded-lg">
        <div class="overflow-hidden bg-white rounded-lg shadow-around">
          <ul>
            <li
              v-for="(product,
              index) in visible_recurring_products_not_in_invoice"
              :key="index"
              class="flex flex-wrap px-2 leading-tight border-b"
            >
              <p :class="`flex-1 p-2 text-linkem-${colourUpfront}`">
                {{ product.title }}
              </p>
              <template v-for="(price, index) in product.prices">
                <div :key="`price-${index}`" class="p-2 text-right">
                  <span
                    :key="`price-${index}`"
                    v-if="
                      offer_targets &&
                        offer_targets[0].demerged_VAT &&
                        price.VAT_demerged_amount
                    "
                  >
                    €
                    {{
                      price.VAT_demerged_amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span :key="`price-${index}`" v-else>
                    €
                    {{
                      price.amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <!-- <span class="text-xs">
                    {{
                      price.payment_type.code == "recurring" ? "/mese" : ""
                    }}
                  </span> -->
                  <span
                    class="text-xs"
                    :key="index"
                    v-if="
                      product.has_deferred_prices && price.total_payments > 1
                    "
                  >
                    {{
                      product.has_deferred_prices && price.total_payments > 1
                        ? "per " + price.total_payments + " mesi"
                        : ""
                    }}
                  </span>
                </div>
              </template>
            </li>
            <li
              v-for="(product, index) in visible_products_not_in_invoice"
              :key="index"
              class="flex flex-wrap px-2 leading-tight border-b"
            >
              <p :class="`flex-1 p-2 text-linkem-${colourUpfront}`">
                {{ product.title }}
              </p>
              <template v-for="(price, index) in product.prices">
                <div :key="`price-${index}`" class="p-2 text-right">
                  <span
                    :key="`price-${index}`"
                    v-if="
                      offer_targets &&
                        offer_targets[0].demerged_VAT &&
                        price.VAT_demerged_amount
                    "
                  >
                    €
                    {{
                      price.VAT_demerged_amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span :key="`price-${index}`" v-else>
                    €
                    {{
                      price.amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span class="text-xs" :key="index">
                    {{
                      price.payment_type.code == "recurring" &&
                      price.total_payments > 1
                        ? "/mese"
                        : ""
                    }} </span
                  ><span
                    class="text-xs"
                    v-if="
                      product.has_deferred_prices && price.total_payments > 1
                    "
                    :key="index"
                  >
                    {{
                      product.has_deferred_prices && price.total_payments > 1
                        ? "per " + price.total_payments + " mesi"
                        : ""
                    }}
                  </span>
                </div>
              </template>
            </li>
            <li v-if="cod_amount" class="flex flex-wrap p-2 border-b">
              <p class="flex-1">Pagamento contrassegno</p>
              <div :key="`price-${index}`" class="p-2 text-right">
                <span
                  :key="`price-${index}`"
                  v-if="
                    offer_targets &&
                      offer_targets[0].demerged_VAT &&
                      price.VAT_demerged_amount
                  "
                >
                  <!-- € 
                    {{ 
                      cod_amount 
                      | currency(' ', 2, { 
                        decimalSeparator: ',', 
                        spaceBetweenAmountAndSymbol: true 
                      }) 
                    }} -->
                  €
                  {{
                    price.VAT_demerged_amount
                      | currency(" ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}
                </span>
                <span :key="`price-${index}`" v-else>
                  €
                  {{
                    price.amount
                      | currency(" ", 2, {
                        decimalSeparator: ",",
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}
                </span>
              </div>
            </li>
            <li
              v-if="cart.offer_discount"
              class="flex flex-wrap p-2 leading-tight border-b"
            >
              <p class="flex-1 p-4 ">
                Codice sconto {{ cart.offer_discount.code }}
              </p>
              <template>
                <div :key="`price-${index}`" class="p-2 text-right">
                  <span :key="`price-${index}`">
                    {{
                      _.get(cart, "offer_discount.campaign.use_percentage")
                        ? "-" +
                          _.get(cart, "offer_discount.campaign.value") +
                          "%"
                        : ("-" + _.get(cart, "offer_discount.campaign.value"))
                          | currency("€ ", 2, {
                            decimalSeparator: ",",
                            spaceBetweenAmountAndSymbol: true
                          })
                    }}
                  </span>
                  <span
                    >{{
                      _.get(
                        cart,
                        "offer_discount.campaign.payment_type.code"
                      ) == "recurring"
                        ? "/mese"
                        : ""
                    }} </span
                  ><span
                    v-if="_.get(cart, 'offer_discount.campaign.total_payments')"
                  >
                    {{
                      _.get(cart, "offer_discount.campaign.total_payments") > 1
                        ? "per " +
                          _.get(
                            cart,
                            "offer_discount.campaign.total_payments"
                          ) +
                          " mesi"
                        : "per 1 mese"
                    }}
                  </span>
                </div>
              </template>
            </li>
            <li
              v-if="_.get(cart, 'offer.refund_method.description')"
              class="flex flex-wrap p-2 leading-tight border-b "
            >
              <p class="px-2 text-xs">
                {{ _.get(cart, "offer.refund_method.description") }}
              </p>
            </li>
            <!-- <li class="flex flex-wrap items-end p-2 border-b">
              <p
                class="flex-1 p-4 text-xs"
                v-if="offer_targets && offer_targets[0].demerged_VAT"
              >
                Prezzi IVA esclusa
              </p>
              <p v-else class="flex-1 p-4 text-xs">
                Tutti i costi indicati sono da intendersi IVA inclusa
              </p>
            </li>-->
          </ul>

          <div
            v-if="
              upfront_total + recurring_not_invoice_total > 0 ||
                hasZeroUpfrontFromCoupon
            "
            :class="
              `flex flex-col text-white rounded-b-lg px-4 py-2 bg-${colourUpfront}-primary`
            "
          >
            <ul>
              <li class="flex flex-wrap text-xl font-semibold leading-tight">
                <p class="flex-1">Totale costo una tantum</p>
                <div class="text-right">
                  <p
                    class="px-2"
                    v-if="
                      offer_targets &&
                        offer_targets[0].demerged_VAT &&
                        no_VAT_upfront_total
                    "
                  >
                    €
                    {{
                      (this.no_VAT_upfront_total +
                        this.cod_amount +
                        no_VAT_recurring_not_invoice_total)
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </p>
                  <p class="px-2" v-else>
                    €
                    {{
                      (this.upfront_total +
                        this.cod_amount +
                        recurring_not_invoice_total)
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </p>
                </div>
              </li>
              <li>
                <p class="text-xs">
                  {{
                    hasVat
                      ? "Prezzi IVA esclusa"
                      : "Tutti i costi indicati sono da intendersi IVA inclusa"
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 py-2" v-if="visible_products_deferred.length > 0">
      <div class="rounded-lg">
        <div class="overflow-hidden bg-white rounded-lg shadow-around">
          <ul>
            <li
              v-for="(product, index) in visible_products_deferred"
              :key="index"
              class="flex flex-wrap px-2 leading-tight border-b"
            >
              <p :class="`flex-1 p-2 text-linkem-${colourUpfront}`">
                {{ product.title }}
              </p>
              <template v-for="(price, index) in product.prices">
                <div :key="`price-${index}`" class="p-2 text-right">
                  <span
                    :key="`price-${index}`"
                    v-if="
                      offer_targets &&
                        offer_targets[0].demerged_VAT &&
                        price.VAT_demerged_amount
                    "
                  >
                    €
                    {{
                      price.VAT_demerged_amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span :key="`price-${index}`" v-else>
                    €
                    {{
                      price.amount
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </span>
                  <span class="text-xs" :key="index">
                    {{ "per " + price.total_payments + " mesi" }}
                  </span>
                </div>
              </template>
            </li>

            <li
              v-if="_.get(cart, 'offer.refund_method.description')"
              class="flex flex-wrap p-2 leading-tight border-b "
            >
              <p class="px-2 text-xs">
                {{ _.get(cart, "offer.refund_method.description") }}
              </p>
            </li>
            <!-- </li> -->
          </ul>
          <div
            v-if="deferred_total"
            :class="
              `flex flex-col text-white rounded-b-lg px-4 py-2 bg-${colourUpfront}-primary`
            "
          >
            <ul>
              <li class="flex flex-wrap text-xl font-semibold leading-tight">
                <p class="flex-1">Contributo di attivazione</p>
                <div class="text-right">
                  <p class="pb-0 pl-2 lg:pt-0">
                    <span
                      v-if="
                        offer_targets &&
                          offer_targets[0].demerged_VAT &&
                          no_VAT_deferred_total
                      "
                    >
                      €
                      {{
                        no_VAT_deferred_total
                          | currency(" ", 2, {
                            decimalSeparator: ",",
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </span>
                    <span v-else>
                      €
                      {{
                        deferred_total
                          | currency(" ", 2, {
                            decimalSeparator: ",",
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </span>
                    <!-- €
                    {{
                      (_.get(this.cart, "cart_totals.upfront") +
                        this.deferred_total)
                        | currency(" ", 2, {
                          decimalSeparator: ",",
                          spaceBetweenAmountAndSymbol: true
                        })
                    }} -->
                  </p>
                  <p class="text-xs bold">
                    {{ _.get(cart, "offer.activation_message") }}
                  </p>
                </div>
              </li>
              <li>
                <p class="text-xs">
                  {{
                    hasVat
                      ? "Prezzi IVA esclusa"
                      : "Tutti i costi indicati sono da intendersi IVA inclusa"
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import currency from "vue2-filters";
export default {
  props: ["api", "colour", "colourUpfront", "hasVat"],
  mounted() {
    this.getCart();
    this.$events.on("cart:upfront_payment_method", method => {
      if (method == "cod") {
        this.cod_amount = this.cart.offer.cachet_amount;
      } else {
        this.cod_amount = 0;
      }
    });
    this.$events.on("cart:cart_updated", () => {
      this.getCart();
    });
  },
  data() {
    return {
      cart: {},
      cod_amount: 0
    };
  },
  beforeDestroy() {
    this.$events.off("cart:upfront_payment_method");
    this.$events.off("cart:cart_updated");
  },
  computed: {
    _: function() {
      return _;
    },
    visible_products: function() {
      var prod =  _.filter(this.cart.products, function(item) {
        return (
          item.visible ||
          _.some(item.prices, function(price) {
            return price.amount > 0;
          })
        );
      });
      return _.sortBy(prod, function(item) {
        return item.pivot.order;
      });
    },
    // inInvoice: function() {
    //   return _.get(this.paymentType, 'code') == 'recurring' && this.total_payments == 0;
    // },
    visible_products_in_invoice: function() {
      return _.filter(this.visible_products, function(item) {
        return _.some(item.prices, function(price) {
          return (
            _.get(price.payment_type, "code") == "recurring" &&
            price.total_payments == 0
          );
        });
      });
    },
    visible_recurring_products_not_in_invoice: function() {
      var coll = _.filter(this.visible_products, function(item) {
        return _.every(item.prices, function(price) {
          return (
            _.get(price.payment_type, "code") == "recurring" &&
            price.total_payments > 0 &&
            !price.is_special_deferred
          );
        });
      });
      return _.sortBy(coll, [
        function(i) {
          return i.prices[0].total_payments;
        }
      ]);
    },
    visible_products_not_in_invoice: function() {
      return _.filter(this.visible_products, function(item) {
        return _.every(item.prices, function(price) {
          return (
            _.get(price.payment_type, "code") != "recurring" ||
            (price.total_payments > 0 && !price.is_special_deferred)
          );
        });
      });
    },
    visible_products_deferred: function() {
      return _.filter(this.visible_products, function(item) {
        return _.some(item.prices, function(price) {
          return (
            _.get(price.payment_type, "code") == "recurring" &&
            price.total_payments > 0 &&
            price.is_special_deferred
          );
        });
      });
    },
    recurring_total: function() {
      return _.get(this.cart, "cart_totals.recurring");
    },
    recurring_invoice_total: function() {
      return _.get(this.cart, "cart_totals.inInvoice");
    },
    recurring_not_invoice_total: function() {
      return _.get(this.cart, "cart_totals.notInInvoice");
    },
    upfront_total: function() {
      return _.get(this.cart, "cart_totals.upfront") + this.cod_amount;
    },
    no_VAT_upfront_total: function() {
      return _.get(this.cart, "cart_totals.noVATUpfront") + this.cod_amount;
    },
    no_VAT_deferred_total: function() {
      return _.get(this.cart, "cart_totals.noVATDeferredTotal");
    },
    deferred_total: function() {
      return _.get(this.cart, "cart_totals.deferredTotal");
    },
    hasZeroUpfrontFromCoupon: function() {
      return _.get(this.cart, "hasZeroUpfrontFromCoupon");
    },
    offer_targets: function() {
      return _.get(this.cart, "offer.targets");
    },
    no_VAT_recurring_not_invoice_total: function() {
      return _.get(this.cart, "cart_totals.noVATNotInInvoice");
    },
    no_VAT_recurring_invoice_total: function() {
      return _.get(this.cart, "cart_totals.noVATInInvoice");
    }
    // currency() {
    //   return _.split(
    //     _.get(this.cart, "cart_totals.upfront") + this.cod_amount,
    //     ","
    //   );
    // }
  },
  methods: {
    getCart: function() {
      this.$axios.get(this.apiUrl(this.api)).then(response => {
        this.cart = response.data;
      });
    },
    apiUrl: function(api) {
      return _.join([window.location.origin, "api", api], "/");
    }
  }
};
</script>
