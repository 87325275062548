<template>
  <transition-expand>
    <section v-if="covered && !checkAgain && !drawer" class="bg-verde">
      <div class="container mx-auto">
        <!-- VERIFICA COPERTURA -->
        <div class="flex flex-wrap justify-center p-4 -mx-4">
          <p v-html="coveredMessage" class="px-2 pr-2 text-center"></p>
          <p
            class="text-white cursor-pointer hover:underline"
            @click="checkAgain = true"
          >
            Verifica un altro indirizzo
          </p>
        </div>
      </div>
    </section>
    <section v-else :class="{ 'bg-black': !drawer }" class="rounded-lg">
      <div class="container mx-auto">
        <!-- VERIFICA COPERTURA -->
        <form
          @submit.prevent="
            (!mobile && !mappedAddress) || checkCoverage(mappedAddress)
          "
        >
          <div class="flex flex-wrap justify-center"
            :class="{ 'flex-col': sidebar}">
            <div
              class="flex-1 p-4 pb-0 sm:flex"
              :class="{ hidden: !drawer, 'w-full': sidebar }"
            >
              <component
                :is="google ? 'google-autocomplete' : 'egon-autocomplete'"
                nested-model
                :id="_.toString(_uid)"
                ref="map"
                :placeholder="searchBoxPlaceholder"
                @placechanged="onChange"
                @enable-autosubmit="enableAutoSubmit"
              />
            </div>
            <div class="flex-initial p-4 text-center">
              <button
                ref="button"
                type="submit"
                class="max-w-full px-4 py-2 overflow-hidden text-center text-white uppercase rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed bg-linkem-celeste"
                :class="{ 'whitespace-no-wrap': !sidebar}"
              >
                {{ verifyButtonLabel }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <external-form
        ref="external"
        :external-url="externalUrl"
        :value="mappedAddress"
        :offer-code="offerCode"
        :target="target"
      ></external-form>
    </section>
  </transition-expand>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  data: () => ({
    checkAgain: Boolean(),
    autoSubmit: Boolean(),
    mappedAddress: undefined
  }),
  props: {
    google: Boolean,
    searchBoxPlaceholder: {
      type: String,
      default: "Comincia a digitare il tuo indirizzo"
    },
    verifyButtonLabel: { type: String, default: "Verifica copertura" },
    responseTitle: { type: String, default: "Lascia i tuoi recapiti" },
    externalUrl: String,
    coveredMessage: String,
    covered: Boolean,
    oid: String,
    verificaCoperturaApi: { type: String, required: true },
    streetNumberMessage: String,
    offerCode: { type: String, default: null },
    target: String,
    sidebar: Boolean,
    requiredStreetNumber: Boolean
  },
  computed: {
    drawer() {
      return this.$parent.$options.name === "drawer";
    },
    mobile() {
      return this.$breakpoint.xsOnly;
    }
  },
  methods: {
    enableAutoSubmit() {
      this.autoSubmit = true;
    },
    onChange(addressData) {
      this.mappedAddress = addressData;
      this.$refs.button.focus();
      if (this.autoSubmit && addressData) {
        this.checkCoverage(addressData);
      }
    },
    async checkCoverage(address) {
      if (this.mobile && !this.drawer) {
        this.$events.emit("toggleDrawer", [
          "Verifica copertura",
          "verifica-copertura-button",
          this.$props
        ]);
      } else if (this.requiredStreetNumber && address && !address.address.street_number) {
        this.$root.$toast.open("Attenzione", this.streetNumberMessage, {
            type: "danger"
          });
      } else if (address) {
        const coverage = await this.checkCopertura(address);

        if (coverage.code === 0 && !_.isEmpty(this.externalUrl)) {
          this.$refs.external.submit();
        } else if (coverage.response.status == "tiscali-covered") {
          this.openTiscaliContacts(coverage.response);
        } else if (!_.isEmpty(this.externalUrl)) {
          this.openContacts(coverage.response.coverage);
        } else {
          this.$events.emit("toggleDrawer", [
            coverage.code !== 0 ? this.responseTitle : "Verifica copertura",
            "verifica-copertura",
            coverage
          ]);
        }
      }
      this.autoSubmit = false;
    },
    async checkCopertura(address) {
      try {
        window.vm.$wait.start("checkCoverage");
        address.oid = this.oid;
        const coverage = await this.$axios.post(
          this.verificaCoperturaApi,
          _.mapKeys(address, (v, k) => _.capitalize(k))
        );

        return {
          response: coverage.data,
          message: coverage.data.message,
          offer: {
            coverage: coverage.data.coverage,
            oid: coverage.data.offer_id
          },
          code: coverage.data.coverage.answer.code
        };
      } finally {
        window.vm.$wait.end("checkCoverage");
      }
    }
  }
};
</script>
