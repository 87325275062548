<template>
  <li v-if="button" class="block px-4 cursor-pointer">
    <a
      class="px-4 py-1 text-sm uppercase whitespace-no-wrap bg-white rounded-full"
      :class="`text-${color}-primary`"
      @click="$events.emit(...event)"
    >
      <slot />
    </a>
  </li>
  <li
    v-else
    class="block px-4 cursor-pointer sm:border-r last:border-r-0 border-text"
  >
    <a
      :href="link"
      class="whitespace-no-wrap cursor-pointer hover:underline"
      @click="$events.emit(...event)"
    >
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  data: () => ({
    expand: true
  }),
  props: {
    event: [String, Array],
    button: Boolean,
    link: String
  }
};
</script>
