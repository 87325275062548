<template>
  <!-- <div class="FormDate" @click="focusInput()"> -->
  <div class="FormDate">
    <span class="title_span">{{ title }}</span>
    <!-- <input
      :id="'day_input_'+idName"
      v-if="showDay"
      ref="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="gg"
      :maxlength="2"
      :max="31"
      :min="1"
      @input="updateDay"
      @blur="day = _.max([_.min([_.toNumber(day), 31]),1]); updateValue()"
    >-->
    <input
      :id="'day_input_' + idName"
      v-if="showDay"
      ref="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="gg"
      :maxlength="2"
      :max="31"
      :min="1"
      @input="updateDay"
      @blur="
        day = day;
        updateValue();
      "
    />
    <span v-if="showDay && showMonth" class="FormDate__divider">/</span>
    <!-- <input
      :id="'month_input_'+idName"
      v-if="showMonth"
      :maxlength="2"
      ref="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      :max="12"
      :min="1"
      @input="updateMonth"
      @blur="month =  _.max([_.min([_.toNumber(month), 12]),1]); updateValue()"
    >-->
    <input
      :id="'month_input_' + idName"
      v-if="showMonth"
      :maxlength="2"
      ref="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      :max="12"
      :min="1"
      @input="updateMonth"
      @blur="
        month = month;
        updateValue();
      "
    />
    <span v-if="showYear && (showDay || showMonth)" class="FormDate__divider"
      >/</span
    >
    <!-- <input
      :id="'year_input_'+idName"
      v-if="showYear"
      :maxlength="4"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      placeholder="aaaa"
      :max="2999"
      :min="1900"
      @input="updateYear"
      @blur="year = _.max([_.min([_.toNumber(year), 2999]),1900]); updateValue()"
    >-->
    <input
      :id="'year_input_' + idName"
      v-if="showYear"
      :maxlength="4"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      placeholder="aaaa"
      :max="2999"
      :min="1900"
      @input="updateYear"
      @blur="
        year = year;
        updateValue();
      "
    />
  </div>
</template>

<script>
var moment = require("moment");
export default {
  name: `date-field`,
  props: {
    value: {
      default: undefined,
      //type: [Number, String],
      required: true
    },
    title: {},
    idName: {},
    showDay: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: true
    },
    showYear: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      day: this.value ? moment(this.value, "DD/MM/YYYY").date() : null,
      month: this.value ? moment(this.value, "DD/MM/YYYY").month() + 1 : null,
      year: this.value ? moment(this.value, "DD/MM/YYYY").year() : null
    };
  },
  computed: {
    _() {
      return _;
    }
  },
  watch: {},
  methods: {
    // focusInput() {
    //   document.getElementById('day_input_'+this.idName).focus();
    // },
    updateDay() {
      if (this.day.toString().length < 2) return;
      if (this.showMonth) this.$refs.month.select();
      else if (this.showYear) this.$refs.year.select();
    },
    updateMonth() {
      if (this.month.toString().length < 2) return;
      if (this.showYear) this.$refs.year.select();
    },
    updateYear() {
      if (this.year.toString().length < 4) return;
      this.$refs.year.blur();
    },
    updateValue() {
      const theDate = moment(
        `${this.day}/${this.month}/${this.year}`,
        "DD/MM/YYYY"
      );
      if (!moment(theDate, "DD/MM/YYYY").isValid()) {
        this.$emit(`input`, null);
        this.$emit("error", [`${this.title} non è valida`]);
        return;
      }
      if (!!this.day && !!this.month && !!this.year) {
        this.$emit(
          `input`,
          `${_.padStart(this.day, 2, "0")}/${_.padStart(
            this.month,
            2,
            "0"
          )}/${_.padStart(this.year, 4, "0")}`
        );
        this.$emit(`focus`, "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.FormDate {
  $spacing: 6px;

  display: block;
  // position: relative;
  overflow: hidden;

  -webkit-box-shadow: none;

  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    background-color: transparent;
    padding: $spacing;
    padding-right: $spacing;
    padding-left: $spacing;
    border: none;
    text-align: center;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #999999;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999999;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #999999;
    }

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing * 2;
    }

    &:last-child {
      padding-right: $spacing * 2;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 3em;
    }

    &--year {
      width: 4em;
    }
  }

  &__divider {
    padding-top: $spacing;
    padding-bottom: $spacing;
    pointer-events: none;
    color: #999;
  }
}
.title_span {
  color: #999;
}

@media screen and (max-width: 394px) {
  .title_span {
    display: block;
  }
}
</style>
