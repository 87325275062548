<template>
  <div id="bottom-banner" style="display: block;" v-if="isOpen">
    <div class="container mx-auto mx-4">
      <div class="flex flex-wrap justify-around">
        <div class="flex flex-none w-full md:w-11/12 justify-center">
          <div class="text-center p-4 my-0 bottom-Banner-Interno">
            Questo sito utilizza cookie che rientrano nella responsabilità di
            terze parti. Se vuoi saperne di più o negare il consenso a tutti o
            ad alcuni cookie
            <a
              href="http://web.linkem.com/-/media/linkem/files/informativa-privacy-e-cookie-policy_20180524.pdf?la=it-it&amp;hash=C12CF46765F3E0D1045304BA5B36DFFF932868BClevel=privati"
              >clicca qui</a
            >. <br />Cliccando qualunque elemento di questa pagina o proseguendo
            nella navigazione acconsenti all’uso dei cookie.
          </div>
        </div>
        <div class="flex flex-none w-full md:w-1/12 justify-center">
          <div id="closeBanner">
            <a href="#" @click="accept">
              <font-awesome-icon icon="times-circle"></font-awesome-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Cookie from "tiny-cookie";
export default {
  props: {
    storageName: {
      type: String,
      default: "Cookie__EU_law"
    },
    domain: {
      type: String,
      default: "localhost"
    }
  },
  created() {
    if (this.getVisited() !== "Yes") {
      this.isOpen = true;
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    let aTag = document.getElementsByTagName("a");
    for (var i = 0; i < aTag.length; i++) {
      aTag[i].addEventListener(
        "click",
        () => {
          this.accept();
        },
        false
      );
    }

    let buttonTag = document.getElementsByTagName("button");
    for (var ii = 0; ii < buttonTag.length; ii++) {
      buttonTag[ii].addEventListener(
        "click",
        () => {
          this.accept();
        },
        false
      );
    }
  },
  methods: {
    setVisited() {
      Cookie.set(this.storageName, "Yes", { domain: this.domain });
    },
    getVisited() {
      return Cookie.get(this.storageName);
    },
    accept() {
      this.setVisited();
      this.isOpen = false;
      this.$events.emit("accept");
    },
    close() {
      this.isOpen = false;
    }
  }
};
</script>
<style>
#bottom-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.9);
  z-index: 1000000000;
  color: #fff;
  font-size: 16px;
  display: none;
  width: 100%;
  text-align: center;
}
#bottom-banner #closeBanner {
  margin: 36px 0;
  font-size: 27px;
}
#bottom-banner i {
  color: #fff;
}

#bottom-banner .bottom-Banner-Interno a {
  color: #f5ac6f;
  text-decoration: none;
  background-color: transparent;
}
#bottom-banner .bottom-Banner-Interno a:hover {
  text-decoration: underline;
}
</style>
