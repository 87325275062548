<template>
  <transition
    name="transition-expand"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:after-enter="afterEnter"
    v-on:before-leave="beforeLeave"
    v-on:leave="leave"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  methods: {
    beforeEnter: el => {
      el.style.height = `0px`;
    },
    enter: el => {
      el.style.height = `0px`;
      el.style.height = `${el.scrollHeight}px`;
    },
    afterEnter: el => {
      el.style = null;
    },
    beforeLeave: el => {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave: el => {
      el.style.height = `${el.scrollHeight}px`;
      el.style.height = `0px`;
    }
  }
};
</script>

<style>
.transition-expand-enter-active,
.transition-expand-leave-active {
  transition: height ease 1s;
  @apply overflow-y-hidden;
}
</style>
