<template>
  <label class="control-label">
    <input
      :id="`${modelName.replace(/\./g, '_')}_${value}`"
      :name="`${modelName}_${value}`"
      v-model="data_value"
      type="radio"
       @change="(callMethod && broadcastUpfrontPaymentMethod($event)) || (contractMethod && mustDownloadContract($event)) "     
      :disabled="disabled"
      :value="radioValue"
      @click="$emit('input', $event.target.value)"
    />
    {{ message }}
  </label>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    callMethod: {
      type: Boolean,
      default: false
    },
    message: {
      required: true,
      type: String
    },
    radioValue: {
      required: true
    },
    modelName: {
      required: true,
      type: String
    },
    value: {
      required: true
    },
    contractMethod: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      error_model: this.errorModel,
      data_value: this.value
    };
  },
  methods: {
    broadcastUpfrontPaymentMethod($event) {
      this.$events.emit("cart:upfront_payment_method", $event.target.value);
    },
    mustDownloadContract($event){
    this.$events.emit("contract:reset_contract", $event.target.value);
    }
  }
};
</script>
