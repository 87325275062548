var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-linkem-grigio"},[_c('toast',{ref:"toast"}),_c('drawer',{attrs:{"go-on-api":"","contacts-api":""}},[_vm._t("drawer")],2),_c('div',{staticClass:"antialiased max-w-extreme mx-auto"},[_c('header-nav',{attrs:{"area-clienti-api":{
        login: 'login',
        logout: 'logout',
        recoverPwd: 'recoverPwd',
        recoverUsr: 'recoverUsr'
      },"logo":{
        alt: 'Logo Linkem',
        href: '/',
        src: 'logo-linkem-new.png'
      }},scopedSlots:_vm._u([{key:"left-header-top",fn:function(){return [_vm._t("left-header-top")]},proxy:true},{key:"right-header-top",fn:function(){return [_vm._t("right-header-top")]},proxy:true},{key:"navbar",fn:function(){return [_vm._t("navbar")]},proxy:true}],null,true)}),_c('main-loader'),_c('div',{staticClass:"pt-24"},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }