<template>
  <ValidationProvider :rules="validation" v-slot="{ errors }" :name="label">
    <slot :errors="!_.isEmpty(errors)"></slot>
    <p
      class="h-4 text-xs italic mb-2"
      :class="_.isEmpty(errors) || `text-error`"
    >
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
export default {
  props: ["label", "validation", "type"]
};
</script>
