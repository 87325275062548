<template>
  <section class="bg-gray-200 py-10">
    <div class="container mx-auto p-4">
      <div class="flex flex-wrap justify-around -mx-4">
        <support-field
          v-for="field in supportFields"
          :key="field.title"
          v-bind="field"
        ></support-field>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    supportFields: Array
  }
};
</script>
