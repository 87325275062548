import Vue from "vue";

import _ from "lodash";
import "./assets/tailwind.css";
import "aos/dist/aos.css";

import Breakpoint from "./plugins/breakpoint";

require("./register-plugins");
require("./register-components");

Vue.prototype.$breakpoint = Vue.observable(new Breakpoint());

Vue.prototype.$window = window;

Vue.mixin({
  props: {
    sectionColor: String
  },
  provide() {
    if (_.get(this, "$options.propsData.sectionColor")) {
      const self = this;
      return {
        $$section: {
          get color() {
            return self.sectionColor;
          }
        }
      };
    }
  },
  computed: {
    _() {
      return _;
    },
    color() {
      return this.sectionColor || this.$$section.color;
    }
  },
  inject: {
    $$section: {
      from: "$$section",
      default: { color: "arancio" }
    }
  },
  methods: {
    recaptcha(action) {
      this.$recaptcha("login").then(() => {
        action();
      });
    },
    openContacts(coverage, origin = "LasciaContatti") {
      this.$events.emit("toggleDrawer", [
        "Lascia i tuoi dati, sarai ricontattato",
        "contact-me",
        { coverage: coverage, sectionColor: this.color, origin: origin }
      ]);
    },
    openTiscaliContacts(coverage, origin = "LasciaContatti") {
      this.$events.emit("toggleDrawer", [
        // "Lascia i tuoi dati, sarai ricontattato da un operatore Tiscali",
        coverage.title,
        "tiscali-contact-me",
        { coverage: coverage, sectionColor: this.color, origin: origin }
      ]);
    }
  }
});

export default {};
