<template>
  <div id="checkout_contract_form" v-if="step == step_name">
    <step-header
      title="Contratto"
      icon="credit-card"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-20 mx-auto">
          <div class="flex flex-wrap justify-around">
            <div class="justify-center p-4">
              <a
                id="DownloadContract"
                target="_blank"
                :href="infoRoute"
                class="text-grey my-1 font-bold uppercase"
              >
                <font-awesome-icon
                  icon="file-signature"
                  :class="`text-${colour}-primary`"
                />Scarica info precontrattuali
              </a>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="checkbox justify-center w-full p-4">
              <linkem-checkbox
                :disabled="!canAccept"
                :errorModel="checkout_errors['flag_privacy']"
                :value="contract.flag_privacy"
                @input="
                  $emit('update:contract', {
                    ...contract,
                    flag_privacy: $event
                  })
                "
                modelName="checkout.contract.flag_privacy"
                :message="privacyMessage"
              />
              <span
                class="text-error"
                v-for="(item, index) in checkout_errors.flag_privacy_contract"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="checkbox justify-center w-full p-4">
              <linkem-checkbox
                :errorModel="checkout_errors['flag_marketing']"
                :value="contract.flag_marketing"
                @input="
                  $emit('update:contract', {
                    ...contract,
                    flag_marketing: $event
                  })
                "
                modelName="checkout.contract.flag_marketing"
                :message="marketingMessage"
              />
              <span
                class="text-error"
                v-for="(item, index) in checkout_errors.flag_marketing_contract"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="p-4 w-full" v-if="target == 'privati' && activationEnabled">
            <h4 class="m-1">
              <span class="font-bold"> {{ activationMessages[0] }}</span>
              <span class="font-bold inline-block"> {{ activationMessages[1] }}<tooltip :message="tooltipMessage"></tooltip></span>
            </h4>
            <div class="form-group m-1">
              <div class="form-group">
                <div class="flex flex-wrap justify-around lg:w-1/2">
                  <div
                    class="py-2 w-full justify-center"
                  >
                    <linkem-radio
                      :value="contract.is_activation_accepted"
                      @input="
                        $emit('update:contract', {
                          ...contract,
                          is_activation_accepted: $event
                        })
                      "
                      modelName="checkout.contract.is_activation_accepted"
                      :radioValue="true"
                      message="Si - L'attivazione sarà più veloce"
                      :contractMethod="true"
                    ></linkem-radio>
                  </div>
                  <div
                    class="py-2 w-full justify-center"
                  >
                    <linkem-radio
                    :value="contract.is_activation_accepted"
                      @input="
                        $emit('update:contract', {
                          ...contract,
                          is_activation_accepted: $event
                        })
                      "
                      modelName="checkout.contract.is_activation_accepted"
                      :radioValue="false"
                      message="No - L'attivazione sarà più lenta"
                      :contractMethod="true"
                    ></linkem-radio>
                  </div>
                </div>
                <span
                  class="text-error"
                  v-for="(item,
                  index) in checkout_errors.is_activation_accepted"
                  :key="index"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="justify-center p-4">
              <div :class="{ 'cursor-not-allowed': !canDownload }">
                <a
                  id="DownloadContract"
                  target="_blank"
                  :href="pdfRoute + (contract.flag_marketing || contract.is_activation_accepted ? '?marketing=' + (contract.flag_marketing || 'false') + '&acceptance=' + (contract.is_activation_accepted || 'false') : '')"
                  class="text-grey my-1 font-bold uppercase"
                  :class="{
                    ' pointer-events-none text-gray-500': !canDownload
                  }"
                  @click="downloaded"
                >
                  <font-awesome-icon
                    icon="file-signature"
                    :class="[
                      canDownload ? `text-${colour}-primary` : 'text-gray-500'
                    ]"
                  />Scarica contratto
                </a>
              </div>
            </div>
          </div>
          <ValidationProvider
            immediate
            name="downloadedContract"
            :rules="{ required: { allowFalse: false } }"
          >
            <input :value="downloadedContract" v-show="false" />
          </ValidationProvider>

          <!-- <div class="flex flex-wrap justify-around">
            <div class="checkbox justify-center w-full p-4 font-bold">
              {{ emailMessage }}
            </div>
          </div> -->
          <div class="flex flex-wrap justify-around">
            <div class="checkbox justify-center w-full p-4">
              <p>
                <span v-html="resetMessages[0]" />
                <span class="cursor-pointer underline" v-text="' qui'" @click="reset" />
                <span v-html="resetMessages[1]" />
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="checkbox justify-center w-full p-4">
              <ValidationProvider
                immediate
                name="terms"
                :rules="{ required: { allowFalse: false } }"
              >
                <linkem-checkbox
                  :errorModel="checkout_errors['flag_terms']"
                  :value="contract.flag_terms"
                  @input="
                    $emit('update:contract', {
                      ...contract,
                      flag_terms: $event
                    })
                  "
                  modelName="checkout.contract.flag_terms"
                  :message="termsMessage"
                />
                <span
                  class="text-error"
                  v-for="(item, index) in checkout_errors.flag_terms"
                  :key="index"
                  >{{ item }}</span
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="flex flex-wrap p-4 pt-0">
            <span> * campo obbligatorio </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    contract: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    emailMessage: {
      type: String,
      required: true
    },
    termsMessage: {
      type: String,
      required: true
    },
    privacyMessage: {
      type: String,
      required: true
    },
    marketingMessage: {
      type: String,
      required: true
    },
    resetMessage: {
      type: String,
      required: true
    },
    pdfRoute: {
      type: String,
      required: true
    },
    infoRoute: {
      type: String,
      required: true
    },
    colour: {
      default: "arancio"
    },
    target: {
      type: String,
      default: "privati"
    },
    activationEnabled: {
      type: Boolean,
      default: false
    },
    activationMessage: {
      type: String
    },
    tooltipMessage: {
      type: String
    }
  },
  data: function() {
    return {
      downloadedContract: false,
      step_name: "contract"
    };
  },
  mounted() {
    this.$emit("update:contract", {});
    this.$events.on("contract:reset_contract", method => {
      this.reset();
    });
  },
  beforeDestroy() {
    this.$events.off("contract:reset_contract");
  },
  computed: {
    resetMessages() {
      this.resetMessage = _.replace(this.resetMessage, "<p>", "");
      this.resetMessage = _.replace(this.resetMessage, "</p>", "");
      return _.split(this.resetMessage, "$$", 2);
    },
    canDownload() {
      return this.contract.flag_privacy;
    },
    canAccept() {
      return !this.downloadedContract;
    },
    activationMessages() {
      var splitted = _.split(this.activationMessage, " ");
      return [_.initial(splitted).join(" ") + " ", _.last(splitted)];
    }
  },
  methods: {
    reset() {
      this.downloadedContract = false;
      this.$events.emit('checkout:resettedContract');
    },
    downloaded() {
      this.downloadedContract = true;
      this.$events.emit('checkout:downloadedContract');
    }
  }
};
</script>
