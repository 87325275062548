var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container p-4 mx-auto"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"md:w-3/4 justify-center w-full p-4"},[_c('div',{staticClass:"flex"},_vm._l((_vm.top_steps),function(step,index){return _c('div',{key:index,staticClass:"relative flex-col flex-grow-0 w-1/4 py-4 text-center"},[_c('div',[_c('span',{staticClass:"outlined md:h-12 md:w-12 flex items-center justify-center w-10 h-10 mx-auto rounded-full",class:("bg-" + (step.active
                    ? _vm.colour(step)
                    : step.completed
                    ? 'gray-400'
                    : 'white') + " " + (step.active ? '' : 'border-2 border-gray-400'))},[(step.customIcon)?_c('icon-comp',{staticClass:"text-lg",class:("text-" + (step.completed ? 'white' : 'gray-400')),attrs:{"icon-name":step.icon}}):_c('font-awesome-icon',{staticClass:"text-xl",class:("text-" + (step.completed ? 'white' : 'gray-400')),attrs:{"icon":step.icon}})],1),_c('p',{staticClass:"uppercase",class:[
                step.active
                  ? ("text-linkem-" + (_vm.colour(step)))
                  : 'text-gray-400 w-0 sm:w-full hidden md:block'
              ]},[_vm._v("\n              "+_vm._s(step.label)+"\n            ")])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }