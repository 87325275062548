<template>
  <div class="fixed z-40">
    <div
      class="inset-0 bg-overlay fixed"
      :class="{ hidden: !show }"
      @click="close"
    ></div>
    <div
      class="inset-y-0 right-0 bg-white w-0 h-full fixed overflow-x-hidden"
      :class="{ 'w-full md:w-2/3': show }"
    >
      <div class="flex flex-col inset-y-0 h-full">
        <div
          class="flex flex-initial justify-between items-baseline shadow py-3 px-4 h-12"
        >
          <h4 class="font-bold uppercase" :class="`text-${color}-primary`">
            {{ title }}
          </h4>
          <button class="px-2" @click="close">
            <font-awesome-icon icon="times"></font-awesome-icon>
          </button>
        </div>
        <component
          :is="_.kebabCase(status.component)"
          v-bind.sync="allProps"
          :component.sync="status.component"
          @close="close"
        ></component>
        <!-- <verifica-copertura v-if="!_.isEmpty(address)" :address="address" :go-on-api="goOnApi"></verifica-copertura> -->
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    show: false,
    title: String(),
    status: Object()
  }),
  props: {
    goOnApi: {
      required: true,
      type: String
    },
    contactsApi: {
      required: true,
      type: String
    },
    privacyMsg: String,
    coverage: String,
    target: String,
    leaveContactMessage: { type: String },
    leavecontactNameLabel: { type: String },
    leavecontactLastnameLabel: { type: String },
    leavecontactEmailLabel: { type: String },
    leavecontactPhoneLabel: { type: String },
    submitButtonLabel: { type: String },
    leadSuccessMessage: {type: String }
  },
  events: {
    toggleDrawer([title, component, props]) {
      this.show = true;
      this.title = title;
      this.status = { component: component, props: props };
    }
  },
  computed: {
    portal() {
      return _.kebabCase(this.title);
    },
    allProps() {
      return _.merge(this.$props, this.status.props);
    }
  },
  methods: {
    close() {
      Object.assign(this.$data, this.$options.data());
    }
  }
};
</script>

<style scoped>
* {
  transition: width ease 1s;
}
</style>
