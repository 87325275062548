import Vue from "vue";
import axios from "axios";
import _ from "lodash";

Vue.prototype.$axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use(function(config) {
  // window.vm.$events.emit("loading", true);
  return config;
});

axios.interceptors.response.use(
  function(response) {
    // window.vm.$events.emit("loading", false);
    // Do something with response data
    return response;
  },
  function(error) {
    // window.vm.$events.emit("loading", false);
    // Do something with response error
    const code = parseInt(error.response && error.response.status);

    if (_.get(error, "response.data.message")) {
      // eslint-disable-next-line no-undef
      const message =
        code === 422
          ? "I dati inseriti non sono validi, si prega di riprovare"
          : _.get(error, "response.data.message");

      window.vm.$root.$toast.open("Errore", message, {
        type: "error"
      });
    }
    return Promise.reject(error);
  }
);
