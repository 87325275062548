<template>
  <section id="coupon-view" class="bg-gray-200">
    <form id="formCoupon" method="POST" :action="submitUrl">
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap justify-center -mx-4">
          <div class="flex-auto p-4">
            <input type="hidden" name="_token" v-bind:value="csrf" />
            <!-- <span class="fa fa-ticket"></span> -->
            <input
              id="coupon"
              :maxlength="unlockLength"
              name="coupon"
              placeholder="Inserisci coupon"
              class="w-full py-2 px-4"
              type="text"
              v-model="input"
              autocomplete="off"
            />
          </div>
          <div class="flex-initial p-4">
            <button
              type="submit"
              id="submit-call"
              class="shadow-button w-full bg-linkem-celeste text-white py-2 px-4 rounded-full uppercase"
            >
              <span>Sblocca offerte</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
export default {
  props: ["submitUrl", "unlockLength", "csrf"],
  data: function() {
    return {
      input: ""
    };
  },
  mounted: function() {
    // var _comp = this;
    // bus.$on('coupon:toggle_panel', clickCount => {
    //     _comp.isOpen = !_comp.isOpen;
    // });
  }
};
</script>
<style lang="scss" scoped>
#coupon-view {
  overflow: hidden;
  input {
    border-width: 0;
    height: 38px;
  }
  &.open {
    height: 150px;
  }
}
</style>
