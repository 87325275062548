<template>
  <div class="form-group">
    <component
      :is="google ? 'google-autocomplete' : 'egon-autocomplete'"
      :id="modelName.replace(/\./g, '_')"
      :name="modelName"
      :placeholder="label"
      :classname="
        `form-control w-full bg-gray-200 mx-auto ${
          errorModel ? 'border-error' : ''
        }`
      "
      @placechanged="$emit('google-input', $event)"
      variant="egonCheckout"
    />

    <font-awesome-icon
      icon="exclamation-triangle"
      v-if="error_model"
      class="text-error"
    ></font-awesome-icon>
    <span
      class="pl-2 text-error"
      v-for="(item, index) in error_model"
      :key="index"
      >{{ item }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    errorModel: {
      required: true
    },
    modelName: {
      required: true
    },
    google: Boolean
  },
  data: function() {
    return {
      error_model: this.errorModel
    };
  },
  watch: {
    errorModel: function() {
      this.error_model = this.errorModel;
    }
  }
};
</script>
