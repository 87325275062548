var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"rounded-lg shadow-around"},[_c('div',{staticClass:"flex flex-col p-4 bg-white rounded-lg"},[_c('div',[_c('p',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.hasValidationErrors),expression:"this.hasValidationErrors"}],staticClass:"p-1 text-sm cursor-pointer text-error"},[_vm._v("Formato coupon non valido, riprovare")])]),(_vm.discount)?_c('p',[_vm._v("\n          "+_vm._s(_vm._.get(_vm.discount, "code"))+":\n          "+_vm._s(_vm._f("currency")(_vm._.get(_vm.discount, "campaign.use_percentage")
              ? "-" + _vm._.get(_vm.discount, "campaign.value") + "%"
              : ("-" + _vm._.get(_vm.discount, "campaign.value")),"€ ", 2, {
                  decimalSeparator: ",",
                  symbolOnLeft: false,
                  spaceBetweenAmountAndSymbol: true
                }))+"\n          "+_vm._s(_vm._.get(_vm.discount, "campaign.payment_type.code") == "recurring"
              ? "/ mese"
              : "")+"\n        ")]):_vm._e(),(this.failedCoupon && _vm.discount === null && this.isDiscount)?_c('p',[_c('span',{staticClass:"text-sm"},[_vm._v("Spiacenti, non è stato trovato nessun sconto")])]):_vm._e(),(this.failedCoupon && !this.isDiscount)?_c('p',[_c('span',{staticClass:"text-sm"},[_vm._v("Spiacenti, coupon non valido")])]):_vm._e()]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full lg:w-2/3 md:w-1/2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couponCode),expression:"couponCode"}],staticClass:"w-full px-4 py-2 bg-gray-200",attrs:{"id":this.couponType + '-code',"name":this.couponType + '-code',"placeholder":"Codice promozione","type":"text","maxlength":this.couponLength},domProps:{"value":(_vm.couponCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.couponCode=$event.target.value}}})]),_c('div',{staticClass:"justify-center w-full pt-4 lg:w-1/3 md:w-1/2 md:pl-4 md:pt-0"},[_c('button',{class:'w-full bg-azzurro text-white p-2 rounded-full hover:bg-azzurro-lighter ' +
                (this.submitDisabled ? 'disabled' : ''),attrs:{"id":"submitButton","type":"submit"},on:{"click":function($event){return _vm.submitAndDisable()}}},[_vm._v("\n            Applica\n          ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }