<template>
  <a @click="eventClick()">
    <i :class="iconClasses"></i>
    <span>{{ title }}</span>
  </a>
</template>
<script>
export default {
  props: ["iconClasses", "title", "event-name", "event-option"],
  methods: {
    eventClick: function() {
      this.$events.emit(this.eventName, this.eventOption);
    }
  }
};
</script>
