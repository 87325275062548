<template></template>
<script>
export default {
  props: {
    coverage: Object
  },
  mounted() {
    if (this.coverage) {
      this.openTiscaliContacts(this.coverage);
    }
  }
};
</script>