<template>
  <div id="checkout_personal_data_form" v-if="step == step_name">
    <step-header
      title="Dati personali"
      icon="user"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto mx-20">
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-datetime
                :errorModel="checkout_errors.birth_date"
                :value="personal_data.birth_date"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_date: $event
                  })
                "
                modelName="checkout.personal_data.birth_date"
                label="Data Nascita"
                idName="birth_date"
              ></linkem-datetime>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.sex"
                :value="personal_data.sex"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    sex: $event
                  })
                "
                modelName="checkout.personal_data.sex"
                label="Sesso"
                :options="sex"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.birth_country"
                :value="personal_data.birth_country"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_country: $event,
                    birth_city: null
                  });
                  checkEnable($event);
                "
                modelName="checkout.personal_data.birth_country"
                label="Paese di Nascita"
                enabled
                api="getnations"
              ></input-autocomplete>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                v-if="enabled"
                :errorModel="checkout_errors.birth_city"
                :value="personal_data.birth_city"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    birth_city: $event
                  })
                "
                modelName="checkout.personal_data.birth_city"
                label="Comune di Nascita"
                :enabled="enabled"
                api="getlocations"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full justify-center">
              <linkem-input
                :errorModel="checkout_errors.fiscal_code"
                :value="personal_data.fiscal_code"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    fiscal_code: $event
                  })
                "
                modelName="checkout.personal_data.fiscal_code"
                label="Codice fiscale"
                :maxLength="inputLength.taxCodeLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.document_number"
                :value="personal_data.document_number"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    document_number: $event
                  })
                "
                modelName="checkout.personal_data.document_number"
                label="Numero documento"
                :maxLength="inputLength.docNumberLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <input-autocomplete
                :errorModel="checkout_errors.document_type"
                :value="personal_data.document_type"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    document_type: $event
                  })
                "
                modelName="checkout.personal_data.document_type"
                label="Selezionare il tipo documento"
                :options="document_types"
              ></input-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-datetime
                :errorModel="checkout_errors.released_at"
                :value="personal_data.released_at"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    released_at: $event
                  })
                "
                modelName="checkout.personal_data.released_at"
                label="Data Rilascio"
                idName="released_at"
              ></linkem-datetime>
            </div>
            <div class="p-4 w-full lg:w-1/2 justify-center">
              <linkem-input
                :errorModel="checkout_errors.released_by"
                :value="personal_data.released_by"
                @input="
                  $emit('update:personal_data', {
                    ...personal_data,
                    released_by: $event
                  })
                "
                modelName="checkout.personal_data.released_by"
                label="Rilasciato da"
                :maxLength="inputLength.docReleasedFromLength"
                autocompleteField="on"
              ></linkem-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    personal_data: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    inputLength: {
      required: true
    },
    colour: {
      default: "arancio"
    }
  },
  data: function() {
    return {
      step_name: "personal_data",
      sex: [
        { code: "F", label: "Femminile" },
        { code: "M", label: "Maschile" }
      ],
      document_types: [
        { code: "CartaDiIdentita", label: "Carta d'identità" },
        { code: "Passaporto", label: "Passaporto" },
        { code: "PatenteDiGuida", label: "Patente di guida" }
      ],
      enabled: false
    };
  },
  methods: {
    checkEnable(birth_country) {
      this.enabled = birth_country.code == "ITALIA;Z000;IT";
      // this.$emit('update:personal_data', {
      //   ...this.personal_data,
      //   birth_city: null
      // })
      this.checkout_errors.birth_city = null;
    }
  }
};
</script>
