<template>
  <ul class="banner-list">
    <li
      class="banner-list__item"
      v-for="item in linkList"
      :key="item.description"
    >
      <i class="icon text-5xl" :class="[`icon-${item.icon}`]"></i>
      <h2 class="font-light leading-tight">{{ item.description }}</h2>
      <a href="tel:@tel">
        <h1 class="font-black text-2xl">{{ item.value }}</h1>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    linkList: Array
  }
};
</script>
<style>
.banner-list {
  @apply flex;
  @apply flex-col;
  @apply text-center;
  @apply text-white;
}

.banner-list__item {
  @apply p-4;
}

@screen sm {
  .banner-list {
    @apply flex-row;
    @apply justify-center;
  }
}

@screen lg {
  .banner-list {
    @apply px-12;
  }
}
</style>
