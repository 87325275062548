<template>
  <span
    class="flex-initial justify-between items-baseline py-3 px-4 h-12"
    v-html="this.leadSuccessMessage"
  ></span>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  props: {
    leadSuccessMessage: {type: String, default: null}
  },
  computed: {
    color() {
      return this.sectionColor || this.$$section.color;
    }
  },
  methods: {
  }
};
</script>
