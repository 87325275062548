<template>
  <div class="px-4 py-2">
    <div class="rounded-lg shadow-around">
      <div class="flex flex-col p-4 bg-white rounded-lg">
        <div>
          <p>
            <span
              v-show="this.hasValidationErrors"
              class="p-1 text-sm cursor-pointer text-error"
              >Formato coupon non valido, riprovare</span
            >
          </p>
          <p v-if="discount">
            {{ _.get(discount, "code") }}:
            {{
              _.get(discount, "campaign.use_percentage")
                ? "-" + _.get(discount, "campaign.value") + "%"
                : ("-" + _.get(discount, "campaign.value"))
                  | currency("€ ", 2, {
                    decimalSeparator: ",",
                    symbolOnLeft: false,
                    spaceBetweenAmountAndSymbol: true
                  })
            }}
            {{
              _.get(discount, "campaign.payment_type.code") == "recurring"
                ? "/ mese"
                : ""
            }}
          </p>
          <p v-if="this.failedCoupon && discount === null && this.isDiscount">
            <span class="text-sm"
              >Spiacenti, non è stato trovato nessun sconto</span
            >
          </p>
          <p v-if="this.failedCoupon && !this.isDiscount">
            <span class="text-sm">Spiacenti, coupon non valido</span>
          </p>
        </div>
        <div class="flex flex-wrap justify-around">
          <div class="justify-center w-full lg:w-2/3 md:w-1/2">
            <!-- <input
              :id="this.couponType+'-code'"
              :name="this.couponType+'-code'"
              placeholder="Codice promozione"
              class="w-full px-4 py-2 bg-gray-200"
              type="text"
              v-model="couponCode"
              :maxlength="this.couponLength"
              v-validate="{ required: true, regex: /^[^\'\%\&\<\>\.\;\=\$\£\{\}\[\]\\\*]+$/ }"
            />-->
            <input
              :id="this.couponType + '-code'"
              :name="this.couponType + '-code'"
              placeholder="Codice promozione"
              class="w-full px-4 py-2 bg-gray-200"
              type="text"
              v-model="couponCode"
              :maxlength="this.couponLength"
            />
          </div>
          <div
            class="justify-center w-full pt-4 lg:w-1/3 md:w-1/2 md:pl-4 md:pt-0"
          >
            <button
              id="submitButton"
              type="submit"
              @click="submitAndDisable()"
              v-bind:class="
                'w-full bg-azzurro text-white p-2 rounded-full hover:bg-azzurro-lighter ' +
                  (this.submitDisabled ? 'disabled' : '')
              "
            >
              Applica
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    "coupon-length": Number,
    cart: Object,
    "submit-url": String,
    "coupon-type": String
  },
  data: function() {
    return {
      discount: this.cart.offerDiscount,
      couponCode: "",
      failedCoupon: false,
      submitDisabled: false,
      hasValidationErrors: false
    };
  },
  computed: {
    _: function() {
      return _;
    },
    isDiscount: function() {
      return this.couponType === "discount";
    }
  },
  // created: function() {
  //   bus.$on("cart:unselectItem", (id) => {
  //     if(id === this.compId) { this.selComposition = false}
  //   });
  // },

  methods: {
    updateCart() {
      this.$events.emit("cart:cart_updated");
    },
    enableDisableButton() {
      this.submitDisabled = !this.submitDisabled;
    },
    submitAndDisable() {
      this.$events.emit("checkout:loading");
      let component = this;
      component.enableDisableButton();

      // component.$validator.validateAll().then(function (is_valid) {
      //   if (is_valid) {
      component.hasValidationErrors = false;

      this.$axios
        .post(component.submitUrl, { code: component.couponCode })
        .then(response => {
          if (response.status == 200) {
            component.failedCoupon = false;
            if (component.isDiscount) {
              component.discount = response.data.discount;
            } else {
              document.getElementById("goToCheckout").disabled = false;
            }
          }
        })
        .catch(error => {
          component.enableDisableButton();
          component.failedCoupon = error.response.data.failedCoupon;
          //document.getElementById("goToCheckout").disabled = true;
          component.discount = null;
        })
        .finally(() => {
          component.updateCart();
          this.$events.emit("checkout:not-loading");
        });

      //   } else {
      //     component.hasValidationErrors = true;
      //     bus.$emit("checkout:not-loading");
      //   }
      // });
    }
  }
};
</script>
