<template>
  <div class="container p-4 mx-auto">
    <div class="flex flex-wrap justify-around">
      <div class="md:w-3/4 justify-center w-full p-4">
        <div class="flex">
          <div
            class="relative flex-col flex-grow-0 w-1/4 py-4 text-center"
            v-for="(step, index) in top_steps"
            :key="index"
          >
            <div>
              <span
                class="outlined md:h-12 md:w-12 flex items-center justify-center w-10 h-10 mx-auto rounded-full"
                :class="
                  `bg-${
                    step.active
                      ? colour(step)
                      : step.completed
                      ? 'gray-400'
                      : 'white'
                  } ${step.active ? '' : 'border-2 border-gray-400'}`
                "
              >
                <icon-comp
                  v-if="step.customIcon"
                  :icon-name="step.icon"
                  class="text-lg"
                  :class="`text-${step.completed ? 'white' : 'gray-400'}`"
                />
                <font-awesome-icon
                  v-else
                  class="text-xl"
                  :class="`text-${step.completed ? 'white' : 'gray-400'}`"
                  :icon="step.icon"
                />
              </span>
              <p
                class="uppercase"
                :class="[
                  step.active
                    ? `text-linkem-${colour(step)}`
                    : 'text-gray-400 w-0 sm:w-full hidden md:block'
                ]"
              >
                {{ step.label }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import iconComp from "../layouts/iconComp.vue";
export default {
  components: { iconComp },
  props: ["offerColour", "isVoip"],
  data: function() {
    return {
      top_steps: [
        {
          class: "contact-data",
          icon: "envelope",
          steps: ["contact_data"],
          active: false,
          notCompletedClass: [
            "personal-data",
            "address",
            "portability_data",
            "payments"
          ],
          completed: false,
          label: "Dati di contatto",
          order: 1
        },
        {
          class: "personal-data",
          icon: "user",
          steps: ["personal_data"],
          active: false,
          notCompletedClass: ["address", "portability_data", "payments"],
          completed: false,
          label: "Dati personali",
          order: 2
        },
        {
          class: "address",
          icon: "map-marker-alt",
          steps: ["address", "billing_address", "shipping_address"],
          active: false,
          notCompletedClass: ["portability_data", "payments"],
          completed: false,
          label: "Indirizzi",
          order: 3
        },
        {
          class: "payments",
          icon: "credit-card",
          steps: [
            "payment_methods",
            "recurring_rid",
            "contract",
            "recurring_cc",
            "upfront_cc",
            "everything_cc",
            "complete"
          ],
          active: false,
          notCompletedClass: [],
          completed: false,
          label: "Pagamenti",
          order: 5
        }
      ],
      widthStyle: {}
    };
  },
  beforeDestroy() {
    this.$events.off("checkout-step");
  },
  created() {
    this.isVoip
      ? this.top_steps.push({
          class: "portability_data",
          icon: "telephone",
          customIcon: true,
          steps: ["portability_data"],
          active: false,
          notCompletedClass: ["payments"],
          completed: false,
          label: "Portabilità numero",
          order: 4
        })
      : this.top_steps;

    this.top_steps = _.sortBy(this.top_steps, ["order"]);

    this.$events.on("checkout-step", step => {
      _.forEach(this.top_steps, current => {
        if (_.includes(current.steps, step)) {
          current.active = true;
        } else {
          current.active = false;
        }

        var active_step = _.find(this.top_steps, top_step => {
          return _.includes(top_step.steps, step);
        });

        if (_.includes(active_step.notCompletedClass, current.class)) {
          current.completed = false;
        } else {
          current.completed = true;
        }
      });

      /*
      active_step.active = true;*/

      //_.forEach(active_steps.completedClass, function(completedStep){ completedStep.completed = true; });
    });
    this.widthStyle = {
      width: 100 / this.top_steps.length + "%"
    };
  },
  methods: {
    colour(step) {
      return step.active ? this.offerColour : step.completed ? "white" : "grey";
    }
  }
};
</script>
<style scoped>
.flex-col.flex-grow-0.relative.text-center:not(:last-child)::after {
  content: "";
  height: 3px;
  background: #cbd5e0;
  flex-grow: 1;
  width: 100%;
  position: absolute;
  top: 35px;
  left: 50%;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .flex-col.flex-grow-0.relative.text-center:not(:last-child)::after {
    content: "";
    height: 3px;
    background: #cbd5e0;
    flex-grow: 1;
    width: 100%;
    position: absolute;
    top: 39px;
    left: 50%;
    z-index: -1;
  }
}

.outlined {
  outline: 4px solid #fff;
}
</style>
