<template>
  <div class="text-linkem-grigio">
    <toast ref="toast"></toast>
    <drawer go-on-api="" contacts-api="">
      <slot name="drawer" />
    </drawer>
    <div class="antialiased max-w-extreme mx-auto">
      <header-nav
        :area-clienti-api="{
          login: 'login',
          logout: 'logout',
          recoverPwd: 'recoverPwd',
          recoverUsr: 'recoverUsr'
        }"
        :logo="{
          alt: 'Logo Linkem',
          href: '/',
          src: 'logo-linkem-new.png'
        }"
      >
        <template #left-header-top>
          <slot name="left-header-top"></slot>
        </template>
        <template #right-header-top>
          <slot name="right-header-top"></slot>
        </template>
        <template #navbar>
          <slot name="navbar"></slot>
        </template>
      </header-nav>
      <!-- TODO: html di header e footer -->
      <!-- TODO: loader -->
      <main-loader />
      <div class="pt-24">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$root.$toast = this.$refs.toast;
  }
};
</script>
