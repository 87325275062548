<template>
  <div
    v-if="loading || bus_loading"
    class="linkem-loader-container"
    :class="position"
  >
    <div class="linkem-loader">
      <div class="left"></div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "fixed"
    }
  },
  data: function() {
    return {
      bus_loading: false
    };
  },
  beforeDestroy() {
    this.$events.off("checkout:loading");
    this.$events.off("checkout:not-loading");
  },
  mounted: function() {
    this.$events.on("checkout:loading", () => {
      this.bus_loading = true;
    });
    this.$events.on("checkout:not-loading", () => {
      this.bus_loading = false;
    });
  }
};
</script>
