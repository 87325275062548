<template>
  <div class="tooltip"> 
    <icon-comp
      iconName="info-circled"
      iconStyle="text-arancio-primary"
    ></icon-comp>
    <span class="tooltiptext text-linkem-grigio font-normal hidden md:block" v-html="message"></span>
    <span class="tooltiptextmobile text-linkem-grigio font-normal block md:hidden" v-html="message"></span>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    message: { type: String, default: null }
  },
  data: () => ({
    show: false,
    title: String(),
    status: Object()
  })
};
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
  cursor: help;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #FFF2CD;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
  border: 1px solid #EEC64C;
  transform: translateY(-45%);
  width:500px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #EEC64C transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptextmobile {
  visibility: hidden;
  background-color: #FFF2CD;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  border: 1px solid #EEC64C;
  transform: translateX(-30%);
  width:300px;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptextmobile::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #EEC64C transparent;
}
.tooltip:hover .tooltiptextmobile {
  visibility: visible;
}
</style>