var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step == _vm.step_name)?_c('div',{attrs:{"id":"checkout_contact_data_form"}},[_c('step-header',_vm._g({attrs:{"title":"Dati di contatto","icon":"envelope","colour":_vm.colour}},_vm.$listeners)),_c('div',{staticClass:"bg-white rounded-b-lg"},[_c('div',{staticClass:"transition checkout-form content section-light"},[_c('div',{staticClass:"mx-20 mx-auto"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.name,"value":_vm.contact_data.name,"modelName":"checkout.contact_data.name","label":"Nome","maxLength":_vm.inputLength.nameLength,"autocompleteField":"section-contact_data given-name"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {name: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.last_name,"value":_vm.contact_data.last_name,"modelName":"checkout.contact_data.last_name","label":"Cognome","maxLength":_vm.inputLength.lastNameLength,"autocompleteField":"section-contact_data family-name"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {last_name: $event}))}}})],1)]),_c('form-email',{attrs:{"errorModel":_vm.checkout_errors.email,"modelName":"checkout.contact_data.email","maxLength":_vm.inputLength.emailLength,"autocompleteField":"section-contact_data email","confirm-email":_vm.contact_data.confirmEmail},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {email: $event}))},"update:confirm-email":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {confirmEmail: $event}))}},model:{value:(_vm.contact_data.email),callback:function ($$v) {_vm.$set(_vm.contact_data, "email", $$v)},expression:"contact_data.email"}}),_c('form-cellphone',{attrs:{"prefixes":_vm.prefixes,"prefix-error-model":_vm.checkout_errors.international_prefix,"prefix":_vm.contact_data.international_prefix,"phone-error-model":_vm.checkout_errors.mobile_phone,"phone":_vm.contact_data.mobile_phone,"confirmed-phone":_vm.confirmedPhone,"otp-check":_vm.otpCheck,"max-length":_vm.inputLength.mobileLength},on:{"update:confirmed-phone":function($event){return _vm.$emit('update:confirmed-phone', $event)},"update:prefix":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {international_prefix: $event}))},"update:phone":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {mobile_phone: $event}))}}}),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 checkbox"},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors.flag_contacts,"value":_vm.contact_data.flag_contacts,"modelName":"checkout.contact_data.flag_contacts","message":_vm.checkboxMessage},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {flag_contacts: $event}))}}})],1)])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }