<template>
  <div class="fixed z-20 w-full mx-auto max-w-extreme shadow-under">
    <area-clienti
      login="login"
      logout="logout"
      recoverPwd="recoverPwd"
      recoverUsr="recoverUsr"
      configurationItemId="0123456789"
      captcha=""
    ></area-clienti>
    <div class="flex sm:h-24">
      <div class="flex flex-col flex-1">
        <div class="sm:bg-white sm:h-12"></div>
        <div class="sm:bg-white sm:h-12"></div>
      </div>
      <nav class="container flex flex-row flex-no-wrap px-0 bg-white">
        <div
          class="z-10 flex-no-wrap self-center flex-none hidden bg-transparent sm:block sm:-mr-12 sm:h-24"
        >
          <logo v-bind="logo" class="flex-none"></logo>
          <svg
            class="flex-none inline-block h-full text-white align-top fill-current stroke-current"
            style="width:25px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31.8906 118.9999"
          >
            <path
              d="M.0078,119s3.1191-4.6258,6.0469-9.696L29.3525,68.951a19.12,19.12,0,0,0,0-19.0653L6.0547,9.5326C3.127,4.4626.0078,0,.0078,0Z"
              transform="translate(-0.0078 0)"
            />
          </svg>
        </div>
        <div class="flex flex-col flex-1">
          <div
            class="flex items-stretch h-12 px-4 text-white sm:pl-20"
            :class="`bg-${color}`"
          >
            <div
              class="flex items-baseline justify-between sm:w-full sm:items-stretch"
            >
              <ul class="flex -mx-4">
                <slot name="left-header-top"></slot>
              </ul>
              <ul class="items-center hidden -mx-4 sm:flex">
                <area-clienti-button>
                  <icon-comp iconName="user" />Area clienti
                </area-clienti-button>
                <!-- Eventuali altri link -->
                <link-menu>
                  <icon-comp iconName="telephone" />06 94444
                </link-menu>
                <!-- Eventuali altri link -->
              </ul>
            </div>
          </div>
          <div
            class="flex items-stretch px-4 bg-gray-200 sm:h-12 sm:pl-10"
            :class="`text-${color}-primary`"
          >
            <div
              class="flex flex-col items-stretch items-center w-full sm:flex-row sm:justify-end"
            >
              <div
                class="flex items-center justify-between py-2 -mx-4 sm:hidden"
              >
                <div class="flex justify-start flex-1">
                  <link-menu>
                    <font-awesome-icon
                      class="cursor-pointer"
                      icon="bars"
                      @click="menu = !menu"
                    ></font-awesome-icon>
                  </link-menu>
                </div>
                <logo class="flex-none" v-bind="logo"></logo>
                <ul class="flex justify-end flex-1">
                  <area-clienti-button>
                    <icon-comp iconName="user" />
                  </area-clienti-button>
                  <!-- Eventuali altri link -->
                  <link-menu>
                    <icon-comp iconName="phone" />
                  </link-menu>
                  <!-- Eventuali altri link -->
                </ul>
              </div>
              <transition-expand>
                <ul
                  v-show="menu"
                  class="flex flex-col -mx-4 bg-white sm:hidden sm:bg-transparent sm:flex-row"
                >
                  <slot name="navbar"></slot>
                </ul>
              </transition-expand>
              <ul
                class="flex-col hidden -mx-4 bg-white sm:flex sm:bg-transparent sm:flex-row"
              >
                <slot name="navbar"></slot>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="flex flex-col flex-1">
        <div class="sm:h-12" :class="`sm:bg-${color}-secondary`"></div>
        <div class="sm:bg-gray-200 sm:h-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: false
  }),
  props: {
    checkout: Boolean,
    areaClientiApi: {
      type: Object,
      required: true
    },
    logo: {
      type: Object,
      required: true
    },
    configurationItemId: String
  }
};
</script>
