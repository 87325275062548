<template>
  <div class="w-full justify-center p-4">
    <div class="info__wrapper">
      <h2
        class="block text-xl font-bold uppercase mb-4"
        :class="`text-${color}-primary`"
      >
        Prima di acquistare
      </h2>
      <ul class="flex flex-wrap">
        <li
          class="info__wrapper__link"
          v-for="(item, index) in theList"
          :key="`${item.title}-${index}`"
        >
          <font-awesome-icon
            class="mr-2"
            :class="`text-${color}-primary`"
            icon="chevron-right"
            @click="menu = !menu"
          ></font-awesome-icon>
          <a :href="item.url">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemList: Array
  },
  computed: {
    theList() {
      return _.slice(
        _.shuffle(this.itemList),
        0,
        _.random(3, this.itemList.length)
      );
    }
  }
};
</script>
