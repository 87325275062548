import _ from "lodash";
export default function(options = {}) {
  this.xs = false;
  this.sm = false;
  this.md = false;
  this.lg = false;
  this.xl = false;
  this.xsOnly = false;
  this.smOnly = false;
  this.smAndDown = false;
  this.smAndUp = false;
  this.mdOnly = false;
  this.mdAndDown = false;
  this.mdAndUp = false;
  this.lgOnly = false;
  this.lgAndDown = false;
  this.lgAndUp = false;
  this.xlOnly = false;
  this.name = "";
  this.height = 0;
  this.width = 0;
  this.thresholds = {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280
  };
  this.scrollBarWidth = 16;
  this.resizeTimeout = 0;

  ////

  this.init = function() {
    /* istanbul ignore if */
    if (typeof window === "undefined") return;
    window.addEventListener("resize", this.onResize.bind(this), {
      passive: true
    });
    this.update();
  };

  this.onResize = function() {
    clearTimeout(this.resizeTimeout); // Added debounce to match what
    // v-resize used to do but was
    // removed due to a memory leak
    // https://github.com/vuetifyjs/vuetify/pull/2997
    this.resizeTimeout = window.setTimeout(this.update.bind(this), 200);
  };

  this.update = function() {
    const height = this.getClientHeight();
    const width = this.getClientWidth();
    const xs = width < this.thresholds.xs;
    const sm = width < this.thresholds.sm && !xs;
    const md = width < this.thresholds.md - this.scrollBarWidth && !(sm || xs);
    const lg =
      width < this.thresholds.lg - this.scrollBarWidth && !(md || sm || xs);
    const xl = width >= this.thresholds.lg - this.scrollBarWidth;
    this.height = height;
    this.width = width;
    this.xs = xs;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
    this.xsOnly = xs;
    this.smOnly = sm;
    this.smAndDown = (xs || sm) && !(md || lg || xl);
    this.smAndUp = !xs && (sm || md || lg || xl);
    this.mdOnly = md;
    this.mdAndDown = (xs || sm || md) && !(lg || xl);
    this.mdAndUp = !(xs || sm) && (md || lg || xl);
    this.lgOnly = lg;
    this.lgAndDown = (xs || sm || md || lg) && !xl;
    this.lgAndUp = !(xs || sm || md) && (lg || xl);
    this.xlOnly = xl;
    switch (true) {
      case xs:
        this.name = "xs";
        break;
      case sm:
        this.name = "sm";
        break;
      case md:
        this.name = "md";
        break;
      case lg:
        this.name = "lg";
        break;
      default:
        this.name = "xl";
        break;
    }
  };

  this.getClientWidth = function() {
    /* istanbul ignore if */
    if (typeof document === "undefined") return 0; // SSR
    return Math.max(
      _.get(document, "documentElement.clientWidth"),
      window.innerWidth || 0
    );
  };

  this.getClientHeight = function() {
    /* istanbul ignore if */
    if (typeof document === "undefined") return 0; // SSR
    return Math.max(
      _.get(document, "documentElement.clientHeight"),
      window.innerHeight || 0
    );
  };

  ////

  this.thresholds = {
    ...this.thresholds,
    ...options.thresholds
  };
  this.scrollBarWidth = options.scrollBarWidth || this.scrollBarWidth;

  this.init();
}
