<template>
  <div class="supporto__container">
    <div class="supporto__icon">
      <img :src="imgPath" />
    </div>
    <div class="supporto__section__wrapper">
      <h1 class="supporto__section__title">
        {{ title }}
      </h1>
      <div class="text-justify mb-20">
        <p class="p-10 py-4 text-sm">
          {{ textContent }}
        </p>
        <div class="supporto__section__wrapbutton">
          <button class="supporto__section__button">
            <a :href="buttonLink">{{ buttonText }}</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgPath: String,
    title: String,
    textContent: String,
    buttonLink: String,
    buttonText: String
  }
};
</script>

<style scoped>
.supporto__container {
  @apply flex;
  @apply flex-none;
  @apply w-full;
  @apply my-10;
  @apply justify-center;
  @apply relative;
}

@screen lg {
  .supporto__container {
    @apply w-1/2;
  }
}
</style>
