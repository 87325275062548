<template>
  <div class="flex flex-none w-full justify-center p-4" :class="width">
    <div class="teaser__wrapper" :class="{ 'teaser__wrapper--single': single }">
      <div
        v-if="!dense"
        class="teaser__image"
        :class="[
          { 'teaser__image--single': single },
          { 'teaser__image--simple': simple }
        ]"
        :style="
          `background-image: url(${require('@/assets/images/' + picture)})`
        "
      ></div>
      <div
        v-if="!simple && perRow != 1"
        :class="[
          high
            ? `teaser__text--high text-${color}-primary`
            : `teaser__text text-${color}-primary`
        ]"
      >
        <div
          class="leading-none mb-2 text-4-5xl"
          :class="`text-${color}-primary`"
        >
          <slot name="icon"></slot>
        </div>
        <p class="font-bold text-xl uppercase leading-none">
          <slot name="title" />
        </p>
        <p class="text-md lg:text-lg xl:text-xl px-2 capitalize">
          <slot name="subtitle" />
        </p>
      </div>
      <div v-if="simple" class="teaser__body text-center p-4">
        <div
          class="leading-none mb-4 text-4-5xl"
          :class="`text-${color}-secondary`"
        >
          <slot name="icon"></slot>
        </div>
        <h3 class="font-bold text-2xl" :class="`text-${color}-secondary`">
          <slot name="title" />
        </h3>
        <p class="">
          <slot name="content" />
        </p>
      </div>
      <div
        v-if="!dense || !simple"
        class="teaser__features"
        :class="{ 'teaser__features--single': single }"
      >
        <slot name="features" />
      </div>

      <div
        class
        :class="{ 'teaser__price--single': single, 'mt-auto': !single }"
      >
        <div
          v-if="perRow == 1"
          class="flex flex-col text-center justify-center h-30 overflow-hidden p-2"
          :class="`text-${color}-primary`"
        >
          <div
            class="leading-none mb-2 text-4-5xl"
            :class="`text-${color}-primary`"
          >
            <slot name="icon"></slot>
          </div>
          <p class="font-bold text-xl uppercase leading-none">
            <slot name="title" />
          </p>
          <p class="text-md lg:text-lg xl:text-xl px-2">
            <slot name="subtitle" />
          </p>
        </div>
        <slot name="price" />
        <div class="flex pb-4">
          <slot name="button" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    link: String,
    dense: Boolean,
    price: String,
    monthly: Boolean,
    high: Boolean,
    perRow: Number,
    picture: String,
    simple: Boolean
  },
  computed: {
    currency() {
      return _.split(this.price, ",");
    },
    width() {
      switch (this.perRow) {
        case 1:
          return "md:w-full";
        case 2:
          return "md:w-1/2 lg:w-2/5";
        case 3:
          return "md:w-1/3";
        default:
          return "md:w-full";
      }
    },
    single() {
      return this.perRow === 1;
    }
  }
};
</script>

<style>
.teaser__wrapper {
  @apply flex;
  @apply bg-white;
  @apply flex-wrap;
  @apply w-full;
  @apply overflow-hidden;
  @apply rounded-lg;
  @apply shadow-around;
  @apply flex-col;
}

.teaser__wrapper--single {
  @apply flex-row;
}

.teaser__image {
  @apply flex;
  @apply h-44;
  @apply bg-cover;
  @apply bg-center;
  @apply justify-center;
  @apply items-center;
}

.teaser__image--single {
  @apply flex-none;
  @apply w-full;
  @apply h-auto;
  @apply min-h-44;
}

.teaser__image--simple {
  @apply flex-none;
  @apply w-full;
  @apply h-auto;
  @apply min-h-60;
}

.teaser__text {
  @apply flex;
  @apply flex-col;
  @apply text-center;
  @apply h-30;
  @apply border-b;
  @apply overflow-hidden;
  @apply py-2;
}

.teaser__text--high {
  @apply flex;
  @apply flex-col;
  @apply text-center;
  @apply h-34;
  @apply leading-tight;
  @apply border-b;
  @apply overflow-hidden;
  @apply py-2;
}

.teaser__features {
  @apply hidden;
  @apply flex-wrap;
}

.teaser__features--single {
  @apply w-1/4;
  @apply -mb-px;
}

.teaser__price--single {
  @apply flex;
  @apply flex-1;
  @apply flex-col;
  @apply w-4/12;
  @apply justify-center;
  @apply border-l;
  @apply items-center;
  @apply text-center;
  @apply p-2;
}

.feature__item {
  @apply flex;
  @apply flex-auto;
  @apply items-center;
  @apply overflow-hidden;
  @apply leading-none;
  @apply text-sm;
}

@screen md {
  .teaser__image--single {
    @apply w-2/5;
  }
  .teaser__features {
    @apply flex;
  }
  .teaser__features--single {
    @apply hidden;
  }
  .feature__item--single {
    @apply h-auto;
  }
}

@screen xl {
  .teaser__features--single {
    @apply flex;
  }
}
</style>
