<template>
  <link-menu button :event="eventAction">
    <slot />
  </link-menu>
</template>

<script>
export default {
  data: () => ({
    expand: true
  }),
  props: {
    event: String,
    logged: Boolean,
    button: Boolean,
    link: String
  },
  computed: {
    eventAction() {
      return ["toggleLogin", this.logged ? "logout" : "login"];
    }
  }
};
</script>
