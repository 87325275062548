<template>
  <div id="checkout_portability_data_form" v-if="step == step_name">
    <step-header
      title="Portabilità numero"
      icon="telephone"
      custom-icon
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto">
          <div class="lg:w-auto justify-center flex-shrink w-full p-4">
            <div class="checkbox justify-center w-full p-4">
              <label for="new">
                <input
                  type="radio"
                  id="numberManagement"
                  value="new"
                  v-model="portability_data.numberManagement"
                  @change="
                    $emit('update:portability_data', {
                      ...portability_data,
                      numberManagement: $event.target.value
                    })
                  "
                />
                Attiva un nuovo numero di telefono fisso
              </label>
              <br />
              <label for="port">
                <input
                  type="radio"
                  id="numberManagement"
                  value="port"
                  v-model="portability_data.numberManagement"
                  @change="
                    $emit('update:portability_data', {
                      ...portability_data,
                      numberManagement: $event.target.value
                    })
                  "
                />
                Passa a Linkem mantenendo il tuo numero di telefono fisso</label
              >
            </div>
          </div>
          <template v-if="portability_data.numberManagement === 'port'">
            <form-cellphone
              :prefixes="prefixes"
              :prefix-error-model="checkout_errors.prefix"
              :prefix="portability_data.prefix"
              :phone-error-model="checkout_errors.phone"
              :phone="portability_data.phone"
              phone-label="Numero"
              rules="required"
              :confirmed-phone="confirmedPhone"
              @update:confirmed-phone="$emit('update:confirmed-phone', $event)"
              phone-model-name="checkout.portability_data.phone"
              prefix-model-name="checkout.portability_data.prefix"
              @update:prefix="
                $emit('update:portability_data', {
                  ...portability_data,
                  prefix: $event
                })
              "
              @update:phone="
                $emit('update:portability_data', {
                  ...portability_data,
                  phone: $event
                })
              "
              :otp-check="false"
              :max-length="inputLength.voipLenght"
              :voip-field="true"
            />
            <div class="lg:flex-no-wrap flex flex-wrap lg:w-1/2">
              <div class="justify-center w-full p-4">
                <linkem-input
                  :errorModel="checkout_errors.migration"
                  :value="portability_data.migration"
                  @input="
                    $emit('update:portability_data', {
                      ...portability_data,
                      migration: $event
                    })
                  "
                  modelName="checkout.portability_data.migration"
                  label="Codice di Migrazione"
                  :maxLength="inputLength.migrationLength"
                />
              </div>
              <!-- <div class="lg:w-1/2 justify-center w-full p-4">
                <input-autocomplete
                  :error-model="operatorErrorModel"
                  :value="portability_data.operator"
                  @input="
                    $emit('update:portability_data', {
                      ...portability_data,
                      operator: $event
                    })
                  "
                  model-name="checkout.portability_data.operator"
                  label="Operatore"
                  :options="operatori"
                ></input-autocomplete>
              </div> -->
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    // prefixes: {
    //   required: true
    // },
    // operators: {
    //   required: true
    // },
    portability_data: {
      required: true
    },
    checkout_errors: {
      required: true,
      default: {}
    },
    inputLength: {
      required: true
    },
    colour: {
      default: "arancio"
    }
  },
  data: function() {
    return {
      step_name: "portability_data",
      operatori: [
        {
          code: "1",
          label: "Wind Tre"
        },
        {
          code: "2",
          label: "Tim"
        },
        {
          code: "3",
          label: "Vodafone"
        },
        {
          code: "4",
          label: "Fastweb"
        },
        {
          code: "5",
          label: "Tiscali"
        },
        {
          code: "6",
          label: "Eolo"
        },
        {
          code: "7",
          label: "Altro"
        }
      ]
    };
  }
};
</script>
