<template>
  <label class="control-label flex items-baseline">
    <input
      :id="modelName.replace(/\./g, '_')"
      :name="modelName"
      type="checkbox"
      v-model="data_value"
      :disabled="disabled"
    />
    <span v-html="message"> {{ message }} </span>
  </label>
</template>
<script>
export default {
  props: {
    errorModel: {
      required: true
    },
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    modelName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String
    }
  },
  data: function() {
    return {
      error_model: this.errorModel,
      data_value: this.value
    };
  },
  watch: {
    errorModel: function() {
      this.error_model = this.errorModel;
    },
    data_value: function(val) {
      this.$emit("input", val);
    }
  }
};
</script>
