<template>
  <div id="checkout_address_form" v-if="step == step_name">
    <step-header
      title="Metodi pagamento"
      icon="credit-card"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto mx-20">
          <div
            class="flex flex-wrap justify-around"
            v-if="recurring_payment_methods"
          >
            <div class="p-4 w-full" v-if="payment_methods.has_recurring == '1'">
              <h4 class="font-bold m-1">
                <span class="text-arancio-primary">Contributo mensile</span>
              </h4>
              <div class="form-group m-1">
                <div class="form-group">
                  <!-- <label>Seleziona il metodo di pagamento</label> -->
                  <div class="flex flex-wrap justify-around">
                    <div
                      class="p-4 w-full lg:w-1/2 justify-center"
                      v-for="(recpaymeth, index) in recurring_payment_methods"
                      :key="index"
                    >
                      <linkem-radio
                        :value="payment_methods.recurring_payment_method"
                        @input="
                          $emit('update:payment_methods', {
                            ...payment_methods,
                            recurring_payment_method: $event
                          })
                        "
                        modelName="checkout.payment_methods.recurring_payment_method"
                        :radioValue="recpaymeth.code"
                        :message="recpaymeth.name"
                        :disabled="recPm ? true : false"
                      ></linkem-radio>
                    </div>
                  </div>
                  <span
                    class="text-error"
                    v-for="(item,
                    index) in checkout_errors.recurring_payment_method"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap justify-around"
            v-if="upfront_payment_methods"
          >
            <div class="p-4 w-full" v-if="payment_methods.has_upfront == '1'">
              <h4 class="font-bold m-1">
                <span class="text-arancio-primary">Contributo immediato</span>
              </h4>
              <div class="form-group m-1">
                <div class="form-group">
                  <!-- <label>Seleziona il metodo di pagamento</label> -->
                  <div class="flex flex-wrap justify-around">
                    <div
                      class="p-4 w-full lg:w-1/2 justify-center"
                      v-for="(uppaymeth, index) in upfront_payment_methods"
                      :key="index"
                    >
                      <linkem-radio
                        :value="payment_methods.upfront_payment_method"
                        @input="
                          $emit('update:payment_methods', {
                            ...payment_methods,
                            upfront_payment_method: $event
                          })
                        "
                        modelName="checkout.payment_methods.upfront_payment_method"
                        :radioValue="uppaymeth.code"
                        :message="uppaymeth.name"
                        :callMethod="true"
                      ></linkem-radio>
                    </div>
                  </div>
                  <span
                    class="text-error"
                    v-for="(item,
                    index) in checkout_errors.upfront_payment_method"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="p-4"
            v-if="payment_methods.has_recurring == 1"
          >
            <span v-if="this.recPm == 'cc'" v-html="ccMessage"/>
            <span v-else-if="this.recPm == 'rid'" v-html="ridMessage"/>
            <span v-else v-html="sepa_message"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    payment_methods: {
      required: true
    },
    checkout_errors: {
      required: true,
      default: {}
    },
    inputLength: {
      required: true
    },
    sepa_message: {
      required: true
    },
    ridMessage: {},
    ccMessage: {},
    upfront_payment_methods: {},
    recurring_payment_methods: {},
    recPm: {},
    colour: {
      default: "arancio"
    }
  },
  data: function() {
    return {
      step_name: "payment_methods"
    };
  }
};
</script>
