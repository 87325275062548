<template id="tpl-checkout">
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent autocomplete="off">
      <div>
        <div class="p-4 pt-2">
          <section class="shadow-around rounded-lg">
            <div id="checkout-container">
              <component
                :is="`${target}-contact-data`"
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :checkboxMessage="contactMessage"
                :businessMessage="businessMessage"
                :inputLength="inputLength"
                :prefixes="prefixes"
                :confirmed-phone.sync="confirmedPhone"
                :colour="colour"
                :otp-check="otpCheckEnable"
                v-if="checkIfActive(step)"
              ></component>
              <component
                :is="`${target}-personal-data`"
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :inputLength="inputLength"
                :colour="colour"
                v-if="checkIfActive(step)"
              ></component>
              <address-step
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :inputLength="inputLength"
                :account="account"
                :old_address="address"
                :colour="colour"
                :google="google"
                v-if="checkIfActive(step)"
              ></address-step>
              <portability-data
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :inputLength="inputLength"
                :account="account"
                :colour="colour"
                v-if="checkIfActive(step)"
              ></portability-data>
              <payment-methods
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :inputLength="inputLength"
                :sepa_message="sepaMessage"
                :recurring_payment_methods="recurringPaymentMethods"
                :upfront_payment_methods="upfrontPaymentMethods"
                :recPm="recPm"
                :colour="colour"
                :ridMessage="ridMessage"
                :ccMessage="ccMessage"
                v-if="checkIfActive(step)"
              ></payment-methods>
              <recurring-rid
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :inputLength="inputLength"
                :phone="previousPhone || checkout.contact_data.mobile_phone"
                :confirmed-phone.sync="confirmedPhone"
                :confirmed-iban.sync="confirmedIban"
                :iban="iban"
                :colour="colour"
                :ridConditionsMessage="ridConditionsMessage"
                v-if="checkIfActive(step)"
              ></recurring-rid>
              <contract
                :step="step"
                v-bind.sync="checkout"
                :checkout_errors="checkout_errors"
                :emailMessage="emailMessage"
                :termsMessage="termsMessage"
                :privacyMessage="privacyMessage"
                :resetMessage="resetMessage"
                :marketingMessage="marketingMessage"
                :pdfRoute="pdfRoute"
                :infoRoute="infoRoute"
                :colour="colour"
                :target="target"
                :activation-enabled="activationEnabled"
                :activation-message="activationMessage"
                :tooltip-message="tooltipMessage"
                v-if="checkIfActive(step)"
              ></contract>
              <complete
                :step="step"
                :colour="colour"
                v-if="checkIfActive(step)"
              ></complete>
            </div>
          </section>
        </div>
        <checkout-validation-btn :invalid="invalid"></checkout-validation-btn>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
// import Datepicker from './vendor/vuejs-datepicker';
// import { it } from '.vendor/vuejs-datepicker/dist/locale'
export default {
  // components: { Datepicker },
  template: "#tpl-checkout",
  props: [
    "initStep",
    "target",
    "account",
    "address",
    "sameShipping",
    "sameBilling",
    "hasRecurring",
    "hasUpfront",
    "recPm",
    "iban",
    "mandato",
    "billingAddress",
    "prefixes",
    "contactMessage",
    "inputLength",
    "sepaMessage",
    "recurringPaymentMethods",
    "upfrontPaymentMethods",
    "emailMessage",
    "termsMessage",
    "privacyMessage",
    "resetMessage",
    "marketingMessage",
    "ridConditionsMessage",
    "pdfRoute",
    "infoRoute",
    "businessMessage",
    "colour",
    "otpCheckEnable",
    "otpMailEnable",
    "google",
    "previousPhone",
    "isVoip",
    "ridMessage",
    "ccMessage",
    "activationEnabled",
    "activationMessage",
    "tooltipMessage"
  ],
  data: function() {
    return {
      step: "",
      previousSteps: {},
      //datePickerLang: it,
      confirmedPhone: Boolean(),
      confirmedIban: Boolean(),
      checkout: {
        contact_data: {
          international_prefix: "+39",
          is_PA: false,
          confirmEmail: String()
        },
        personal_data: {},
        portability_data: {
          prefix: this.address.prefix
        },
        address: {
          activation_address: {
            route: this.address.route,
            street_number: this.address.street_number,
            city: this.address.city,
            administrative_area: this.address.administrative_area,
            postal_code: this.address.postal_code,
            same_shipping_address: this.sameShipping,
            same_billing_address: this.sameBilling,
            cat: this.address.cat,
            istat: this.address.istat,
            extra: this.address.extra
          },
          billing_address: this.billingAddress
            ? {
                care_of: this.billingAddress.name,
                route: this.billingAddress.line1,
                street_number: this.billingAddress.number,
                city: this.billingAddress.city,
                administrative_area: this.billingAddress.province,
                postal_code: this.billingAddress.postal_code
              }
            : {},
          shipping_address: {}
        },
        payment_methods: {
          has_recurring: this.hasRecurring,
          has_upfront: this.hasUpfront,
          recurring_payment_method: this.recPm
        },
        recurring_rid: {
          iban: this.iban,
          codice_mandato: this.mandato
        },
        contract: {},
        complete: {}
      },
      checkout_errors: {}
    };
  },
  watch: {
    // "checkout.personal_data.birth_country.code": function() {
    //   if(this.checkout.personal_data.birth_country.code != "ITALIA;Z000;IT")
    //     this.checkout.personal_data.birth_city = null;
    // },
    step: function() {
      //leave-checkout component
      if (this.step != "contact_data") {
        this.$events.emit(
          "checkout:privacy",
          this.checkout.contact_data.flag_contacts
        );
      }
    },
    checkout: {
      handler: function(val, oldVal) {
        (val = _.omit(val, "contract")), (oldVal = _.omit(oldVal, "contract"));
        if (!_.isEqual(val, oldVal)) {
          this.checkout.contract = {};
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$events.off("checkout:validateStep");
    this.$events.off("checkout:validatePreviousStep");
  },
  created: function() {
    this.changeStep(this.initStep);
    this.$events.on("checkout:validateStep", () => {
      this.changeCheckoutStep(this.step);
    });
    this.$events.on("checkout:validatePreviousStep", () => {
      this.changeStep(this.previousSteps[this.step]);
    });
  },
  methods: {
    checkIfActive(step) {
      if (
        (step == "shipping_address" &&
          this.checkout.address.same_shipping_address) ||
        (step == "billing_address" &&
          this.checkout.address.same_billing_address) ||
        (step == "recurring_rid" &&
          !this.checkout.payment_methods.recurring_payment_method) ||
        (step == "recurring_rid" &&
          this.checkout.payment_methods.recurring_payment_method != "rid") ||
        ((step == "contact_data" || step == "personal_data") &&
          this.initStep == "address") ||
        (step == "payment_methods" &&
          this.hasRecurring == "" &&
          this.hasUpfront == "") ||
        (step == "portability_data" && !this.isVoip)
      ) {
        return false;
      }
      return true;
    },
    changeStep(stepName) {
      if (
        stepName == "recurring_cc" ||
        stepName == "upfront_cc" ||
        stepName == "everything_cc"
      ) {
        this.$events.emit("checkout:loading");
        window.location.replace("/checkout/creditcard");
      }
      if (stepName == "complete") {
        this.$events.emit("checkout:loading");
        window.location.replace("/checkout/thanks");
      }
      if (this.checkout[stepName]) {
        this.$events.emit("checkout:loading");
        this.step = stepName;
        this.$events.emit("checkout-step", this.step);
        this.$events.emit("checkout:not-loading");
      }
    },
    changeCheckoutStep(step) {
      this.$events.emit("checkout:loading");
      this.$axios
        .post(
          _.join([window.location.origin, "checkout", this.target, step], "/"),
          this.checkout
        )
        .then(
          response => {
            if (response.status == 200) {
              this.changeStep(response.data.step);
              this.previousSteps[response.data.step] = step;
              this.checkout_errors = {};
            }
          },
          error => {
            if (error.response.status == 500) {
              this.$root.$toast.open(
                "Ops! Qualcosa è andato storto",
                error.response.data.message,
                {
                  type: "error"
                }
              );
            }
            if (error.response.status == 422) {
              this.checkout_errors = error.response.data.errors;
            }
            if (error.response.status == 408 || error.response.status == 400) {
              this.$root.$toast.open(
                "error.response.data.title",
                "<div class='padding-20 gis-message text-center'>" +
                  error.response.data.message +
                  "</div>" +
                  "<div><a href='/' class='link-button up margin-20 no-margin-top cursor-pointer'>Torna alla home</a></div>",
                {
                  type: "error"
                }
              );
            }
            this.$events.emit("checkout:not-loading");
          }
        );
    },
    setOption(model, option) {
      model = option;
    }
  }
};
</script>
