<template>
  <ValidationObserver
    v-slot="{ errors, handleSubmit }"
    class="sm:flex-no-wrap flex flex-wrap"
    :class="{ 'lg:w-1/2': voipField }"
  >
    <input-element
      class="flex-shrink p-4"
      :class="voipField ? 'sm:w-40 pr-0' : 'sm:w-30'"
      :error-model="prefixErrorModel"
      :value="prefix"
      @input="$emit('update:prefix', $event)"
      :model-name="prefixModelName"
      label="Prefisso"
      :options="prefixes"
      disabled
    ></input-element>

    <input-element
      class="flex-grow p-4"
      :rules="rules"
      :name="phoneLabel"
      :error-model="phoneErrorModel"
      :value="phone"
      :label="phoneLabel"
      @input="$emit('update:phone', $event)"
      :model-name="phoneModelName"
      :max-length="maxLength"
      autocomplete-field="section-contact_data tel"
      :append-icon="otpCheck && _.isEmpty(errors.verified) ? 'check' : ''"
    />
    <form-otp
      :phone="phone"
      v-if="otpCheck"
      :validable="_.isEmpty(errors.Cellulare)"
      :handle-submit="handleSubmit"
      :confirmed-phone="confirmedPhone"
      @update:confirmed-phone="$emit('update:confirmed-phone', $event)"
    />
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    phone: String,
    prefix: String,
    autocompleteField: String,
    modelName: String,
    maxLength: [String, Number],
    phoneErrorModel: [String, Object, Array],
    prefixErrorModel: [String, Object, Array],
    prefixes: [Array],
    otpCheck: Boolean,
    confirmedPhone: Boolean,
    phoneLabel: { default: "Cellulare" },
    phoneModelName: { default: "checkout.contact_data.mobile_phone" },
    prefixModelName: { default: "checkout.contact_data.international_prefix" },
    rules: { default: "required|cell" },
    voipField: Boolean
  }
};
</script>
