<template>
  <section
    class="bg-section"
    :style="`background-image: url(${require('@/assets/images/' + picture)})`"
  >
    <slot name="content" />
  </section>
</template>
<script>
export default {
  props: {
    picture: String
  }
};
</script>
<style>
.bg-section {
  @apply flex flex-none bg-cover bg-center;
}
</style>
