<template>
  <section class="p-4 bg-gray-200">
    <div class="container mx-auto">
      <div class="flex flex-wrap justify-between">
        <contact-card
          v-for="card in contactCards"
          :key="card.title"
          v-bind="card"
        ></contact-card>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    contactCards: Array
  }
};
</script>
