var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.$scopedSlots.title)?_c('div',{staticClass:"text-center p-4",class:("text-" + _vm.color + "-primary")},[_c('div',{staticClass:"block text-2xl"},[_vm._t("title")],2)]):_vm._e(),_c('swiper',{ref:"theSwiper",attrs:{"options":_vm.swiperOption}},[_vm._t("default"),_c('div',{staticClass:"swiper-pagination",class:[
        _vm.inset
          ? 'dark swiper-pagination-clickable swiper-pagination-bullets'
          : '',
        _vm.outlinebullet ? 'dark' : '',
        _vm.textColor,
        ("instance-" + _vm._uid)
      ],attrs:{"slot":"pagination"},slot:"pagination"}),(_vm.navigation)?[_c('div',{staticClass:"swiper-button-prev flex",class:[
          ("text-" + _vm.color + "-primary instance-" + _vm._uid),
          _vm.navigationMobile ? 'sm:hidden' : ''
        ],attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left","size":"2x"}})],1),_c('div',{staticClass:"swiper-button-next flex",class:[
          ("text-" + _vm.color + "-primary instance-" + _vm._uid),
          _vm.navigationMobile ? 'sm:hidden' : ''
        ],attrs:{"slot":"button-next"},slot:"button-next"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right","size":"2x"}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }