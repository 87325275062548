<template>
  <div id="checkout_contact_data_form" v-if="step == step_name">
    <step-header
      title="Dati di contatto"
      icon="contact-data"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="transition checkout-form content section-light">
        <div class="mx-auto">
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4">
              <linkem-input
                :errorModel="checkout_errors.company_name"
                :value="contact_data.company_name"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    company_name: $event
                  })
                "
                modelName="checkout.contact_data.company_name"
                label="Ragione sociale"
                :maxLength="`${inputLength.companyNameLength}`"
                autocompleteField="section-contact_data given-name"
              ></linkem-input>
            </div>
          </div>
          <form-email
            v-model="contact_data.email"
            @input="
              $emit('update:contact_data', {
                ...contact_data,
                email: $event
              })
            "
            modelName="checkout.contact_data.email"
            :maxLength="inputLength.emailLength"
            autocompleteField="section-contact_data email"
            :confirm-email="contact_data.confirmEmail"
            @update:confirm-email="
              $emit('update:contact_data', {
                ...contact_data,
                confirmEmail: $event
              })
            "
          />
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.VAT_number"
                :value="contact_data.VAT_number"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    VAT_number: $event
                  })
                "
                modelName="checkout.contact_data.VAT_number"
                label="Partita IVA"
                :maxLength="`${inputLength.vatLength}`"
                autocompleteField="on"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.fiscal_code"
                :value="contact_data.fiscal_code"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    fiscal_code: $event
                  })
                "
                modelName="checkout.contact_data.fiscal_code"
                label="Codice fiscale"
                :maxLength="`${inputLength.taxCodeLength}`"
                autocompleteField="on"
              ></linkem-input>
            </div>
          </div>
          <form-cellphone
            :prefixes="prefixes"
            :prefix-error-model="checkout_errors.international_prefix"
            :prefix="contact_data.international_prefix"
            :phone-error-model="checkout_errors.mobile_phone"
            :phone="contact_data.mobile_phone"
            :confirmed-phone="confirmedPhone"
            @update:confirmed-phone="$emit('update:confirmed-phone', $event)"
            @update:prefix="
              $emit('update:contact_data', {
                ...contact_data,
                international_prefix: $event
              })
            "
            @update:phone="
              $emit('update:contact_data', {
                ...contact_data,
                mobile_phone: $event
              })
            "
            :otp-check="otpCheck"
            :max-length="inputLength.mobileLength"
            :section-color="colour"
          />
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-full">
              <address-autocomplete
                :error-model="checkout_errors.route"
                :value="contact_data"
                @google-input="
                  $emit('update:contact_data', { ...contact_data, ...$event })
                "
                modelName="contact_data_google"
                label="Inserire l'indirizzo completo"
              ></address-autocomplete>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-3/4">
              <linkem-input
                :errorModel="checkout_errors.route"
                :value="contact_data.route"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    route: $event
                  })
                "
                modelName="checkout.contact_data.route"
                label="Indirizzo sede legale"
                :disabled="true"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/4">
              <linkem-input
                :errorModel="checkout_errors.street_number"
                :value="contact_data.street_number"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    street_number: $event
                  })
                "
                modelName="checkout.contact_data.street_number"
                label="Civico sede legale"
                :maxLength="`${inputLength.streetNumberLength}`"
                autocompleteField="section-contact_data address-line2"
              ></linkem-input>
            </div>
          </div>
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-1/3">
              <linkem-input
                :errorModel="checkout_errors.administrative_area"
                :value="contact_data.administrative_area"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    administrative_area: $event
                  })
                "
                modelName="checkout.contact_data.administrative_area"
                label="Provincia sede legale"
                maxlength="2"
                :disabled="true"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/3">
              <linkem-input
                :errorModel="checkout_errors.city"
                :value="contact_data.city"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    city: $event
                  })
                "
                modelName="checkout.contact_data.city"
                label="Comune sede legale"
                :disabled="true"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/3">
              <linkem-input
                :errorModel="checkout_errors.postal_code"
                :value="contact_data.postal_code"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    postal_code: $event
                  })
                "
                modelName="checkout.contact_data.postal_code"
                label="Cap sede legale"
                maxlength="5"
                autocompleteField="section-contact_data postal-code"
              ></linkem-input>
            </div>
          </div>

          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.recipient_code"
                :value="contact_data.recipient_code"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    recipient_code: $event
                  })
                "
                modelName="checkout.contact_data.recipient_code"
                label="Codice destinatario"
                :maxLength="`${inputLength.recipientCodeLength}`"
                autocompleteField="off"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.PEC"
                :value="contact_data.PEC"
                @input="
                  $emit('update:contact_data', { ...contact_data, PEC: $event })
                "
                modelName="checkout.contact_data.PEC"
                label="PEC"
                :maxLength="`${inputLength.emailLength}`"
                autocompleteField="off"
              ></linkem-input>
            </div>
          </div>
          <!-- <div class="justify-center w-full p-4 checkbox">
            <linkem-checkbox
              :errorModel="checkout_errors.is_PA"
              :value="contact_data.is_PA"
              @input="
                $emit('update:contact_data', { ...contact_data, is_PA: $event })
              "
              modelName="checkout.contact_data.is_PA"
              :message="businessMessage"
            ></linkem-checkbox>
          </div>
          <div
            v-if="this.contact_data.is_PA"
            class="flex flex-wrap justify-around"
          >
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.CIG"
                :value="contact_data.CIG"
                @input="
                  $emit('update:contact_data', { ...contact_data, CIG: $event })
                "
                modelName="checkout.contact_data.CIG"
                label="CIG"
                :maxLength="`${inputLength.CIGLength}`"
                autocompleteField="off"
              ></linkem-input>
            </div>
            <div class="justify-center w-full p-4 lg:w-1/2">
              <linkem-input
                :errorModel="checkout_errors.CUP"
                :value="contact_data.CUP"
                @input="
                  $emit('update:contact_data', { ...contact_data, CUP: $event })
                "
                modelName="checkout.contact_data.CUP"
                label="CUP"
                :maxLength="`${inputLength.CUPLength}`"
                autocompleteField="off"
              ></linkem-input>
            </div>
          </div> -->
          <div class="flex flex-wrap justify-around">
            <div class="justify-center w-full p-4 checkbox">
              <linkem-checkbox
                :errorModel="checkout_errors.flag_contacts"
                :value="contact_data.flag_contacts"
                @input="
                  $emit('update:contact_data', {
                    ...contact_data,
                    flag_contacts: $event
                  })
                "
                modelName="checkout.contact_data.flag_contacts"
                :message="checkboxMessage"
              ></linkem-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    contact_data: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    checkboxMessage: {
      type: String,
      required: true
    },
    inputLength: {
      required: true
    },
    prefixes: {
      required: true
    },
    businessMessage: {
      type: String,
      required: true
    },
    colour: {
      default: "arancio"
    },
    otpCheck: Boolean,
    confirmedPhone: Boolean
  },
  data: function() {
    return {
      step_name: "contact_data"
    };
  }
};
</script>
