<template>
  <div
    class="flex flex-col justify-center text-center p-2 h-30 overflow-hidden"
    :class="`text-${color}-primary`"
  >
    <p class="h-5 text-xs">
      <slot />
    </p>
    <p v-if="price" class="price">
      <span class="price__int">{{ currency[0] }}</span>
      <span v-if="currency.length > 1">,{{ currency[1] }}</span
      >€
    </p>
    <p class="text-linkem-grigio text-sm h-5">
      <slot name="monthly" />
    </p>
    <p class="text-linkem-grigio text-sm h-5">
      <slot name="info" />
    </p>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    link: String,
    altreOfferte: Boolean,
    price: String
  },
  computed: {
    dense() {
      return this.altreOfferte || this.$parent.altreOfferte;
    },
    currency() {
      return _.split(this.price, ",");
    }
  }
};
</script>

<style>
.price {
  @apply leading-none;
  @apply text-2xl;
  @apply h-12;
}
.price__int {
  @apply font-bold;
  @apply text-5xl;
}
</style>
