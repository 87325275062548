<template>
  <div>
    <label v-text="label" />
    <select>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.value }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    options: Array,
    disabled: Boolean
  }
};
</script>
