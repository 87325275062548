<template>
  <div
    class="feature__item"
    :class="[
      !teaser
        ? `w-1/2 md:w-1/${perRow} lg:w-1/${perRow} h-12 md:h-20`
        : !single
        ? 'w-1/2 border-b h-12'
        : 'w-full border-b'
    ]"
  >
    <div class="text-3xl flex-initial p-2" :class="`text-${color}-primary`">
      <slot name="icon"></slot>
    </div>
    <div class="flex-1 p-2 text-xs lg:text-sm">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    single: Boolean,
    teaser: Boolean,
    perRow: Number
  }
};
</script>
