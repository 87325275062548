var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap-reverse justify-between"},[_c('div',{staticClass:"justify-center lg:w-48 p-4 w-full"},[(
        !_vm.loading &&
          (_vm.checkoutStep != 'complete' && _vm.checkoutStep != 'contact_data')
      )?_c('a',{staticClass:"shadow-button h-10 mr-auto text-white py-2 block rounded-full uppercase overflow-hidden text-center bg-grigio hover:bg-grigio-lighter",class:{
        disabled: _vm.blockedStep && _vm.checkoutStep == 'contract',
        'pointer-events-none': _vm.blockedStep && _vm.checkoutStep == 'contract',
        'cursor-not-allowed': _vm.blockedStep && _vm.checkoutStep == 'contract',
        'bg-grigio-lighter': _vm.blockedStep && _vm.checkoutStep == 'contract'
      },attrs:{"href":"#","id":"previous-button","tabindex":"0"},on:{"click":function($event){return _vm.validateStepPrevious()}}},[[_vm._v("Indietro")]],2):_vm._e()]),_c('div',{staticClass:"justify-center p-4 w-full",class:_vm.checkoutStep == 'recurring_rid' ? 'lg:w-1/3' : 'lg:w-48'},[(!_vm.loading && _vm.checkoutStep != 'complete')?_c('a',{staticClass:"shadow-button h-10 ml-auto text-white py-2 block rounded-full uppercase overflow-hidden text-center bg-verde hover:bg-verde-lighter",class:{
        disabled: _vm.invalid,
        'pointer-events-none': _vm.invalid,
        'cursor-not-allowed': _vm.invalid,
        'bg-grigio-lighter': _vm.invalid
      },attrs:{"href":"#","id":"forward-button","tabindex":"0"},on:{"click":function($event){return _vm.validateStepForward()}}},[(_vm.checkoutStep == 'contract')?[_vm._v("Acquista")]:(_vm.checkoutStep == 'recurring_rid')?[_vm._v("Conferma e accetta")]:[_vm._v("Avanti")]],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }