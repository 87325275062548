<template>
  <div class="form-group">
    <date-field
      :id="modelName.replace(/\./g, '_')"
      :name="modelName"
      :value="data_value"
      @input="
        data_value = $event;
        $emit('input', $event);
      "
      type="date"
      :title="label"
      :id-name="idName"
      @focus="error_model = ''"
      :autocomplete="autocompleteField"
      @error="error_model = $event"
      class="form-control w-full px-4 bg-gray-200 mx-auto border"
      :class="{ 'border-error': errorModel }"
    ></date-field>
    <font-awesome-icon
      icon="exclamation-triangle"
      v-if="error_model"
      class="text-error"
    ></font-awesome-icon>
    <span
      class="text-error pl-2"
      v-for="(item, index) in error_model"
      :key="index"
      >{{ item }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    modelName: {
      required: true,
      type: String
    },
    label: {
      type: String,
      required: true
    },
    idName: {
      type: String,
      required: true
    },
    errorModel: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocompleteField: {
      type: String,
      default: "off"
    }
  },
  data: function() {
    return {
      error_model: this.errorModel,
      data_value: this.value
    };
  },
  watch: {
    errorModel: function() {
      this.error_model = this.errorModel;
    }
  }
};
</script>
