<template>
  <div class="fixed bottom-0 right-0 mr-2">
    <span
      class="outlined cursor-pointer rounded-full mb-12 h-10 md:h-12 w-10 md:w-12 flex items-center justify-center mx-auto"
      :class="`bg-${colour} hover:bg-${colour}-lighter`"
      @click="openContacts(null)"
    >
      <font-awesome-icon
        class="text-2xl text-white"
        icon="headset"
      ></font-awesome-icon>
    </span>
  </div>
</template>
<script>
export default {
  props: ["colour"],
  data() {
    return {
      olarkIsOpen: false
    };
  },
  methods: {
    // open_chat: function(){
    //   this.olarkIsOpen = true;
    //   olark('api.box.expand');
    // }
  }
};
</script>
