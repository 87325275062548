<template>
  <section :class="`bg-${color}`">
    <div class="container mx-auto p-4">
      <contact-highlight
        v-for="link in bannerLinks"
        :key="link.title"
        v-bind="link"
      ></contact-highlight>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    bannerLinks: Array
  }
};
</script>
