<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit" :class="$attrs._class">
      <div class="px-4 mb-6 md:flex-none md:mb-0">
        <button
          type="submit"
          class="px-8 py-1 overflow-hidden text-sm text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          :disabled="invalid"
        >
          Logout
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    path: String,
    configurationItemId: String,
    antiForgeryToken: String
  },
  methods: {
    async submit() {
      try {
        window.vm.$wait.start("logout");
        await this.$axios.post(
          this.path,
          {
            ConfigurationItemID: this.configurationItemId
          },
          { headers: { RequestVerificationToken: this.antiForgeryToken } }
        );
      } finally {
        window.vm.$wait.end("logout");
        window.location.reload();
      }
    }
  }
};
</script>
