var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step == _vm.step_name)?_c('div',{attrs:{"id":"checkout_contract_form"}},[_c('step-header',_vm._g({attrs:{"title":"Contratto","icon":"credit-card","colour":_vm.colour}},_vm.$listeners)),_c('div',{staticClass:"bg-white rounded-b-lg"},[_c('div',{staticClass:"checkout-form content section-light transition"},[_c('div',{staticClass:"mx-20 mx-auto"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center p-4"},[_c('a',{staticClass:"text-grey my-1 font-bold uppercase",attrs:{"id":"DownloadContract","target":"_blank","href":_vm.infoRoute}},[_c('font-awesome-icon',{class:("text-" + _vm.colour + "-primary"),attrs:{"icon":"file-signature"}}),_vm._v("Scarica info precontrattuali\n            ")],1)])]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"checkbox justify-center w-full p-4"},[_c('linkem-checkbox',{attrs:{"disabled":!_vm.canAccept,"errorModel":_vm.checkout_errors['flag_privacy'],"value":_vm.contract.flag_privacy,"modelName":"checkout.contract.flag_privacy","message":_vm.privacyMessage},on:{"input":function($event){return _vm.$emit('update:contract', Object.assign({}, _vm.contract,
                  {flag_privacy: $event}))}}}),_vm._l((_vm.checkout_errors.flag_privacy_contract),function(item,index){return _c('span',{key:index,staticClass:"text-error"},[_vm._v(_vm._s(item))])})],2)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"checkbox justify-center w-full p-4"},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors['flag_marketing'],"value":_vm.contract.flag_marketing,"modelName":"checkout.contract.flag_marketing","message":_vm.marketingMessage},on:{"input":function($event){return _vm.$emit('update:contract', Object.assign({}, _vm.contract,
                  {flag_marketing: $event}))}}}),_vm._l((_vm.checkout_errors.flag_marketing_contract),function(item,index){return _c('span',{key:index,staticClass:"text-error"},[_vm._v(_vm._s(item))])})],2)]),(_vm.target == 'privati' && _vm.activationEnabled)?_c('div',{staticClass:"p-4 w-full"},[_c('h4',{staticClass:"m-1"},[_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.activationMessages[0]))]),_c('span',{staticClass:"font-bold inline-block"},[_vm._v(" "+_vm._s(_vm.activationMessages[1])),_c('tooltip',{attrs:{"message":_vm.tooltipMessage}})],1)]),_c('div',{staticClass:"form-group m-1"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"flex flex-wrap justify-around lg:w-1/2"},[_c('div',{staticClass:"py-2 w-full justify-center"},[_c('linkem-radio',{attrs:{"value":_vm.contract.is_activation_accepted,"modelName":"checkout.contract.is_activation_accepted","radioValue":true,"message":"Si - L'attivazione sarà più veloce","contractMethod":true},on:{"input":function($event){return _vm.$emit('update:contract', Object.assign({}, _vm.contract,
                        {is_activation_accepted: $event}))}}})],1),_c('div',{staticClass:"py-2 w-full justify-center"},[_c('linkem-radio',{attrs:{"value":_vm.contract.is_activation_accepted,"modelName":"checkout.contract.is_activation_accepted","radioValue":false,"message":"No - L'attivazione sarà più lenta","contractMethod":true},on:{"input":function($event){return _vm.$emit('update:contract', Object.assign({}, _vm.contract,
                        {is_activation_accepted: $event}))}}})],1)]),_vm._l((_vm.checkout_errors.is_activation_accepted),function(item,index){return _c('span',{key:index,staticClass:"text-error"},[_vm._v(_vm._s(item))])})],2)])]):_vm._e(),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center p-4"},[_c('div',{class:{ 'cursor-not-allowed': !_vm.canDownload }},[_c('a',{staticClass:"text-grey my-1 font-bold uppercase",class:{
                  ' pointer-events-none text-gray-500': !_vm.canDownload
                },attrs:{"id":"DownloadContract","target":"_blank","href":_vm.pdfRoute + (_vm.contract.flag_marketing || _vm.contract.is_activation_accepted ? '?marketing=' + (_vm.contract.flag_marketing || 'false') + '&acceptance=' + (_vm.contract.is_activation_accepted || 'false') : '')},on:{"click":_vm.downloaded}},[_c('font-awesome-icon',{class:[
                    _vm.canDownload ? ("text-" + _vm.colour + "-primary") : 'text-gray-500'
                  ],attrs:{"icon":"file-signature"}}),_vm._v("Scarica contratto\n              ")],1)])])]),_c('ValidationProvider',{attrs:{"immediate":"","name":"downloadedContract","rules":{ required: { allowFalse: false } }}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],domProps:{"value":_vm.downloadedContract}})]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"checkbox justify-center w-full p-4"},[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.resetMessages[0])}}),_c('span',{staticClass:"cursor-pointer underline",domProps:{"textContent":_vm._s(' qui')},on:{"click":_vm.reset}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.resetMessages[1])}})])])]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"checkbox justify-center w-full p-4"},[_c('ValidationProvider',{attrs:{"immediate":"","name":"terms","rules":{ required: { allowFalse: false } }}},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors['flag_terms'],"value":_vm.contract.flag_terms,"modelName":"checkout.contract.flag_terms","message":_vm.termsMessage},on:{"input":function($event){return _vm.$emit('update:contract', Object.assign({}, _vm.contract,
                    {flag_terms: $event}))}}}),_vm._l((_vm.checkout_errors.flag_terms),function(item,index){return _c('span',{key:index,staticClass:"text-error"},[_vm._v(_vm._s(item))])})],2)],1)]),_vm._m(0)],1)])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap p-4 pt-0"},[_c('span',[_vm._v(" * campo obbligatorio ")])])}]

export { render, staticRenderFns }