var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-none w-full justify-center p-4",class:_vm.width},[_c('div',{staticClass:"teaser__wrapper",class:{ 'teaser__wrapper--single': _vm.single }},[(!_vm.dense)?_c('div',{staticClass:"teaser__image",class:[
        { 'teaser__image--single': _vm.single },
        { 'teaser__image--simple': _vm.simple }
      ],style:(("background-image: url(" + (require('@/assets/images/' + _vm.picture)) + ")"))}):_vm._e(),(!_vm.simple && _vm.perRow != 1)?_c('div',{class:[
        _vm.high
          ? ("teaser__text--high text-" + _vm.color + "-primary")
          : ("teaser__text text-" + _vm.color + "-primary")
      ]},[_c('div',{staticClass:"leading-none mb-2 text-4-5xl",class:("text-" + _vm.color + "-primary")},[_vm._t("icon")],2),_c('p',{staticClass:"font-bold text-xl uppercase leading-none"},[_vm._t("title")],2),_c('p',{staticClass:"text-md lg:text-lg xl:text-xl px-2 capitalize"},[_vm._t("subtitle")],2)]):_vm._e(),(_vm.simple)?_c('div',{staticClass:"teaser__body text-center p-4"},[_c('div',{staticClass:"leading-none mb-4 text-4-5xl",class:("text-" + _vm.color + "-secondary")},[_vm._t("icon")],2),_c('h3',{staticClass:"font-bold text-2xl",class:("text-" + _vm.color + "-secondary")},[_vm._t("title")],2),_c('p',{},[_vm._t("content")],2)]):_vm._e(),(!_vm.dense || !_vm.simple)?_c('div',{staticClass:"teaser__features",class:{ 'teaser__features--single': _vm.single }},[_vm._t("features")],2):_vm._e(),_c('div',{class:{ 'teaser__price--single': _vm.single, 'mt-auto': !_vm.single }},[(_vm.perRow == 1)?_c('div',{staticClass:"flex flex-col text-center justify-center h-30 overflow-hidden p-2",class:("text-" + _vm.color + "-primary")},[_c('div',{staticClass:"leading-none mb-2 text-4-5xl",class:("text-" + _vm.color + "-primary")},[_vm._t("icon")],2),_c('p',{staticClass:"font-bold text-xl uppercase leading-none"},[_vm._t("title")],2),_c('p',{staticClass:"text-md lg:text-lg xl:text-xl px-2"},[_vm._t("subtitle")],2)]):_vm._e(),_vm._t("price"),_c('div',{staticClass:"flex pb-4"},[_vm._t("button")],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }