<template>
  <div id="checkout_recurring_rid_form" v-if="step == step_name">
    <step-header
      title="Grazie!"
      icon="thumbs-up"
      :colour="colour"
      v-on="$listeners"
    ></step-header>
    <div class="bg-white rounded-b-lg">
      <div class="checkout-form content section-light transition">
        <div class="mx-auto mx-20">
          <div class="flex flex-wrap justify-around">
            <div class="p-4 w-full justify-center">
              <h2 class="text-center">Ordine completato con successo!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    },
    colour: {
      default: "arancio"
    }
  },
  data: function() {
    return {
      step_name: "complete"
    };
  }
};
</script>
