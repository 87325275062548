<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit" :class="$attrs._class">
      <div class="flex-auto w-full px-4 mb-6 md:w-auto md:mb-0">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <input
            v-model="username"
            class="w-full px-4 py-2 mb-2 border"
            type="text"
            :placeholder="usernamePlaceholder"
            :class="{ 'border-error ': !_.isEmpty(errors) }"
          />
          <a
            @click="changeState('recoverUsr')"
            class="block text-xs italic text-center cursor-pointer md:text-left"
            :class="[
              `hover:text-${color}-primary`,
              _.isEmpty(errors) || `text-error`
            ]"
            >{{ forgottenUsernameText }}</a
          >
        </ValidationProvider>
      </div>
      <div class="flex-auto w-full px-4 mb-6 md:w-auto md:mb-0">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <input
            v-model="password"
            class="w-full px-4 py-2 mb-2 border"
            type="password"
            :placeholder="passwordPlaceholder"
            :class="{ 'border-error ': !_.isEmpty(errors) }"
          />
          <a
            @click="changeState('recoverPwd')"
            class="block text-xs italic text-center cursor-pointer md:text-left"
            :class="[
              `hover:text-${color}-primary`,
              _.isEmpty(errors) || `text-error`
            ]"
            >{{ forgottenPasswordText }}</a
          >
        </ValidationProvider>
      </div>
      <div class="w-full px-4 mb-6 text-center md:flex-none md:mb-0 md:w-auto">
        <label
          class="flex items-baseline block w-full font-bold text-gray-500 whitespace-no-wrap md:w-2/3"
        >
          <input
            v-model="rememberMe"
            class="mr-2 leading-tight"
            type="checkbox"
          />
          <span class="text-sm">{{ rememberMeText }}</span>
        </label>
      </div>
      <div class="px-4 mb-2 md:flex-none md:mb-0">
        <button
          type="submit"
          class="px-8 py-1 overflow-hidden text-sm text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          :disabled="invalid"
        >
          {{ loginButtonText }}
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  data: () => ({
    username: String(),
    password: String(),
    rememberMe: Boolean()
  }),
  props: {
    path: String,
    retentionDays: {
      type: Number,
      default: 30
    },
    configurationItemId: String,
    antiForgeryToken: String,

    usernamePlaceholder: { type: String, default: "Username" },
    passwordPlaceholder: { type: String, default: "Password" },
    forgottenUsernameText: {
      type: String,
      default: "non ricordi il tuo username?"
    },
    forgottenPasswordText: {
      type: String,
      default: "hai dimenticato la tua password?"
    },
    rememberMeText: { type: String, default: "Ricordami per 30 giorni" },
    loginButtonText: { type: String, default: "Login" },
    maxAuthFailedAttempts: { type: Number, default: 5 },
    recaptchaIsEnabled: { type: Boolean, default: true },
    changePasswordUrl: { type: String },
    redirectUrl: { type: String }
  },
  methods: {
    changeState(step) {
      this.$emit("update:step", step);
    },
    async submit() {
      try {
        window.vm.$wait.start("login");
        await this.$axios.post(
          this.path,
          {
            username: this.username,
            password: this.password,
            rememberme: this.rememberMe,
            retentionDays: this.retentionDays,
            ConfigurationItemID: this.configurationItemId
          },
          { headers: { RequestVerificationToken: this.antiForgeryToken } }
        );

        this.redirectUrl
          ? (window.location.href = this.redirectUrl)
          : window.location.reload();
      } catch (error) {
        if (_.get(error, "response.status") === 403) {
          window.location.href = this.changePasswordUrl;
        } else if (
          _.get(error, "response.data.AuthFailedAttempts") >=
            this.maxAuthFailedAttempts &&
          this.recaptchaIsEnabled
        ) {
          this.changeState("captcha");
        }
      } finally {
        window.vm.$wait.end("login");
      }
    }
  }
};
</script>
