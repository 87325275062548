<template>
  <video
    autoplay="autoplay"
    playsinline=""
    loop="loop"
    muted="muted"
    class="video-banner video-banner-mobile"
  >
    <slot name="source" />
  </video>
</template>
<script>
export default {
  props: {
    video: String
  }
};
</script>
<style>
.video-banner {
  object-fit: cover;
  width: 1920px;
  height: 450px;
  object-position: center;
}

.video-banner-mobile {
  object-fit: cover;
  width: 1920px;
  height: 450px;
  object-position: center;
}

@media (max-width: 1280px) {
  .video-banner {
    object-fit: cover;
    width: 1920px;
    height: 290px;
    object-position: center;
  }
}

@media (max-width: 640px) {
  .video-banner {
    object-fit: cover;
    width: 1920px;
    height: 145px;
    object-position: center;
  }

  .video-banner-mobile {
    object-fit: cover;
    width: 1920px;
    height: 450px;
    object-position: center;
  }
}
</style>
