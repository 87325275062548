var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'transitionpx-2 ' +
      (_vm.selComposition ? ("text-" + _vm.colour + "-primary") : 'text-gray-600') +
      ' bg-white rounded',attrs:{"id":'cart-' + this.kitType + '-composition-' + _vm.comp.id}},[_c('form',{attrs:{"id":'form-kits-' + _vm.comp.id}},[_c('div',{staticClass:"flex flex-wrap justify-around py-4 bg-white"},[_c('div',{staticClass:"justify-center w-1/12 px-4",on:{"click":function($event){_vm.showPanel = !_vm.showPanel}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.showPanel ? 'minus' : 'plus'}})],1),_c('div',{staticClass:"justify-center w-1/2 cursor-pointer sm:w-5/12",on:{"click":function($event){return _vm.submit()}}},[_c('span',{staticClass:"py-2 font-bold"},[_vm._v(_vm._s(_vm.comp.kitName))])]),_c('div',{staticClass:"justify-center w-1/4 cursor-pointer sm:w-5/12",on:{"click":function($event){return _vm.submit()}}},[(_vm.comp.upfrontKitPrice > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.comp.upfrontKitPrice,"€ ", 2, {
              decimalSeparator: ",",
              symbolOnLeft: false,
              spaceBetweenAmountAndSymbol: true
            })))]):_vm._e(),(_vm.comp.recurringKitPrice > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.comp.recurringKitPrice,"€ ", 2, {
                decimalSeparator: ",",
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true
              }))+"\n          / mese")]):_vm._e()]),_c('div',{staticClass:"justify-center w-1/6 text-center cursor-pointer sm:w-1/12",on:{"click":function($event){return _vm.submit()}}},[(this.kitType == 'optional')?_c('font-awesome-icon',{attrs:{"icon":_vm.selComposition ? 'check-square' : 'square'}}):_vm._e(),(this.kitType == 'mandatory')?_c('font-awesome-icon',{attrs:{"icon":_vm.selComposition ? 'check-circle' : 'circle'}}):_vm._e()],1),(_vm.showPanel)?_c('div',{staticClass:"overflow-hidden",attrs:{"id":((_vm.comp.id) + "-active")}},[_c('div',{staticClass:"pt-4"},[_c('p',{staticClass:"px-4",domProps:{"innerHTML":_vm._s(_vm.comp.kitDescription)}})])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }