<template>
  <ValidationObserver v-slot="{ invalid, validate }">
    <div
      v-if="coverage && coverage.message"
      v-html="coverage.message"
      class="container flex-initial mb-4 text-center"
    ></div>
    <div
      v-if="leaveContactMessage"
      v-html="leaveContactMessage"
      class="container flex-initial mt-2 mb-4 text-center"
    ></div>
    <form
      @submit.prevent="
        validate().then(valid => {
          !valid || submit();
        })
      "
      class="flex flex-row flex-wrap p-4 -mx-4"
      :class="$attrs._class"
    >
      <div
        v-for="field in _.filter(
          form,
          v => !v.target || _.lowerCase(v.target) === _.lowerCase(target)
        )"
        :key="field.name"
        class="mb-6 md:mb-0 mx-auto px-4 w-full sm:w-1/2"
      >
        <validator v-bind="field">
          <template #default="{errors}">
            <template v-if="field.type === 'checkbox'">
              <label class="flex items-baseline flex-1 w-full">
                <input
                  v-model="field.value"
                  class="mr-2 leading-tight"
                  type="checkbox"
                />
                <span
                  v-if="field.name === 'flag_contacts'"
                  v-html="privacyMsg"
                ></span>
              </label>
            </template>
            <input
              v-else
              v-model="field.value"
              class="border mb-2 px-4 py-2 w-full"
              :type="field.type"
              :placeholder="field.label"
              :class="{ 'border-error ': errors }"
            />
          </template>
        </validator>
      </div>
      <div class="flex-auto w-full px-4 mb-6 md:mb-0" >
        <span v-html="privacyMsg"></span>
      </div>
      <div class="hidden sm:flex justify-center w-full p-4 mb-6 md:mb-0">
        <div v-if="leaveUrl" class="flex justify-center w-full">
          <a
            class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
            :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
            :href="leaveUrl"
          >
            Esci dal carrello
          </a>
        </div>
        <div class="flex justify-center w-full">
          <button
            type="submit"
            class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
            :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
            :disabled="invalid"
          >
            {{ submitButtonLabel }}
          </button>
        </div>
      </div>
      <div class="flex justify-center w-full sm:hidden">
        <button
          type="submit"
          class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          :disabled="invalid"
        >
          {{ submitButtonLabel }}
        </button>
      </div>
      <div v-if="leaveUrl" class="flex justify-center w-full p-4 mb-6 md:mb-0 sm:hidden">
        <a
          class="h-10 px-4 py-2 mx-auto overflow-hidden text-center text-white uppercase whitespace-no-wrap rounded-full cursor-pointer shadow-button disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[`bg-${color}`, `hover:bg-${color}-lighter`]"
          :href="leaveUrl"
        >
          Esci dal carrello
        </a>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    //origin: String,
    form: [
      // {
      //   name: "company_name",
      //   value: String(),
      //   validation: { required: true },
      //   label: "Ragione sociale",
      //   type: "text",
      //   target: "business"
      // },
      // {
      //   name: "VAT_number",
      //   value: String(),
      //   validation: { required: true },
      //   label: "P. Iva",
      //   type: "text",
      //   target: "business"
      // },
      // {
      //   name: "name",
      //   value: String(),
      //   validation: { required: true, regex: /^[A-Za-z àèéìòù']+$/, max: 50 },
      //   label: "Nome",
      //   type: "text"
      // },
      // {
      //   name: "last_name",
      //   value: String(),
      //   validation: { required: true, regex: /^[A-Za-z àèéìòù']+$/, max: 50 },
      //   label: "Cognome",
      //   type: "text"
      // },
      // {
      //   name: "email",
      //   value: String(),
      //   validation: { required: true, email: true },
      //   label: "E-mail",
      //   type: "email"
      // },
      {
        name: "mobile_phone",
        value: String(),
        validation: { required: true, min: 9, max: 10, regex: /^3[\d]+$/ },
        label: "Cellulare",
        type: "text"
      }
      // {
      //   name: "flag_contacts",
      //   value: Boolean(),
      //   validation: { required: { allowFalse: false } },
      //   label: "privacy",
      //   type: "checkbox"
      // }
      //   {
      //     name: "telephone",
      //     value: String(),
      //     validation: {required: true, min:9, max:10, regex:"/^3[d]+$"},
      //     label: "Telefono",
      //     type: "input"
      //   },
    ]
  }),
  inheritAttrs: false,
  props: {
    target: {
      type: String,
      default: "privati"
    },
    coverage: Object,
    privacyMsg: String,
    contactsApi: String,
    leaveContactMessage: { type: String },
    leavecontactNameLabel: { type: String, default: "Nome" },
    leavecontactLastnameLabel: { type: String, default: "Cognome" },
    leavecontactEmailLabel: { type: String, default: "E-mail" },
    leavecontactPhoneLabel: { type: String, default: "Cellulare" },
    submitButtonLabel: { type: String, default: "Lascia Contatti" },
    leaveUrl: { type: String, default: null },
    origin: { type: String, default: "LasciaContatti" },
    leadSuccessMessage: { type: String, default: null }
  },
  computed: {
    url() {
      return window.location.origin;
    },
    mappedForm() {
      return _.map(this.form, i => {
        switch (i.name) {
          case "name":
            i.label = this.leavecontactNameLabel;
            break;
          case "last_name":
            i.label = this.leavecontactLastnameLabel;
            break;
          case "email":
            i.label = this.leavecontactEmailLabel;
            break;
          case "mobile_phone":
            i.label = this.leavecontactPhoneLabel;
            break;
        }
        return i;
      });
    },
    submitForm() {
      return _.merge(
        _.mapValues(_.mapKeys(this.form, v => v.name), v => v.value),
        { coverage: this.coverage },
        { target: this.target },
        { origin: this.origin },
        { url: this.url }
      );
    },
    color() {
      return this.sectionColor || this.$$section.color;
    }
  },
  methods: {
    async submit() {
      try {
        window.vm.$wait.start("contact");
        await this.$axios
          .post(this.contactsApi, this.submitForm)
          .then(response => {
            this.$events.emit("toggleDrawer", [
              "Richiesta inviata con successo",
              "thanks",
              { sectionColor: this.color, leadSuccessMessage: this.leadSuccessMessage }
            ]);
          });
                      // window.vm.$root.$contact.open(
            //   "Richiesta inviata",
            //   this.leadSuccessMessage,
            //   {
            //     type: "success"
            //   }
            // );
            // this.$emit("close");
            
      } finally {
        window.vm.$wait.end("contact");
      }
    }
  }
};
</script>
