<template>
  <section>
    <div
      v-if="$scopedSlots.title"
      class="text-center p-4"
      :class="`text-${color}-primary`"
    >
      <div class="block text-2xl">
        <slot name="title"></slot>
      </div>
    </div>
    <swiper :options="swiperOption" ref="theSwiper">
      <!-- slides -->
      <slot />
      <!-- Optional controls -->
      <div
        class="swiper-pagination"
        :class="[
          inset
            ? 'dark swiper-pagination-clickable swiper-pagination-bullets'
            : '',
          outlinebullet ? 'dark' : '',
          textColor,
          `instance-${_uid}`
        ]"
        slot="pagination"
      ></div>
      <!-- If we need navigation buttons -->
      <template v-if="navigation">
        <div
          class="swiper-button-prev flex"
          slot="button-prev"
          :class="[
            `text-${color}-primary instance-${_uid}`,
            navigationMobile ? 'sm:hidden' : ''
          ]"
        >
          <font-awesome-icon icon="chevron-left" size="2x"></font-awesome-icon>
        </div>
        <div
          class="swiper-button-next flex"
          slot="button-next"
          :class="[
            `text-${color}-primary instance-${_uid}`,
            navigationMobile ? 'sm:hidden' : ''
          ]"
        >
          <font-awesome-icon icon="chevron-right" size="2x"></font-awesome-icon>
        </div>
      </template>
    </swiper>
  </section>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {};
  },
  props: {
    outlinebullet: Boolean,
    photogallery: Boolean,
    inset: Boolean,
    pagination: Boolean,
    navigation: Boolean,
    navigationMobile: Boolean,
    slidesPerView: { default: 1 },
    slidesXs: {
      type: Number,
      default: 1
    },
    slidesSm: {
      type: Number,
      default: 2
    },
    loop: Boolean,
    autoplay: Boolean,
    speed: { default: 3000 }
  },
  computed: {
    swiper() {
      return this.$refs.theSwiper.swiper;
    },
    textColor() {
      return `text-${this.color}-primary`;
    },
    swiperOption() {
      return {
        slidesPerView: this.slidesPerView,
        slidesXs: this.slidesXs,
        slidesSm: this.slidesSm,
        loop: this.loop,
        autoplay: this.autoplay
          ? {
              delay: this.speed,
              disableOnInteraction: false
            }
          : false,
        speed: this.speed,
        pagination: this.pagination
          ? {
              el: `.instance-${this._uid}.swiper-pagination`,
              type: "bullets",
              clickable: "true"
            }
          : false,
        navigation: this.navigation
          ? {
              nextEl: `.instance-${this._uid}.swiper-button-next`,
              prevEl: `.instance-${this._uid}.swiper-button-prev`
            }
          : false,
        breakpoints: {
          640: {
            slidesPerView: this.slidesXs
          },
          768: {
            slidesPerView: this.slidesSm
          },
          1024: {
            slidesPerView: _.round(_.divide(this.slidesPerView, 2))
          }
        }
      };
    }
  },
  created() {}
};
</script>

<style>
/* TODO: item stretch */
.swiper-container {
  @apply mx-auto;
  @apply relative;
  @apply overflow-hidden;
  @apply list-none;
  @apply p-0;
  @apply z-10;
}

.swiper-wrapper {
  @apply relative;
  @apply w-full;
  /* @apply h-full; */
  @apply z-10;
  @apply flex;
}

.swiper-slide {
  @apply flex-shrink-0;
  @apply relative;
  @apply w-full;
  @apply h-auto;
  @apply flex;
  @apply justify-center;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  @apply bottom-0;
  @apply left-0;
  @apply w-full;
}

.swiper-pagination {
  @apply text-center;
  @apply absolute;
  @apply z-20;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  @apply mx-1;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  @apply cursor-pointer;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply opacity-100;
}

.dark .swiper-pagination-bullet {
  @apply bg-white;
  @apply border-2;
}

.swiper-pagination-bullet {
  @apply w-4;
  @apply h-4;
  @apply inset-y-0;
  @apply inline-block;
  @apply rounded-full;
  @apply bg-text;
  @apply opacity-50;
  @apply border-text;
}

.swiper-button-next,
.swiper-button-prev {
  @apply absolute;
  @apply inset-y-0;
  @apply cursor-pointer;
  @apply z-10;
  @apply items-center;
}
.swiper-button-next {
  @apply right-0;
}
.swiper-button-disabled {
  @apply hidden;
}
</style>
