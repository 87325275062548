<template>
  <li
    class="cursor-pointer"
    :class="{ 'font-extrabold': active, headertop: headertop, navbar: navbar }"
    @mouseenter="
      $scopedSlots.submenu && $breakpoint.smAndUp ? (expand = true) : null
    "
    @mouseleave="
      $scopedSlots.submenu && $breakpoint.smAndUp ? (expand = false) : null
    "
  >
    <a
      class="group relative block py-3 px-4"
      :class="{ submenu: submenu || navbar }"
      @click="
        $scopedSlots.submenu && $breakpoint.xsOnly ? (expand = !expand) : null
      "
      :href="link"
    >
      <span
        :class="[
          submenu
            ? `flex items-center group-hover:text-${color}-primary capitalize`
            : '',
          flex ? 'flex sm:block flex-row justify-between' : ''
        ]"
      >
        <slot name="icon"> </slot>
        <slot />
      </span>
    </a>
    <transition-expand v-if="$scopedSlots.submenu">
      <div
        v-show="expand"
        class="text-linkem-grigio sm:absolute sm:h-12 bg-white w-full left-0 sm:shadow-under  sm:pr-20"
      >
        <ul class="container mx-auto sm:flex sm:justify-end">
          <slot name="submenu"></slot>
        </ul>
      </div>
    </transition-expand>
  </li>
</template>

<script>
export default {
  data: () => ({
    expand: false
  }),
  props: {
    //active: Boolean,
    link: String,
    headertop: Boolean,
    navbar: Boolean,
    submenu: Boolean,
    flex: Boolean
  },
  computed: {
    active() {
      return this.link === location.pathname && this.headertop;
    }
  }
};
</script>

<style>
.headertop {
  @apply border-b-6;
  @apply border-transparent;
}
.headertop:hover {
  @apply border-text;
}

@screen sm {
  .navbar {
    @apply border-b-6;
    @apply border-transparent;
  }

  .navbar:hover {
    @apply border-text;
  }
}

.submenu {
  @apply border-b;
}
@screen sm {
  .submenu {
    @apply border-b-0;
  }
}
</style>
