<template>
  <div class="form-group w-full">
    <input
      :id="modelName.replace(/\./g, '_')"
      :name="modelName"
      :maxlength="maxLength"
      v-on:keyup="error_model = ''"
      :disabled="disabled"
      class="form-control w-full py-2 px-4 bg-gray-200 mx-auto border placeholder-gray-600"
      :class="{ 'border-error': errorModel }"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="label"
      :title="label"
      :autocomplete="autocompleteField"
    />
    <font-awesome-icon
      icon="exclamation-triangle"
      v-if="error_model"
      class="text-error"
    ></font-awesome-icon>
    <span
      class="text-error pl-2"
      v-html="item"
      v-for="(item, index) in error_model"
      :key="index"
      >{{ item }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    modelName: {
      type: String,
      required: true
    },
    errorModel: {
      required: true,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: "50"
    },
    autocompleteField: {
      type: String,
      default: "off"
    }
  },
  data: function() {
    return {
      error_model: this.errorModel,
      data_value: this.value
    };
  },
  watch: {
    errorModel: function() {
      this.error_model = this.errorModel;
    },
    value: function() {
      this.data_value = this.value;
    }
  }
};
</script>
