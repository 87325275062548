var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step == _vm.step_name)?_c('div',{attrs:{"id":"checkout_contact_data_form"}},[_c('step-header',_vm._g({attrs:{"title":"Dati di contatto","icon":"contact-data","colour":_vm.colour}},_vm.$listeners)),_c('div',{staticClass:"bg-white rounded-b-lg"},[_c('div',{staticClass:"transition checkout-form content section-light"},[_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.company_name,"value":_vm.contact_data.company_name,"modelName":"checkout.contact_data.company_name","label":"Ragione sociale","maxLength":("" + (_vm.inputLength.companyNameLength)),"autocompleteField":"section-contact_data given-name"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {company_name: $event}))}}})],1)]),_c('form-email',{attrs:{"modelName":"checkout.contact_data.email","maxLength":_vm.inputLength.emailLength,"autocompleteField":"section-contact_data email","confirm-email":_vm.contact_data.confirmEmail},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {email: $event}))},"update:confirm-email":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {confirmEmail: $event}))}},model:{value:(_vm.contact_data.email),callback:function ($$v) {_vm.$set(_vm.contact_data, "email", $$v)},expression:"contact_data.email"}}),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.VAT_number,"value":_vm.contact_data.VAT_number,"modelName":"checkout.contact_data.VAT_number","label":"Partita IVA","maxLength":("" + (_vm.inputLength.vatLength)),"autocompleteField":"on"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {VAT_number: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.fiscal_code,"value":_vm.contact_data.fiscal_code,"modelName":"checkout.contact_data.fiscal_code","label":"Codice fiscale","maxLength":("" + (_vm.inputLength.taxCodeLength)),"autocompleteField":"on"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {fiscal_code: $event}))}}})],1)]),_c('form-cellphone',{attrs:{"prefixes":_vm.prefixes,"prefix-error-model":_vm.checkout_errors.international_prefix,"prefix":_vm.contact_data.international_prefix,"phone-error-model":_vm.checkout_errors.mobile_phone,"phone":_vm.contact_data.mobile_phone,"confirmed-phone":_vm.confirmedPhone,"otp-check":_vm.otpCheck,"max-length":_vm.inputLength.mobileLength,"section-color":_vm.colour},on:{"update:confirmed-phone":function($event){return _vm.$emit('update:confirmed-phone', $event)},"update:prefix":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {international_prefix: $event}))},"update:phone":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
              {mobile_phone: $event}))}}}),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-full"},[_c('address-autocomplete',{attrs:{"error-model":_vm.checkout_errors.route,"value":_vm.contact_data,"modelName":"contact_data_google","label":"Inserire l'indirizzo completo"},on:{"google-input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data, $event))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-3/4"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.route,"value":_vm.contact_data.route,"modelName":"checkout.contact_data.route","label":"Indirizzo sede legale","disabled":true},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {route: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/4"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.street_number,"value":_vm.contact_data.street_number,"modelName":"checkout.contact_data.street_number","label":"Civico sede legale","maxLength":("" + (_vm.inputLength.streetNumberLength)),"autocompleteField":"section-contact_data address-line2"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {street_number: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/3"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.administrative_area,"value":_vm.contact_data.administrative_area,"modelName":"checkout.contact_data.administrative_area","label":"Provincia sede legale","maxlength":"2","disabled":true},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {administrative_area: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/3"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.city,"value":_vm.contact_data.city,"modelName":"checkout.contact_data.city","label":"Comune sede legale","disabled":true},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {city: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/3"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.postal_code,"value":_vm.contact_data.postal_code,"modelName":"checkout.contact_data.postal_code","label":"Cap sede legale","maxlength":"5","autocompleteField":"section-contact_data postal-code"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {postal_code: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.recipient_code,"value":_vm.contact_data.recipient_code,"modelName":"checkout.contact_data.recipient_code","label":"Codice destinatario","maxLength":("" + (_vm.inputLength.recipientCodeLength)),"autocompleteField":"off"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {recipient_code: $event}))}}})],1),_c('div',{staticClass:"justify-center w-full p-4 lg:w-1/2"},[_c('linkem-input',{attrs:{"errorModel":_vm.checkout_errors.PEC,"value":_vm.contact_data.PEC,"modelName":"checkout.contact_data.PEC","label":"PEC","maxLength":("" + (_vm.inputLength.emailLength)),"autocompleteField":"off"},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data, {PEC: $event}))}}})],1)]),_c('div',{staticClass:"flex flex-wrap justify-around"},[_c('div',{staticClass:"justify-center w-full p-4 checkbox"},[_c('linkem-checkbox',{attrs:{"errorModel":_vm.checkout_errors.flag_contacts,"value":_vm.contact_data.flag_contacts,"modelName":"checkout.contact_data.flag_contacts","message":_vm.checkboxMessage},on:{"input":function($event){return _vm.$emit('update:contact_data', Object.assign({}, _vm.contact_data,
                  {flag_contacts: $event}))}}})],1)])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }