<template>
  <div class="px-4 py-2 text-white rounded-t-lg" :class="`bg-${colour}`">
    <h3 class="no-margin padding-10 color-gray-alt cursor-pointer">
      <span class="icon-container">
        <icon-comp v-if="customIcon" :icon-name="icon" class="margin-0-4" />
        <font-awesome-icon
          v-else
          class="margin-0-4"
          :icon="icon"
        ></font-awesome-icon>
      </span>
      {{ title }}
    </h3>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    customIcon: Boolean,
    colour: {
      default: "arancio"
    }
  }
};
</script>
