<template>
  <a
    :href="href"
    class="h-8 img__wrapper sm:bg-white sm:p-4 sm:h-full sm:inline-block"
  >
    <img
      height="auto"
      width="auto"
      :src="require(`@/assets/images/${src}`)"
      :alt="alt"
    />
  </a>
</template>
<script>
export default {
  props: {
    href: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  }
};
</script>
