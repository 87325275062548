import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from "vee-validate/dist/vee-validate.full";
import it from "vee-validate/dist/locale/it.json";
import {required} from "vee-validate/dist/rules";

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Add a rule.

extend('required', {
  ...required,
  message: 'Il campo {_field_} è obbligatorio'
});

extend("otpDigits", {
  validate: value =>
    value.match(
      /^[0-9]{6}$/i
    ),
  message: "Il codice di conferma deve essere un valore numerico di 6 cifre"
});

extend("cf", {
  validate: value =>
    value.match(
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    ),
  message: "Codice fiscale non valido"
});

extend("cell", {
  validate: value => value.match(/^3[\d]{8,9}$/),
  message: "Inserire un numero di telefono cellulare valido"
});
// /^[1-9]\d{5,9}/

localize({
  it: {
    messages: it.messages
  }
});
localize("it");
