<template>
  <div>
    <div class="mx-auto">
      <div class="flex flex-wrap justify-around">
        <div class="justify-center w-full p-4">
          <linkem-input
            :errorModel="checkout_errors['shipping_address.care_of']"
            :value="shipping_address.care_of"
            @input="
              $emit('update:shipping_address', {
                ...shipping_address,
                care_of: $event
              })
            "
            :modelName="'checkout.address.shipping_address.care_of'"
            label="Destinatario"
            :autocompleteField="'section-shipping_address care_of name'"
            :disabled="account.billingAddress && type != 'billing_address'"
            :maxLength="`${inputLength.careOfLength}`"
          ></linkem-input>
        </div>
      </div>
      <div
        class="flex flex-wrap justify-around"
        v-if="!account.billingAddress || type != 'billing'"
      >
        <div class="justify-center w-full p-4">
          <address-autocomplete
            :errorModel="checkout_errors['shipping_address.route']"
            :value="shipping_address"
            @google-input="
              $emit('update:shipping_address', {
                ...shipping_address,
                ...$event
              })
            "
            :google="google"
            :modelName="'checkout.address.shipping_address.route'"
            label="Inserire l'indirizzo completo"
          ></address-autocomplete>
        </div>
      </div>
      <div class="flex flex-wrap justify-around">
        <div class="justify-center w-full p-4 lg:w-3/4">
          <linkem-input
            :errorModel="checkout_errors['shipping_address.route']"
            :value="shipping_address.route"
            @input="
              $emit('update:shipping_address', {
                ...shipping_address,
                route: $event
              })
            "
            :modelName="'checkout.address.shipping_address.route'"
            label="Indirizzo"
            :disabled="true"
          ></linkem-input>
        </div>
        <div class="justify-center w-full p-4 lg:w-1/4">
          <linkem-input
            :errorModel="checkout_errors['shipping_address.street_number']"
            :value="shipping_address.street_number"
            @input="
              $emit('update:shipping_address', {
                ...shipping_address,
                street_number: $event
              })
            "
            :modelName="'checkout.address.shipping_address.street_number'"
            label="Numero civico"
            :disabled="account.billingAddress && type != 'billing_address'"
            :autocompleteField="'section-shipping_address address-line2'"
            :maxLength="`${inputLength.streetNumberLength}`"
          ></linkem-input>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-around">
      <div class="justify-center w-full p-4 lg:w-1/3">
        <linkem-input
          :errorModel="checkout_errors['shipping_address.city']"
          :value="shipping_address.city"
          @input="
            $emit('update:shipping_address', {
              ...shipping_address,
              city: $event
            })
          "
          :modelName="'checkout.address.shipping_address.city'"
          label="Comune"
          :disabled="true"
          :autocompleteField="'section-shipping_address locality'"
        ></linkem-input>
      </div>
      <div class="justify-center w-full p-4 lg:w-1/3">
        <linkem-input
          :errorModel="checkout_errors['shipping_address.postal_code']"
          :value="shipping_address.postal_code"
          @input="
            $emit('update:shipping_address', {
              ...shipping_address,
              postal_code: $event
            })
          "
          :modelName="'checkout.address.shipping_address.postal_code'"
          label="CAP"
          :disabled="account.billingAddress && type != 'billing_address'"
          :autocompleteField="'section-shipping_address postal-code'"
          :maxLength="'5'"
        ></linkem-input>
      </div>
      <div class="justify-center w-full p-4 lg:w-1/3"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    shipping_address: {
      required: true
    },
    checkout_errors: {
      required: true
    },
    inputLength: {
      required: true
    },
    account: {
      required: true
    },
    google: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      step_name: "address"
    };
  }
};
</script>
