<script>
// // import VueSimpleSuggest from "vue-simple-suggest";
// // require("vue-simple-suggest/lib/polyfills");
// const VueSimpleSuggest = require("vue-simple-suggest");

// import { hasKeyCodeByCode, hasKeyCode } from "vue-simple-suggest/lib/misc";

// export default {
//   extends: VueSimpleSuggest,
//   methods: {
//     onKeyDown(e) {
//       const select = this.controlScheme.select,
//         hideList = this.controlScheme.hideList;
//       // prevent form submit on keydown if Enter key registered in the keyup list
//       if (
//         e.key === "Enter" &&
//         this.listShown &&
//         hasKeyCodeByCode([select, hideList], 13)
//       ) {
//         e.preventDefault();
//       }
//       if (e.key === "Tab" && this.hovered) {
//         this.select(this.hovered);
//       }
//       this.onShowList(e);
//       this.moveSelection(e);
//       this.onAutocomplete(e);
//     },
//     onShowList(e) {
//       if (hasKeyCode(this.controlScheme.showList, e)) {
//         this.showSuggestions();
//       }
//     }
//   }
// };
//
</script>
