import Vue from "vue";
import VueTailwind from "vue-tailwind";

// Or why not? define the settings inline:
Vue.use(VueTailwind, {
  TRichSelect: {
    fixedClasses: {
      wrapper: "relative w-full",
      buttonWrapper: "inline-block relative w-full",
      selectButton: "w-full flex text-left justify-between items-center",
      selectButtonLabel: "block truncate",
      selectButtonPlaceholder: "block truncate",
      selectButtonIcon: "hidden",
      selectButtonClearButton:
        "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
      selectButtonClearIcon: "fill-current h-3 w-3",
      dropdown: "absolute w-full z-10 top-0",
      dropdownFeedback: "",
      loadingMoreResults: "",
      optionsList: "overflow-auto",
      searchWrapper: "inline-block w-full ",
      searchBox: "inline-block w-full",
      optgroup: "",
      option: "",
      highlightedOption: "",
      selectedOption: "",
      selectedHighlightedOption: "",
      optionContent: "",
      optionLabel: "truncate block",
      selectedIcon: "fill-current h-4 w-4",
      enterClass: "",
      enterActiveClass: "",
      enterToClass: "",
      leaveClass: "",
      leaveActiveClass: "",
      leaveToClass: ""
    },
    classes: {
      wrapper: "",
      buttonWrapper: "",
      selectButton:
        "px-4 py-2 border pac-target-input bg-white p-2 focus:outline-none",
      selectButtonLabel: "",
      selectButtonPlaceholder: "text-gray-500",
      selectButtonIcon: "",
      selectButtonClearButton: "hover:bg-gray-200 text-gray-500 rounded",
      selectButtonClearIcon: "",
      dropdown: "rounded bg-white shadow",
      dropdownFeedback: "text-sm text-gray-500 px-4",
      loadingMoreResults: "text-sm text-gray-500",
      optionsList: "",
      searchWrapper: "bg-white px-4 py-2",
      searchBox: "focus:outline-none",
      optgroup: "text-gray-500 uppercase text-xs py-1 px-2 font-semibold",
      option: "",
      highlightedOption: "bg-gray-300",
      selectedOption: "font-semibold bg-gray-100",
      selectedHighlightedOption: "bg-gray-300 font-semibold",
      optionContent: "flex justify-between items-center p-2 cursor-pointer",
      optionLabel: "truncate block",
      selectedIcon: "",
      enterClass: "",
      enterActiveClass: "opacity-0 transition ease-out duration-100",
      enterToClass: "opacity-100",
      leaveClass: "transition ease-in opacity-100",
      leaveActiveClass: "",
      leaveToClass: "opacity-0 duration-75"
    },
    variants: {
      danger: {
        selectButton:
          "border-red-500 text-red-500 bg-red-100 border rounded p-2 focus:outline-none",
        selectButtonPlaceholder: "text-red-400",
        selectButtonClearButton: "hover:bg-red-200 text-red-500 rounded"
      },
      success: {
        selectButton:
          "border-green-500 text-green-500 bg-green-100 border rounded p-2 focus:outline-none",
        selectButtonPlaceholder: "text-green-400",
        selectButtonClearButton: "hover:bg-green-200 text-green-500 rounded"
      },
      egon: {
        highlightedOption: "pac-item-selected",
        selectedOption: "pac-item-selected"
      },
      egonCheckout: {
        highlightedOption: "pac-item-selected",
        selectedOption: "pac-item-selected",
        selectButton: "bg-gray-200 px-4 py-2 border pac-target-input p-2 focus:outline-none",
        searchWrapper: "bg-gray-200 px-4 py-2",
        searchBox: "bg-gray-200 focus:outline-none",
      }
    }
  }
});
