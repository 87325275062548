<template>
  <div class="form-group">
    <select
      :id="modelName.replace(/\./g, '_')"
      :name="modelName"
      :class="
        `w-full py-2 px-4 mx-auto border ${
          disabled ? 'bg-gray-500' : 'bg-gray-200'
        } ${errorModel ? 'border-error' : ''}`
      "
      v-model="data_value"
      @change="
        error_model = '';
        data_value = $event.target.value;
        $emit('input', data_value);
      "
      :disabled="disabled"
    >
      <option :value="undefined" disabled>{{ label }}</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.code"
      >
        {{ option.label }}
      </option>
    </select>
    <font-awesome-icon
      icon="exclamation-triangle"
      v-if="error_model"
      class="text-error"
    ></font-awesome-icon>
    <span
      class="text-error"
      v-for="(item, index) in error_model"
      :key="index"
      >{{ item }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    errorModel: {
      required: true
    },
    value: {
      required: true
    },
    modelName: {
      type: String,
      required: true
    },
    options: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  data: function() {
    return {
      error_model: this.errorModel,
      data_value: this.value
    };
  },
  watch: {
    errorModel: function() {
      this.error_model = this.errorModel;
    }
  }
};
</script>
