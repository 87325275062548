<template>
  <div
    v-if="$wait.any"
    class="fixed inset-0 z-50 flex items-center justify-center bg-overlay"
  >
    <h3 class="text-6xl" :class="[`text-${color}-primary`]">
      <font-awesome-icon icon="spinner" spin></font-awesome-icon>
    </h3>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: Boolean()
  }),
  events: {
    loading(value) {
      this.loading = value;
    }
  }
};
</script>
