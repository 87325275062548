<template>
  <t-rich-select
    ref="autocomplete"
    :fetch-options="fetchOptions"
    :placeholder="placeholder"
    :search-box-placeholder="placeholder"
    searching-text="Ricerca in corso..."
    no-results-text="Nessun indirizzo trovato"
    loading-more-results-text="Caricament ulteriori risultati..."
    minimumInputLengthText="Inserire almeno 3 caratteri"
    value-attribute="value"
    :class="classname"
    text-attribute="text"
    select-on-close
    :id="id"
    :minimum-input-length="1"
    clearable
    @change="normalizeAddress"
    @keypress.enter="$emit('enable-autosubmit')"
    :variant="variant"
  >
    <template #option="{ option }">
      <div class="flex cursor-pointer pac-item">
        <span class="flex-shrink-0 pac-icon pac-icon-marker"> </span>
        <div class="truncate pac-item-query">
          <span class="pac-matched" v-text="option.text" :title="option.text">
          </span>
        </div>
      </div>
    </template>
  </t-rich-select>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },

    variant: {
      type: String,
      default: "egon"
    },
    classname: String,

    placeholder: {
      type: String,
      default: "Comincia a digitare il tuo indirizzo"
    },
    nestedModel: Boolean
  },
  data() {
    return {
      autocompleteText: ""
    };
  },
  methods: {
    async fetchOptions(q) {
      try {
        const response = await this.$axios.get(`/api/egon/suggest`, {
          params: { query: q }
        });
        const options = _.map(response.data, i => {
          return {
            value: {
              id: i.id,
              number: _.filter([_.trim(i.number), _.trim(i.exponent)], el => {
                return el && el.length > 0;
              }).join(' ')
            },
            text: this.formatRoad(i)
          };
        });
        return { results: options };
      } catch (error) {
        return {};
      }
    },
    async normalizeAddress(value) {
      if (!value) {
        this.$emit("placechanged", undefined);
        return;
      }
      try {
        window.vm.$wait.start("normalizing");
        this.$refs.autocomplete.query = this.$refs.autocomplete.selectedOption.text;
        const response = await this.$axios.get(`/api/egon/normalize`, {
          params: value
        });
        const model = this.mapAddress(_.get(response.data, "SOG[0]"));
        this.$emit("placechanged", model);
      } finally {
        window.vm.$wait.end("normalizing");
      }
    },
    formatRoad(suggestion) {
      return _.join(
        _.compact([
          suggestion.street,
          _.join(_.compact([suggestion.number, suggestion.exponent]), " "),
          _.join(
            _.compact([
              suggestion.zipcode,
              suggestion.city,
              suggestion.province
            ]),
            " "
          ),
          suggestion.country
        ]),
        ", "
      );
    },
    mapAddress(normalizedAddress) {
      const egon = `${normalizedAddress.INR_620.CIVICOST1.CDPOBJCIV.lValue}`;
      const address = normalizedAddress.INR_620;
      const coordinates = normalizedAddress.COO_626;

      const fullAddress = _.join(
        _.compact([
          address.DSXSTRUFF.DSXST1.DSXSTR,
          address.CIVICOST1.NRXNUMCIV,
          _.join(
            _.compact([
              address.CDXZIP,
              address.DSXPLCUFF.DSXCNL,
              address.DSXPLCSIG.DSXDPT
            ]),
            " "
          ),
          address.DSXPLCUFF.DSXCNY
        ]),
        ", "
      );
      let model = {
        coordinates: {
          latitude: `${coordinates.BES_1_Y_G}`,
          longitude: `${coordinates.BES_1_X_G}`,
          egon: egon
        },
        address: {
          country: address.DSXPLCUFF.DSXCNY,
          administrative_area: address.DSXPLCSIG.DSXDPT,
          administrative_area_level_1: address.DSXPLCUFF.DSXREG,
          city: address.DSXPLCUFF.DSXCNL,
          postal_code: address.CDXZIP,
          route: address.DSXSTRUFF.DSXST1.DSXSTR,
          street_number: address.CIVICOST1.NRXNUMCIV,
          full_address: fullAddress
        }
      };
      model = this.nestedModel
        ? model
        : { ...model.address, ...model.coordinates };
      return model;
    }
  }
};
</script>
<style>
.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
</style>
