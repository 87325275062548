<template>
  <div class="flex flex-wrap-reverse justify-between">
    <div class="justify-center lg:w-48 p-4 w-full">
      <a
        v-if="
          !loading &&
            (checkoutStep != 'complete' && checkoutStep != 'contact_data')
        "
        href="#"
        :class="{
          disabled: blockedStep && checkoutStep == 'contract',
          'pointer-events-none': blockedStep && checkoutStep == 'contract',
          'cursor-not-allowed': blockedStep && checkoutStep == 'contract',
          'bg-grigio-lighter': blockedStep && checkoutStep == 'contract'
        }"
        id="previous-button"
        class="shadow-button h-10 mr-auto text-white py-2 block rounded-full uppercase overflow-hidden text-center bg-grigio hover:bg-grigio-lighter"
        @click="validateStepPrevious()"
        tabindex="0"
      >
        <template
          >Indietro</template
        >
      </a>
    </div>

    <div class="justify-center p-4 w-full"
      :class="checkoutStep == 'recurring_rid' ? 'lg:w-1/3' : 'lg:w-48'">
      <a
        v-if="!loading && checkoutStep != 'complete'"
        href="#"
        id="forward-button"
        :class="{
          disabled: invalid,
          'pointer-events-none': invalid,
          'cursor-not-allowed': invalid,
          'bg-grigio-lighter': invalid
        }"
        class="shadow-button h-10 ml-auto text-white py-2 block rounded-full uppercase overflow-hidden text-center bg-verde hover:bg-verde-lighter"
        @click="validateStepForward()"
        tabindex="0"
      >
        <template v-if="checkoutStep == 'contract'"
          >Acquista</template
        >
        <template v-else-if="checkoutStep == 'recurring_rid'"
          >Conferma e accetta</template
        >
        <template v-else
          >Avanti</template
        >
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: String,
      default: "contact_data"
    },
    invalid: Boolean
  },
  data: function() {
    return {
      loading: false,
      checkoutStep: this.step,
      blockedStep: false
    };
  },
  beforeDestroy() {
    this.$events.off("checkout-step");
    this.$events.off("checkout:loading");
    this.$events.off("checkout:not-loading");
    this.$events.off("checkout:downloadedContract");
    this.$events.off("checkout:resettedContract");
  },
  created: function() {
    this.$events.on("checkout-step", step => {
      this.checkoutStep = step;
    });
  },
  methods: {
    validateStepForward: function() {
      this.$events.emit("checkout:validateStep");
    },
    validateStepPrevious: function() {
      this.$events.emit("checkout:validatePreviousStep");
    }
  },
  mounted: function() {
    this.$events.on("checkout:loading", function() {
      this.loading = true;
    });
    this.$events.on("checkout:not-loading", function() {
      this.loading = false;
    });
      
    this.$events.on("checkout:downloadedContract", blocked => {
      this.blockedStep = true;
    });
    this.$events.on("checkout:resettedContract", blocked => {
      this.blockedStep = false;
    });
  }
};
</script>
