<template>
  <div class="contact__card">
    <ul class="number-list">
      <li class="number-list__item">
        <h3 class="font-semibold text-arancio-primary">
          {{ title }}
        </h3>
      </li>
      <li
        class="number-list__item"
        v-for="item in listItems"
        :key="item.description"
      >
        <i
          class="icon pr-1"
          :class="[`text-${color}-primary`, `icon-${item.icon}`]"
        ></i>
        <p class="pr-2" :class="[`text-${color}-primary`]">
          {{ item.description }}:
        </p>
        <a :href="[`mailto:${item.value}`]">{{ item.value }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    listItems: Array
  }
};
</script>
<style scoped>
.contact__card {
  @apply p-4;
  @apply w-full;
}

.number-list {
  @apply flex;
  @apply flex-col;
  @apply w-full;
  @apply justify-center;
  @apply rounded-lg;
  @apply bg-white;
}

.number-list__item {
  @apply p-4;
  @apply py-3;
  @apply leading-tight;
  @apply text-center;
}

.number-list__item:not(:first-child) {
  @apply flex;
  @apply flex-wrap;
  @apply text-sm;
}

.number-list__item:not(:last-child) {
  border-bottom: 1px solid;
  @apply border-gray-200;
}

@screen md {
  .contact__card {
    @apply w-1/2;
  }
}

@screen lg {
  .contact__card {
    @apply w-1/3;
  }
}
</style>
