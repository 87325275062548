<template>
  <div class="flex flex-col" :class="position">
    <a
      class="button-standard disabled:opacity-75 disabled:cursor-not-allowed"
      :disabled="$attrs.disabled"
      :class="
        negative
          ? `bg-white text-${color}-primary`
          : `bg-${color} hover:bg-${color}-lighter text-white`
      "
      :href="link"
    >
      <slot />
    </a>
  </div>
</template>
<script>
export default {
  props: {
    link: String,
    align: String,
    negative: Boolean
  },
  computed: {
    position() {
      switch (this.align) {
        case "right":
          return "md:items-end";
        case "center":
          return "mx-auto md:items-center";
        case "left":
          return "md:items-start";
        default:
          return "md:items-start";
      }
    }
  }
};
</script>
