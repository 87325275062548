<template>
  <i :class="`icon icon-${iconName} ${iconStyle ? iconStyle : ''}`"></i>
</template>
<script>
export default {
  props: {
    iconName: String,
    iconStyle: String
  }
};
</script>
